import React, { useEffect, useState } from 'react'
import Apis from '@apis'
import { TopBarLayout } from '@layouts'
import Editor from 'jodit-react'
import { Button } from 'antd'
import { useAppDispatch } from '@/redux/hook'
import { SET_MESSAGE } from '@/redux/message'
import styled from 'styled-components'
import { Box } from '@components'

const StyledBox = styled(Box)`
    border: 1px solid rgba(5, 5, 5, 0.06);
    display: block;
`

const Readme: React.FC = () => {
    const dispatch = useAppDispatch()

    const [readme, setReadme] = useState<null | string>('')
    const [value, setValue] = useState<null | string>('')
    const [isUpdateMode, setIsUpdateMode] = useState(false)

    const getReadme = async () => {
        const { data } = await Apis.readme.getReadme()
        setValue(data.body)
        setReadme(data.body)
    }

    useEffect(() => {
        getReadme()
    }, [])

    const onClick = async () => {
        if (isUpdateMode) {
            try {
                await Apis.readme.updateReadme({ body: readme ?? '' })
                dispatch(
                    SET_MESSAGE({
                        text: 'Справка успешно обновлена',
                        type: 'success',
                    })
                )
            } catch (e: any) {
                dispatch(
                    SET_MESSAGE({
                        text: `Не удалось обновить справку. Ошибка: ${
                            e?.response?.data?.message ?? e.message
                        }`,
                        type: 'success',
                    })
                )
            }
            setValue(readme)
            setIsUpdateMode(false)
        } else {
            setIsUpdateMode(true)
        }
    }

    const checkIsDisabled = () => {
        return readme === '<p><br></p>' || readme === ''
    }

    function createMarkup() {
        return { __html: readme ?? '' }
    }

    return (
        <TopBarLayout
            title="Справка"
            postfix={
                <Button
                    disabled={isUpdateMode ? checkIsDisabled() : false}
                    onClick={onClick}
                >
                    {isUpdateMode ? 'Сохранить' : 'Редактировать'}
                </Button>
            }
        >
            {isUpdateMode ? (
                <Editor
                    onChange={(value) => setReadme(value)}
                    value={value ?? ''}
                />
            ) : (
                <StyledBox dangerouslySetInnerHTML={createMarkup()} />
            )}
        </TopBarLayout>
    )
}

export default Readme
