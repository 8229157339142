import httpService from '../httpService'
import {
    CreateVisitReasonProps,
    GetOneVisitReasonResponse,
    GetVisitReasonsProps,
    GetVisitReasonsResponse,
    CreateVisitReasonResponse,
    UpdateVisitReasonProps,
    UpdateVisitReasonResponse,
    DeleteVisitReasonResponse,
} from './types'

export default {
    getVisitReasons({ params }: GetVisitReasonsProps) {
        return httpService.get<GetVisitReasonsResponse>(
            '/dashboard/visit-reason',
            { params }
        )
    },
    createVisitReason({ body }: CreateVisitReasonProps) {
        return httpService.post<CreateVisitReasonResponse>(
            '/dashboard/visit-reason',
            body
        )
    },
    getOneVisitReason(id: number) {
        return httpService.get<GetOneVisitReasonResponse>(
            `/dashboard/visit-reason/${id}`
        )
    },
    updateVisitReason({ id, body }: UpdateVisitReasonProps) {
        return httpService.patch<UpdateVisitReasonResponse>(
            `/dashboard/visit-reason/${id}`,
            body
        )
    },
    deleteVisitReason(id: number) {
        return httpService.delete<DeleteVisitReasonResponse>(
            `/dashboard/visit-reason/${id}`
        )
    },
}
