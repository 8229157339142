import React, { useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Auth } from './screens'
import { DashboardRoutes } from './routes'
import { NotFound } from '@/screens'
import { message } from 'antd'
import { useAppSelector } from './redux/hook'
import {
    useRestriction,
    useStaffGroup,
    useStaffPermission,
    useTeam,
} from '@hooks'

function App() {
    const globalMessage = useAppSelector((state) => state.message)
    const {
        getTeams: { getTeams },
    } = useTeam()
    const {
        getStaffGroups: { getStaffGroups },
    } = useStaffGroup()
    const {
        getStaffPermissions: { getStaffPermissions },
    } = useStaffPermission()
    const { isAuth } = useRestriction()

    const [messageApi, contextHolder] = message.useMessage()

    useEffect(() => {
        if (globalMessage.text && globalMessage.type) {
            messageApi[globalMessage.type](globalMessage.text)
        }
    }, [globalMessage])

    useEffect(() => {
        getTeams()
        getStaffGroups()
        getStaffPermissions()
    }, [isAuth])

    return (
        <>
            <Routes>
                <Route path="/auth" element={<Auth />} />
                <Route path="/*" element={<DashboardRoutes />} />
                <Route path="/404" element={<NotFound />} />
                <Route path="*" element={<Navigate to="/404" />} />
            </Routes>
            {contextHolder}
        </>
    )
}

export default App
