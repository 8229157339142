import React, { useEffect, useState } from 'react'
import { useAppSelector } from '@/redux/hook'
import { Button, Table, Popconfirm } from 'antd'
import type { TableProps } from 'antd'
import { useRequests } from '@hooks'
import { GetRequestsResponseItem } from '@apis/requests'
import { TopBarLayout } from '@layouts'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FaTrashRestore } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

const columns: TableProps<GetRequestsResponseItem>['columns'] = [
    {
        title: '№',
        dataIndex: 'index',
        render: (_: any, __: any, index: number) => index + 1,
        width: '2%',
        align: 'center',
    },
    {
        title: 'Дата создания',
        dataIndex: 'createdat',
        render: (createdat) =>
            new Date(createdat).toLocaleString().substring(0, 17),
        sorter: (a, b) =>
            new Date(a.createdat).getTime() - new Date(b.createdat).getTime(),
        defaultSortOrder: 'descend',
        width: '15%',
        align: 'center',
    },
    {
        title: 'Текст',
        dataIndex: 'text',
        render: (text) => text,
        sorter: (a, b) => a.text.localeCompare(b.text),
        width: 80,
    },
    {
        title: 'Сотрудник',
        dataIndex: 'staff',
        render: (staff) => staff.full_name,
        sorter: (a, b) =>
            (a.staff.full_name || '')
                .toString()
                .localeCompare(b.staff.full_name || ''),
        width: '15%',
    },
]

const RequestsArchive: React.FC = () => {
    const {
        items,
        loading,
        count,
        filters: { page, limit, search },
    } = useAppSelector((state) => state.requests)
    const {
        getArchivedRequests: { getArchivedRequests },
        unarchiveRequest: { unarchiveRequest },
    } = useRequests()

    const nav = useNavigate()
    const [searchValue, setSearchValue] = useState(search || '')

    const onSearch = async () => {
        const search = searchValue.trim()
        if (search) {
            await getArchivedRequests({ page: 1, search })
        } else {
            await getArchivedRequests({ page: 1, search: undefined })
        }
    }

    useEffect(() => {
        getArchivedRequests()
    }, [])

    const getColumns = () => {
        const newColumns = [...columns]
        newColumns.push({
            title: <FaTrashRestore size="20px" />,
            render: (_, { id }, index) => (
                <Popconfirm
                    title="Восстановление заявки"
                    description="Вы хотите восстановить эту заявку?"
                    okText="Да"
                    cancelText="Нет"
                    onConfirm={() => unarchiveRequest(id)}
                >
                    <Button>Восстановить</Button>
                </Popconfirm>
            ),
            width: '10%',
            align: 'center',
        })

        return newColumns
    }

    return (
        <TopBarLayout
            title="Закрытые заявки"
            withGoBack
            withSearchBox
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onSearch={onSearch}
        >
            <AutoSizer style={{ width: '100%' }}>
                {({ width, height }: any) => (
                    <Table<GetRequestsResponseItem>
                        style={{ width: width + 'px' }}
                        dataSource={items}
                        columns={getColumns()}
                        rowKey="id"
                        loading={loading}
                        pagination={{
                            total: count,
                            pageSize: limit,
                            hideOnSinglePage: true,
                            onChange: (page) => getArchivedRequests({ page }),
                            current: page,
                        }}
                        onRow={(data) => ({
                            onClick: () => {
                                console.log('Переданные данные:', data)
                                nav(`/requests/archive/${data.id}`, {
                                    state: { data },
                                })
                            },
                        })}
                        scroll={{ x: 1000 }}
                    />
                )}
            </AutoSizer>
        </TopBarLayout>
    )
}

export default RequestsArchive
