import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { TopBarLayout } from '@layouts'
import { useStaffDinner } from '@hooks'
import styled from 'styled-components'
import { Button, DatePicker, Input, InputNumber, Spin } from 'antd'
import { DinnerType } from './types'
import { DayCard } from '@screens/common/dinners'
import dayjs from 'dayjs'

const DaysWrapper = styled.ul`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    column-gap: 10px;
    list-style: none;
    margin: 0;
    padding: 0;

    @media (max-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 5px;
    }

    @media (max-width: 480px) {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 5px;
    }
`

const Wrapper = styled.div`
    display: grid;
    grid-template-rows: 1fr 32px;
    gap: 10px;
    overflow: visible;

    @media (max-width: 768px) {
        grid-template-rows: auto;
        height: auto;
    }
`

const Box = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
`

const OneDinner: React.FC = () => {
    const { id } = useParams()
    const {
        getOneStaffDinner: {
            getOneStaffDinner,
            getOneStaffDinnerLoading,
            oneStaffDinner,
        },
        updateStaffDinner: { updateStaffDinner },
    } = useStaffDinner()

    const [dinner, setDinner] = useState<DinnerType>({
        days: [],
    })
    const [dinnerSkeleton, setDinnerSkeleton] = useState<DinnerType['days']>([])

    useEffect(() => {
        if (!oneStaffDinner && id) {
            getOneStaffDinner(+id)
        }
        if (oneStaffDinner) {
            const days = oneStaffDinner.week_dinner_days.map((day, index) => ({
                id: day.id,
                menu: day.menu,
                is_free: day.is_free,
            }))
            setDinnerSkeleton(days)
            setDinner({
                price: oneStaffDinner.price ?? undefined,
                title: oneStaffDinner.title,
                deadline: oneStaffDinner.deadline ?? undefined,
                days,
            })
        }
    }, [oneStaffDinner])

    const updateDinner = (data: Partial<Omit<DinnerType, 'days'>>) => {
        setDinner((prevState) => ({
            ...prevState,
            ...data,
        }))
    }

    const onFinish = (send_push: boolean) => {
        if (dinner && id && dinner.deadline && dinner.price && dinner.title) {
            updateStaffDinner({
                id: +id,
                body: {
                    price: +dinner.price ?? 0,
                    days: dinner.days,
                    deadline: dinner.deadline ?? new Date(),
                    title: dinner.title ?? '',
                    send_push,
                },
            })
        }
    }

    const onCreateDayMenu = (index: number) => {
        const resultWeekDay: (typeof dinner.days)[0] = structuredClone(
            dinner.days[index]
        )
        resultWeekDay.menu.push('')

        setDinnerSkeleton((prev) => {
            const weekDays: typeof prev = structuredClone(dinner.days)
            weekDays.splice(index, 1, resultWeekDay)

            return weekDays
        })

        setDinner((prev) => {
            const dinners: typeof prev = structuredClone(prev)
            dinners.days.splice(index, 1, resultWeekDay)

            return dinners
        })
    }

    const onDeleteDayMenu = (indexes: number[]) => {
        setDinner((prev) => {
            const dinners: typeof prev = structuredClone(prev)
            dinners.days[indexes[0]].menu.splice(indexes[1], 1)

            return dinners
        })

        setDinnerSkeleton((prev) => {
            const weekDays: typeof prev = structuredClone(prev)
            weekDays[indexes[0]].menu.splice(indexes[1], 1)

            return weekDays
        })
    }

    const onChangeDayMenu = (changedValue: string, indexes: number[]) => {
        setDinner((prev) => {
            const resultWeekDay: (typeof dinner.days)[0] = structuredClone(
                dinner.days[indexes[0]]
            )
            resultWeekDay.menu.splice(indexes[1], 1, changedValue)
            const dinners: typeof prev = structuredClone(prev)
            dinners.days.splice(indexes[0], 1, resultWeekDay)

            return dinners
        })
    }

    const onCheckDayMenu = (index: number) => {
        const weekDay: (typeof dinner.days)[0] = structuredClone(
            dinner.days[index]
        )
        setDinner((prev) => {
            const dinners: typeof prev = structuredClone(prev)
            dinners.days.splice(index, 1, {
                is_free: !weekDay.is_free,
                menu: weekDay.menu,
                id: weekDay.id,
            })

            return dinners
        })

        setDinnerSkeleton((prev) => {
            const weekDays: typeof prev = structuredClone(prev)
            weekDays.splice(index, 1, {
                is_free: !weekDay.is_free,
                menu: weekDay.menu,
                id: weekDay.id,
            })

            return weekDays
        })
    }

    return (
        <TopBarLayout
            title={`Обед ${dinner.title ?? ''}`}
            withGoBack
            postfix={
                <Box style={{ justifyContent: 'center', gap: '20px' }}>
                    <Link to="staff">
                        <Button>Обед сотрудников</Button>
                    </Link>
                    <Link to={`/dinners/${id}/report`}>
                        <Button>Отчет</Button>
                    </Link>
                </Box>
            }
        >
            {!getOneStaffDinnerLoading && dinner.title !== undefined ? (
                <Wrapper>
                    <DaysWrapper>
                        {dinnerSkeleton.map((day, index) => (
                            <DayCard
                                key={day.id}
                                weekDay={day}
                                weekDayIndex={index}
                                onChange={(changedValue, changedIndex) =>
                                    onChangeDayMenu(changedValue, [
                                        index,
                                        changedIndex,
                                    ])
                                }
                                onCreate={() => onCreateDayMenu(index)}
                                onDelete={(deletedIndex) =>
                                    onDeleteDayMenu([index, deletedIndex])
                                }
                                onCheck={() => onCheckDayMenu(index)}
                            />
                        ))}
                    </DaysWrapper>
                    <Box style={{ justifyContent: 'space-between' }}>
                        <InputNumber
                            style={{ width: '100%' }}
                            value={dinner.price}
                            onChange={(price) =>
                                price && updateDinner({ price })
                            }
                            min={0}
                            placeholder="Цена"
                        />
                        <DatePicker
                            allowClear={false}
                            onChange={(value) =>
                                updateDinner({
                                    deadline: dayjs(value).toDate(),
                                })
                            }
                            placeholder="Дедлайн"
                            style={{ width: '200px' }}
                            value={
                                dinner.deadline
                                    ? dayjs(dinner.deadline)
                                    : undefined
                            }
                            format="YYYY-MM-DD HH:mm:ss"
                            showTime
                        />
                        <Input
                            style={{ width: '100%' }}
                            value={dinner.title}
                            onChange={(event) =>
                                updateDinner({ title: event.target.value })
                            }
                            placeholder="Название"
                        />
                        <Button
                            onClick={() => onFinish(false)}
                            disabled={
                                dinner.price === undefined ||
                                dinner.deadline === undefined ||
                                !dinner.title
                            }
                            type="primary"
                            style={{ width: '200px' }}
                        >
                            Сохранить
                        </Button>
                        <Button
                            onClick={() => onFinish(true)}
                            disabled={
                                dinner.price === undefined ||
                                dinner.deadline === undefined ||
                                !dinner.title
                            }
                            type="primary"
                            style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            Отправить пуш-уведомление
                        </Button>
                    </Box>
                </Wrapper>
            ) : (
                <Box
                    style={{
                        width: '100%',
                        height: '100%',
                        justifyContent: 'center',
                    }}
                >
                    <Spin />
                </Box>
            )}
        </TopBarLayout>
    )
}

export default OneDinner
