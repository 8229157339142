import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { StaffBooksPanelProps } from './types'
import { Box } from '@components'
import { useBook } from '@hooks'
import { CloseOutlined } from '@ant-design/icons'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { Button, Popconfirm } from 'antd'

const StyledBox = styled(Box)`
  position: absolute;
  top: 163px;
  max-width: 250px;
  
  display: flex;
  flex-direction: column;
  gap: 30px;
`

const BookList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;
`

const BookListItem = styled.li`
  display: flex;
  align-items: center;
`

const BooksTitle = styled.h3`
    margin: 0;
  width: 100%;
`

const BookListItemTitle = styled.h4`
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 150px;

  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-weight: 400;
  font-family: sans-serif;
`

export const StaffBooksPanel: React.FC<StaffBooksPanelProps> = (props) => {
    const { items } = props;
    const [localItems, setLocalItems] = useState<StaffBooksPanelProps['items']>(items)
    const {
        updateBookStatus: {
            updateBookStatus,
            updateBookStatusLoading,
        }
    } = useBook()

    const onDelete = async (index: number, id: number) => {
        await updateBookStatus(id)
        setLocalItems(prev => {
            const newItems = [...prev]
            newItems.splice(index, 1)

            return newItems;
        })
    }
    return (
        <StyledBox className='books-panel'>
            <BooksTitle>Книги</BooksTitle>
            <BookList>
                {
                    localItems.map((item, index) => (
                        <BookListItem key={item.id}>
                            <BookListItemTitle>
                                {item.author} - {item.name}
                            </BookListItemTitle>
                            <Popconfirm
                                title="Изъятие книги"
                                description="Вы хотите изъять книгу?"
                                okText='Да'
                                cancelText='Отмена'
                                onConfirm={() => onDelete(index, item.id)}
                            >
                                <AiOutlineCloseCircle
                                    style={{ cursor: 'pointer' }}
                                    size="25px"
                                />
                            </Popconfirm>
                            <Popconfirm title="">

                            </Popconfirm>
                        </BookListItem>
                    ))
                }
            </BookList>
        </StyledBox>
    )
}
