import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Apis from '@apis'
import { VisitReasonStore } from './types'
import { RootState } from '../store'
import {
    GetVisitReasonsProps,
    GetVisitReasonsResponse,
    GetVisitReasonsResponseItem,
} from '@apis/visitReason'

export const initialState: VisitReasonStore = {
    items: [],
    filters: {
        page: 1,
        limit: 50,
        search: undefined,
    },
    count: 0,
    loading: false,
    error: null,
}

export const getVisitReasons = createAsyncThunk<
    { data: GetVisitReasonsResponse; filters: GetVisitReasonsProps['params'] },
    Partial<GetVisitReasonsProps['params']>,
    { state: RootState }
>('GET_VISIT_REASONS', async (payload, thunkAPI) => {
    const { visitReason } = thunkAPI.getState()
    const filters = { ...visitReason.filters, ...payload }
    const { data } = await Apis.visitReason.getVisitReasons({ params: filters })
    return { data, filters }
})

const VisitReasonSlice = createSlice({
    name: 'visitReason',
    initialState,
    reducers: {
        ADD_VISIT_REASON: (
            state,
            action: { payload: GetVisitReasonsResponseItem }
        ) => {
            state.items.push(action.payload)
            state.count = state.items.length
        },
        UPDATE_VISIT_REASON: (
            state,
            action: {
                payload: {
                    updatedVisitReasonData: GetVisitReasonsResponseItem
                    updatedVisitReasonIndex: number
                }
            }
        ) => {
            state.items.splice(
                action.payload.updatedVisitReasonIndex,
                1,
                action.payload.updatedVisitReasonData
            )
        },
        REMOVE_VISIT_REASON: (state, action: { payload: number }) => {
            state.items.splice(action.payload, 1)
            state.count = state.items.length
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getVisitReasons.pending, (state, action) => {
            state.error = null
            state.loading = true
        })
        builder.addCase(getVisitReasons.fulfilled, (state, action) => {
            const {
                filters,
                data: { items, count },
            } = action.payload
            state.filters = filters
            state.items = [...items]
            state.count = count
            state.loading = false
        })
        builder.addCase(getVisitReasons.rejected, (state, action) => {
            state.loading = false
            state.error = action.error
        })
    },
})

export const { ADD_VISIT_REASON, UPDATE_VISIT_REASON, REMOVE_VISIT_REASON } =
    VisitReasonSlice.actions

export default VisitReasonSlice.reducer
