import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Apis from '@apis';
import { RequestStore } from './types'
import { RootState } from '../store';
import {
    GetRequestsProps,
    GetRequestsResponse,
    GetRequestsResponseItem,
} from '@apis/requests';

export const initialState: RequestStore = {
    items: [],
    filters: {
        page: 1,
        limit: 20,
        search: undefined,
    },
    count: 0,
    loading: false,
    error: null,
};

export const getRequests = createAsyncThunk<
    { data: GetRequestsResponse; filters: GetRequestsProps['params'] },
    Partial<GetRequestsProps['params']>,
    { state: RootState }
>('GET_REQUESTS', async (payload, thunkAPI) => {
    const { requests } = thunkAPI.getState();
    const filters = { ...requests.filters, ...payload };
    const { data } = await Apis.requests.getRequests({ params: filters });
    return { data, filters };
});

export const getArchivedRequests = createAsyncThunk<
    { data: GetRequestsResponse; filters: GetRequestsProps['params'] },
    Partial<GetRequestsProps['params']>,
    { state: RootState }
>('GET_REQUESTS', async (payload, thunkAPI) => {
    const { requests } = thunkAPI.getState();
    const filters = { ...requests.filters, ...payload };
    const { data } = await Apis.requests.getArchived({ params: filters });
    return { data, filters };
});

export const unarchiveRequest = createAsyncThunk<
    void,
    number,
    { state: RootState }
>('UNARCHIVE_REQUEST', async (requestId) => {
    await Apis.requests.unarchive(requestId);
});

const RequestsSlice = createSlice({
    name: 'requests',
    initialState,
    reducers: {
        ADD_REQUEST: (state, action: { payload: GetRequestsResponseItem }) => {
            state.items.push(action.payload);
            state.count += 1;
        },
        UPDATE_REQUEST: (
            state,
            action: {
                payload: {
                    updatedRequestData: GetRequestsResponseItem;
                    updatedRequestDataIndex?: number;
                }
            }
        ) => {
            if (action.payload.updatedRequestDataIndex !== undefined) {
                state.items.splice(
                    action.payload.updatedRequestDataIndex,
                    1,
                    action.payload.updatedRequestData
                );
            } else {
                state.items = state.items.map((request) =>
                    request.id !== action.payload.updatedRequestData.id
                        ? request
                        : action.payload.updatedRequestData
                );
            }
        },
        REMOVE_REQUEST: (state, action: { payload: number }) => {
            state.items = state.items.filter(
                (item) => item.id !== action.payload
            );
            state.count -= 1;
        },
        UNARCHIVE_REQUEST: (state, action: { payload: number }) => {
            state.items = state.items.filter(
                (item) => item.id !== action.payload
            );
            state.count -= 1;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getRequests.pending, (state) => {
            state.error = null;
            state.loading = true;
        });
        builder.addCase(getRequests.fulfilled, (state, action) => {
            const {
                filters,
                data: { items, count },
            } = action.payload;
            state.filters = filters;
            state.items = [...items];
            state.count = count;
            state.loading = false;
        });
        builder.addCase(getRequests.rejected, (state, action) => {
            state.loading = false;
            // state.error = action.error;
        });
    },
});

export const { ADD_REQUEST, UPDATE_REQUEST, REMOVE_REQUEST, UNARCHIVE_REQUEST } = RequestsSlice.actions;

export default RequestsSlice.reducer;
