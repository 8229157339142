import React, { useEffect, useState } from 'react'
import { Menu, MenuProps } from 'antd'
import {
    Navigate,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from 'react-router-dom'
import {
    StaffGroups,
    VisitReasons,
    QrGenerator,
    StaffPermissions,
    Teams,
    BookCategories,
} from '@screens/settings'
import { Box } from '@components'
import styled from 'styled-components'

const SettingsStyled = styled(Box)`
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 20px;
`

export const SettingsRoutes: React.FC = () => {
    const nav = useNavigate()
    const location = useLocation()

    const [selectedKeys, setSelectedKeys] = useState(['staff_groups'])

    useEffect(() => {
        if (location.pathname === '/settings') {
            setSelectedKeys(['staff_groups'])
        } else {
            const subLocation = location.pathname.split('/')[2]
            setSelectedKeys([subLocation])
        }
    }, [location])

    const items: MenuProps['items'] = [
        {
            label: 'Группы сотрудников',
            key: 'staff_groups',
        },
        {
            label: 'Проекты',
            key: 'teams',
        },
        {
            label: 'Причины визита',
            key: 'visit_reasons',
        },
        {
            label: 'Доступы сотрудников',
            key: 'staff_permissions',
        },
        {
            label: 'Категории книг',
            key: 'book_categories',
        },
        {
            label: 'QR коды',
            key: 'qr_generator',
        },
    ]

    const onClick: MenuProps['onClick'] = (e) => {
        if (e.key === 'staff_groups') {
            nav('')
            return
        }
        nav(e.key)
    }

    return (
        <SettingsStyled>
            <Menu
                items={items}
                mode="horizontal"
                selectedKeys={selectedKeys}
                onClick={onClick}
            />
            <Routes>
                <Route path="" element={<StaffGroups />} />
                <Route path="visit_reasons" element={<VisitReasons />} />
                <Route path="teams" element={<Teams />} />
                <Route
                    path="staff_permissions"
                    element={<StaffPermissions />}
                />
                <Route
                    path="book_categories"
                    element={<BookCategories />}
                />
                <Route path="qr_generator" element={<QrGenerator />} />
                <Route path="*" element={<Navigate to="/404" />} />
            </Routes>
        </SettingsStyled>
    )
}
