import React, { useState } from 'react'
import { GetVisitReasonsResponseItem } from '@apis/visitReason'
import { useVisitReason } from '@hooks'
import { Button, Input, Modal } from 'antd'
import { Box } from '@components'

type EditVisitReasonModalProps = {
    open: boolean
    setOpen: (open: boolean) => void
    values: GetVisitReasonsResponseItem | undefined
    visitReasonIndex: number | undefined
}

export const EditVisitReasonModal: React.FC<EditVisitReasonModalProps> = (
    props
) => {
    const { open, setOpen, values, visitReasonIndex } = props
    const {
        updateVisitReason: { updateVisitReason, updateVisitReasonLoading },
    } = useVisitReason()

    const [name, setName] = useState('')

    const onUpdateStaffGroup = async () => {
        if (
            values?.id !== undefined &&
            name !== values.name &&
            visitReasonIndex !== undefined
        ) {
            await updateVisitReason(
                { id: values.id, body: { name } },
                visitReasonIndex
            )
            setOpen(false)
        }
    }

    return (
        <Modal
            open={open}
            onCancel={() => setOpen(false)}
            footer={null}
            width="330px"
            destroyOnClose
            title="Изменение причны визита"
        >
            <Box
                style={{
                    flexDirection: 'column',
                    gap: '20px',
                    alignItems: 'stretch',
                }}
            >
                <Input
                    defaultValue={values?.name}
                    onChange={(e) => setName(e.target.value)}
                />
                <Button
                    onClick={onUpdateStaffGroup}
                    loading={updateVisitReasonLoading}
                    disabled={!name.trim() || name === values?.name}
                >
                    Submit
                </Button>
            </Box>
        </Modal>
    )
}
