import React, { useState } from 'react'
import { wallApi } from '@apis/wall'
import { Upload, Button, message, UploadProps, Input, Form } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import styled from 'styled-components'

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
`

const StyledForm = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 500px;
    width: 100%;
    padding: 20px;

    .photo_upload {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
`

const ImagePreview = styled.div`
    margin-bottom: 20px;
    img {
        max-width: 100%;
        border: 1px solid #ccc;
        border-radius: 5px;
    }
`

const CreateWall: React.FC = () => {
    const [text, setText] = useState<string>('')
    const [photo, setPhoto] = useState<File | null>(null)
    const [preview, setPreview] = useState<string | null>(null)

    const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setText(e.target.value)
    }

    const handlePhotoChange: UploadProps['onChange'] = ({ fileList }) => {
        const file = fileList[0]?.originFileObj as File
        if (file) {
            setPhoto(file)
            const reader = new FileReader()
            reader.onloadend = () => {
                setPreview(reader.result as string)
            }
            reader.readAsDataURL(file)
        } else {
            setPhoto(null)
            setPreview(null)
        }
    }

    const handleSubmit = async () => {
        try {
            const response = await wallApi.createWall({
                body: {
                    text,
                    // photo_filename: null
                },
            })

            if (photo) {
                const formData = new FormData()
                formData.append('file', photo)
                await wallApi.updateWallPhoto({
                    id: response.data.id,
                    body: formData,
                })
            }

            message.success('Пост успешно создан!')
        } catch (error) {
            console.error('Не удалось создать пост:', error)
            message.error('Не удалось создать пост')
        }
    }

    return (
        <FormContainer>
            <StyledForm onFinish={handleSubmit}>
                {preview && (
                    <ImagePreview>
                        <img src={preview} alt="Preview" />
                    </ImagePreview>
                )}
                <Form.Item
                    label="Текст"
                    name="text"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста, введите текст поста!',
                        },
                    ]}
                >
                    <Input.TextArea value={text} onChange={handleTextChange} />
                </Form.Item>
                <Form.Item label="Добавить фото" className="photo_upload">
                    <Upload
                        beforeUpload={() => false}
                        showUploadList={false}
                        onChange={handlePhotoChange}
                    >
                        <Button icon={<UploadOutlined />}>Выбрать</Button>
                    </Upload>
                </Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginTop: '20px' }}
                >
                    Опубликовать пост
                </Button>
            </StyledForm>
        </FormContainer>
    )
}

export default CreateWall
