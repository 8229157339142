import Apis from '@apis'
import { useState } from 'react'
import { useAppDispatch, useAppSelector } from '@/redux/hook'
import { SET_MESSAGE } from '@/redux/message'
import { useRestriction } from './useRestriction'
import { getGuests, UPDATE_GUEST } from '@/redux/guest'
import { CloseSessionOfGuestProps, GetGuestsProps } from '@apis/guest'

export const useGuest = () => {
    const { loading, items } = useAppSelector((state) => state.guest)
    const dispatch = useAppDispatch()
    const { isAuth } = useRestriction()

    const [closeSessionOfGuestLoading, setCloseSessionOfGuestLoading] =
        useState(false)

    const fetchGuests = async (params?: Partial<GetGuestsProps['params']>) => {
        if (!isAuth) return

        try {
            await dispatch(getGuests(params ?? {}))
        } catch (e: any) {
            await dispatch(
                SET_MESSAGE({
                    text: `Не удалось загрузить список гостей. Ошибка: ${
                        e?.response?.data?.message ?? e.message
                    }`,
                    type: 'error',
                })
            )
        }
    }

    const closeSessionOfGuest = async (
        updatedGuestValues: CloseSessionOfGuestProps,
        updatedGuestIndex: number
    ) => {
        if (!isAuth) return

        setCloseSessionOfGuestLoading(true)
        try {
            await Apis.guest.closeSessionOfGuest(updatedGuestValues)
            dispatch(
                UPDATE_GUEST({
                    updatedGuestData: {
                        ...items[updatedGuestIndex],
                        check_out_time:
                            updatedGuestValues.body.check_out_date_time,
                    },
                    updatedGuestIndex,
                })
            )
            await dispatch(
                SET_MESSAGE({
                    text: 'Сессия гостя успешно закрыта',
                    type: 'success',
                })
            )
        } catch (e: any) {
            await dispatch(
                SET_MESSAGE({
                    text: `Не удалось закрыть сессию гостя. Ошибка: ${
                        e?.response?.data?.message ?? e.message
                    }`,
                    type: 'error',
                })
            )
        }
        setCloseSessionOfGuestLoading(false)
    }

    return {
        getGuests: {
            getGuests: fetchGuests,
            getGuestsLoading: loading,
        },
        closeSessionOfGuest: {
            closeSessionOfGuest,
            closeSessionOfGuestLoading,
        },
    }
}
