import React from 'react'
import { DayCardProps } from './types'
import styled from 'styled-components'
import { Checkbox, Input } from 'antd'
import { Box } from '@components'
import AutoSizer from 'react-virtualized-auto-sizer'
import { AiOutlineCloseCircle, AiOutlinePlusCircle } from 'react-icons/ai'

const weekDays = [
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
    'Воскресенье',
]

const DayCardWrapper = styled.li`
    display: flex;
    flex-direction: column;
    min-height: 400px;
    height: 100%;
    border: 1px solid rgba(5, 5, 5, 0.06);
    border-radius: 5px;
    padding: 4px;
`

const MenuList = styled.div`
    flex-grow: 1;
    overflow-y: auto;
`

const MenuItem: React.FC<{
    onDelete: () => void
    value: string
    onChange: (value: string) => void
}> = (props) => {
    const { onDelete, value, onChange } = props

    return (
        <Box style={{ gap: '3px', paddingRight: '16px' }}>
            <Input.TextArea
                defaultValue={value}
                onChange={(e) => onChange(e.target.value)}
                autoFocus
            />
            <AiOutlineCloseCircle
                onClick={onDelete}
                style={{ cursor: 'pointer' }}
            />
        </Box>
    )
}

export const DayCard: React.FC<DayCardProps> = (props) => {
    const { weekDay, weekDayIndex, onChange, onCheck, onDelete, onCreate } =
        props

    return (
        <DayCardWrapper>
            <Box
                style={{
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    height: '39px',
                }}
            >
                {weekDays[weekDayIndex]}
                <Checkbox checked={weekDay.is_free} onChange={onCheck} />
            </Box>
            {!weekDay.is_free && (
                <AutoSizer style={{ width: '100%' }}>
                    {(props: any) => (
                        <Box
                            style={{
                                height: props.height - 39 + 'px',
                                overflowY: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px',
                            }}
                        >
                            {weekDay.menu.map((menuItem, menuIndex) => (
                                <MenuItem
                                    onDelete={() => onDelete(menuIndex)}
                                    value={menuItem}
                                    onChange={(value) =>
                                        onChange(value, menuIndex)
                                    }
                                    key={menuIndex + menuItem}
                                />
                            ))}
                            <AiOutlinePlusCircle
                                style={{ flexShrink: 0, cursor: 'pointer' }}
                                onClick={onCreate}
                                size="32px"
                            />
                        </Box>
                    )}
                </AutoSizer>
            )}
        </DayCardWrapper>
    )
}
