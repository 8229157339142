import httpService from '../httpService'
import {
    CreateStaffGroupProps,
    CreateStaffGroupResponse,
    GetOneStaffGroupResponse,
    GetStaffGroupsProps,
    GetStaffGroupsResponse,
    UpdateStaffGroupProps,
    UpdateStaffGroupResponse,
} from './types'

export default {
    getStaffGroups({ params }: GetStaffGroupsProps) {
        return httpService.get<GetStaffGroupsResponse>(
            '/dashboard/staff-groups',
            { params }
        )
    },
    createStaffGroup({ body }: CreateStaffGroupProps) {
        return httpService.post<CreateStaffGroupResponse>(
            '/dashboard/staff-groups',
            body
        )
    },
    getOneStaffGroup(id: number) {
        return httpService.get<GetOneStaffGroupResponse>(
            `/dashboard/staff-groups/${id}`
        )
    },
    updateStaffGroup({ id, body }: UpdateStaffGroupProps) {
        return httpService.patch<UpdateStaffGroupResponse>(
            `/dashboard/staff-groups/${id}`,
            body
        )
    },
    deleteStaffGroup(id: number) {
        return httpService.delete<null>(`/dashboard/staff-groups/${id}`)
    },
}
