import React, { useEffect, useState } from 'react'
import { Button, Input, Modal, Spin } from 'antd'
import { useRegulation } from '@hooks'
import styled from 'styled-components'
import { GetRegulationChangelogsResponse } from '@apis/regulations'
import { Box } from '@components'
import moment from 'moment'
import { MdOutlineClose } from 'react-icons/md'

type ChangelogsModalProps = {
    open: boolean
    onClose: () => void
    group_id: number
}

const List = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    gap: 10px;
`

const Item = styled.li`
    padding: 10px;
    border: 1px solid black;
    border-radius: 5px;
`

const Title = styled.h4`
    margin: 0;
    color: ${(props: any) =>
        props['data-is-deleted'] ? '#ff4d4f' : undefined};
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const CreatedAt = styled.p`
    margin: 0;
`

export const ChangelogsModal: React.FC<ChangelogsModalProps> = (props) => {
    const { open, onClose, group_id } = props
    const {
        getRegulationChangelogs: {
            getRegulationChangelogs,
            getRegulationChangelogsLoading,
            regulationChangelogs,
        },
        updatedRegulationChangelogs: {
            updatedRegulationChangelogs,
            updatedRegulationChangelogsLoading,
        },
        deleteRegulationChangelog: {
            deleteRegulationChangelog,
            deleteRegulationChangelogLoading,
        },
        sendRegulationPush: { sendRegulationPush },
    } = useRegulation()

    const [localChangelogs, setLocalChangelogs] =
        useState<GetRegulationChangelogsResponse>([])

    useEffect(() => {
        open && getRegulationChangelogs({ group_id, push_sent: false })
    }, [group_id, open])

    useEffect(() => {
        if (regulationChangelogs.length !== localChangelogs.length) {
            setLocalChangelogs(regulationChangelogs)
        }
    }, [regulationChangelogs.length])

    const onChange = (value: string, updatedIndex: number) => {
        setLocalChangelogs((prevChangelogs) => {
            const newChangelogs = [...prevChangelogs]

            newChangelogs.splice(updatedIndex, 1, {
                ...prevChangelogs[updatedIndex],
                body: value,
            })

            return newChangelogs
        })
    }

    const onSave = async () => {
        onClose()
        const updatedChangelogs = localChangelogs.filter(
            (localChangelog, index) => {
                return localChangelog.body !== regulationChangelogs[index].body
            }
        )

        if (updatedChangelogs.length) {
            await updatedRegulationChangelogs({
                items: updatedChangelogs.map((changelog) => ({
                    id: changelog.id,
                    body: changelog.body,
                })),
            })
        }

        await sendRegulationPush(group_id)
    }

    const onDelete = async (
        deletedChangelogId: number,
        deletedChangelogIndex: number
    ) => {
        await deleteRegulationChangelog(
            deletedChangelogId,
            deletedChangelogIndex
        )
    }

    return (
        <Modal
            footer={
                <Box style={{ justifyContent: 'flex-end', gap: '30px' }}>
                    <Button
                        type="primary"
                        loading={updatedRegulationChangelogsLoading}
                        onClick={onSave}
                    >
                        Сохранить
                    </Button>
                </Box>
            }
            open={open}
            onCancel={onClose}
            destroyOnClose
            title="Изменения"
        >
            {!getRegulationChangelogsLoading ? (
                <List>
                    {regulationChangelogs.map((changelog, index) => (
                        <Item key={changelog.id}>
                            <Box style={{ paddingBottom: '20px' }}>
                                <Button
                                    onClick={() =>
                                        onDelete(changelog.id, index)
                                    }
                                    style={{ marginLeft: 'auto' }}
                                    danger
                                    type="primary"
                                    icon={<MdOutlineClose size="24px" />}
                                    loading={deleteRegulationChangelogLoading}
                                />
                            </Box>
                            <Box
                                style={{
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    paddingBottom: '6px',
                                    overflow: 'hidden',
                                }}
                            >
                                <Title
                                    data-is-deleted={
                                        changelog.group_regulation === null
                                    }
                                >
                                    {changelog.group_regulation?.title ??
                                        'Регламент бы удален'}
                                </Title>
                                <CreatedAt>
                                    {moment(changelog.created_at).format(
                                        'YYYY-MM-DD HH:MM:SS'
                                    )}
                                </CreatedAt>
                            </Box>
                            <Input.TextArea
                                defaultValue={changelog.body}
                                onChange={(e) =>
                                    onChange(e.target.value, index)
                                }
                            />
                        </Item>
                    ))}
                </List>
            ) : (
                <Box
                    style={{
                        width: '100%',
                        justifyContent: 'center',
                        height: '100px',
                    }}
                >
                    <Spin />
                </Box>
            )}
        </Modal>
    )
}
