import Apis from '@apis'
import { SET_MESSAGE } from '@/redux/message'
import { useAppDispatch, useAppSelector } from '@/redux/hook'
import { useRestriction } from './useRestriction'
import { useState } from 'react'
import { ADD_ADMIN, getAdmins, REMOVE_ADMIN } from '@/redux/admin'
import { CreateAdminProps, GetAdminsProps, UpdateAdminProps } from '@apis/admin'

export const useAdmin = () => {
    const dispatch = useAppDispatch()
    const { loading } = useAppSelector((state) => state.admins)
    const { isAuth, isSuper } = useRestriction()

    const [deleteAdminLoading, setDeleteAdminLoading] = useState(false)
    const [createAdminLoading, setCreateAdminLoading] = useState(false)
    const [updateAdminLoading, setUpdateAdminLoading] = useState(false)

    const fetchAdmins = async (params?: Partial<GetAdminsProps>['params']) => {
        if (!isAuth) return

        try {
            await dispatch(getAdmins(params ?? {}))
        } catch (e: any) {
            await dispatch(
                SET_MESSAGE({
                    text: `Не удалось загрузить список админов. Ошибка: ${
                        e?.response?.data?.message ?? e.message
                    }`,
                    type: 'error',
                })
            )
        }
    }

    const deleteAdmin = async (
        deletedAdminId: number,
        deletedAdminIndex: number
    ) => {
        if (!isAuth || !isSuper) return

        setDeleteAdminLoading(true)
        try {
            await Apis.admin.deleteAdmin(deletedAdminId)
            dispatch(REMOVE_ADMIN(deletedAdminIndex))
            await dispatch(
                SET_MESSAGE({
                    text: 'Админ успешно удален',
                    type: 'success',
                })
            )
        } catch (e: any) {
            await dispatch(
                SET_MESSAGE({
                    text: `Не удалось удалить админа. Ошибка: ${
                        e?.response?.data?.message ?? e.message
                    }`,
                    type: 'error',
                })
            )
        }
        setDeleteAdminLoading(false)
    }

    const createAdmin = async (newAdminData: CreateAdminProps['body']) => {
        if (!isAuth || !isSuper) return

        setCreateAdminLoading(true)
        try {
            const { data } = await Apis.admin.createAdmin({
                body: newAdminData,
            })
            dispatch(ADD_ADMIN(data))
            await dispatch(
                SET_MESSAGE({
                    text: 'Новый админ успешно создан',
                    type: 'success',
                })
            )
        } catch (e: any) {
            await dispatch(
                SET_MESSAGE({
                    text: `Не удалось создать нового админа. Ошибка:${
                        e?.response?.data?.message ?? e.message
                    }`,
                    type: 'error',
                })
            )
        }
        setCreateAdminLoading(false)
    }

    const updateAdmin = async (updatedAdminData: UpdateAdminProps) => {
        if (!isAuth) return

        setUpdateAdminLoading(true)
        try {
            await Apis.admin.updateAdmin(updatedAdminData)
            await fetchAdmins()
            await dispatch(
                SET_MESSAGE({
                    text: 'Админ успешно обновлен',
                    type: 'success',
                })
            )
        } catch (e: any) {
            await dispatch(
                SET_MESSAGE({
                    text: `Не удалось обновить админа. Ошибка: ${
                        e?.response?.data?.message ?? e.message
                    }`,
                    type: 'error',
                })
            )
        }
        setUpdateAdminLoading(false)
    }

    return {
        getAdmins: {
            getAdmins: fetchAdmins,
            getAdminsLoading: loading,
        },
        createAdmin: {
            createAdmin,
            createAdminLoading,
        },
        updateAdmin: {
            updateAdmin,
            updateAdminLoading,
        },
        deleteAdmin: {
            deleteAdmin,
            deleteVisitReasonLoading: deleteAdminLoading,
        },
    }
}
