import { useEffect } from 'react'
import { useAppSelector } from '@/redux/hook'
import { Button, Table, TableProps } from 'antd'
import { useWall } from '@/hooks'
import { GetWallResponseItem } from '@/apis/wall'
import { TopBarLayout } from '@/layouts'
import AutoSizer from 'react-virtualized-auto-sizer'
import { Link, useNavigate } from 'react-router-dom'
import { getColumnData } from '@/utils'

const Wall: React.FC = () => {
    const {
        items,
        loading,
        count,
        filters: { page, limit },
    } = useAppSelector((state) => state.wall)
    const {
        getWall: { getWall },
    } = useWall()

    const nav = useNavigate()

    useEffect(() => {
        getWall()
    }, [])

    const columns: TableProps<GetWallResponseItem>['columns'] = [
        {
            title: 'Дата создания',
            dataIndex: 'created_at',
            render: (created_at) => getColumnData(formatDate(created_at)),
            width: '10%',
            align: 'center',
        },
        {
            title: 'Текст',
            dataIndex: 'text',
            render: getColumnData,
        },
    ]

    const formatDate = (dateString: string): string => {
        const date = new Date(dateString)
        const options: Intl.DateTimeFormatOptions = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        }
        return date.toLocaleDateString('ru-RU', options)
    }

    return (
        <TopBarLayout
            title="Лента"
            postfix={
                <Link to="/wall/new">
                    <Button type="default">Добавить запись</Button>
                </Link>
            }
        >
            <AutoSizer style={{ width: '100%' }}>
                {({ width, height }: any) => (
                    <Table<GetWallResponseItem>
                        style={{ width: width + 'px' }}
                        dataSource={items}
                        columns={columns}
                        rowKey="id"
                        loading={loading}
                        pagination={{
                            total: count,
                            pageSize: limit,
                            hideOnSinglePage: true,
                            onChange: (page) => getWall({ page }),
                            current: page,
                        }}
                        onRow={(data) => ({
                            onClick: () => {
                                console.log(data.id)
                                nav(`/wall/${data.id}`)
                            },
                        })}
                        scroll={{ x: width < 768 ? 500 : 1400 }}
                    />
                )}
            </AutoSizer>
        </TopBarLayout>
    )
}

export default Wall
