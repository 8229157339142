import { useAppDispatch, useAppSelector } from '@/redux/hook'
import {
    CreateRegulationProps,
    DeleteRegulationProps,
    GetRegulationChangelogsProps,
    GetRegulationChangelogsResponse,
    GetRegulationsProps,
    UpdateRegulationChangelogsProps,
    UpdateRegulationProps,
} from '@apis/regulations'
import { useRestriction } from '@hooks/useRestriction'
import { SET_MESSAGE } from '@/redux/message'
import { useState } from 'react'
import Apis from '@apis'
import { getRegulations } from '@/redux/regulation'

export const useRegulation = () => {
    const { isAuth } = useRestriction()
    const dispatch = useAppDispatch()
    const { loading } = useAppSelector((state) => state.regulation)

    const [createRegulationLoading, setCreateRegulationLoading] =
        useState(false)
    const [updateRegulationLoading, setUpdateRegulationLoading] =
        useState(false)
    const [deleteRegulationLoading, setDeleteRegulationLoading] =
        useState(false)
    const [getRegulationChangelogsLoading, setGetRegulationChangelogsLoading] =
        useState(false)
    const [
        updatedRegulationChangelogsLoading,
        setUpdatedRegulationChangelogsLoading,
    ] = useState(false)
    const [deleteRegulationChangelogLoading, setDeleteRegulationChangelog] =
        useState(false)
    const [sendRegulationPushLoading, setSendRegulationPushLoading] =
        useState(false)

    const [regulationChangelogs, setRegulationChangelogs] =
        useState<GetRegulationChangelogsResponse>([])

    const fetchRegulations = async (params: GetRegulationsProps['params']) => {
        if (!isAuth) return

        try {
            await dispatch(getRegulations(params))
        } catch (e: any) {
            dispatch(
                SET_MESSAGE({
                    text: `Не удалось загрузить регламент. Ошибка: ${
                        e?.response?.data?.message ?? e.message
                    }`,
                    type: 'error',
                })
            )
        }
    }

    const createRegulation = async ({ body }: CreateRegulationProps) => {
        if (!isAuth) return

        setCreateRegulationLoading(true)
        try {
            const { data } = await Apis.regulation.createRegulation({ body })
            await fetchRegulations({ group_id: data.group_id })
            dispatch(
                SET_MESSAGE({
                    text: 'Новый регламент успешно создан',
                    type: 'success',
                })
            )
        } catch (e: any) {
            dispatch(
                SET_MESSAGE({
                    text: `Не удалось создать новый регламент. Ошибка: ${
                        e?.response?.data?.message ?? e.message
                    }`,
                    type: 'error',
                })
            )
        }
        setCreateRegulationLoading(false)
    }

    const updateRegulation = async (props: UpdateRegulationProps) => {
        if (!isAuth) return

        setUpdateRegulationLoading(true)
        try {
            const { data } = await Apis.regulation.updateRegulation(props)
            await fetchRegulations({ group_id: data.group_id })
            dispatch(
                SET_MESSAGE({
                    text: 'Регламент успешно обновлен',
                    type: 'success',
                })
            )
        } catch (e: any) {
            dispatch(
                SET_MESSAGE({
                    text: `Не удалось обновить регламент. Ошибка: ${
                        e?.response?.data?.message ?? e.message
                    }`,
                    type: 'error',
                })
            )
        }
        setUpdateRegulationLoading(false)
    }

    const deleteRegulation = async (
        props: DeleteRegulationProps & { group_id: number }
    ) => {
        if (!isAuth) return

        setDeleteRegulationLoading(true)
        try {
            await Apis.regulation.deleteRegulation(props)
            await fetchRegulations({ group_id: props.group_id })
            dispatch(
                SET_MESSAGE({
                    text: 'Регламент успешно удален',
                    type: 'success',
                })
            )
        } catch (e: any) {
            dispatch(
                SET_MESSAGE({
                    text: `Не удалось удалить регламент. Ошибка: ${
                        e?.response?.data?.message ?? e.message
                    }`,
                    type: 'error',
                })
            )
        }
        setDeleteRegulationLoading(true)
    }

    const getRegulationChangelogs = async (
        params: GetRegulationChangelogsProps['params']
    ) => {
        if (!isAuth) return

        setGetRegulationChangelogsLoading(true)
        try {
            const { data } = await Apis.regulation.getRegulationChangelogs({
                params,
            })
            setRegulationChangelogs(data)
        } catch (e: any) {
            dispatch(
                SET_MESSAGE({
                    text: `Не удалось загрузить список изменений. Ошибка: ${
                        e?.response?.data?.message ?? e.message
                    }`,
                    type: 'error',
                })
            )
        }
        setGetRegulationChangelogsLoading(false)
    }

    const updatedRegulationChangelogs = async (
        values: UpdateRegulationChangelogsProps['body']
    ) => {
        if (!isAuth) return

        setUpdatedRegulationChangelogsLoading(true)
        try {
            await Apis.regulation.updateRegulationChangelogs({ body: values })
            dispatch(
                SET_MESSAGE({
                    text: 'Уведомление успешно отправлено',
                    type: 'success',
                })
            )
        } catch (e: any) {
            dispatch(
                SET_MESSAGE({
                    text: `Не удалось отправить уведомление. Ошибка: ${
                        e?.response?.data?.message ?? e.message
                    }`,
                    type: 'error',
                })
            )
        }
        setUpdatedRegulationChangelogsLoading(false)
    }

    const deleteRegulationChangelog = async (
        deletedChangelogId: number,
        deletedChangelogIndex: number
    ) => {
        if (!isAuth) return

        setDeleteRegulationChangelog(true)
        try {
            await Apis.regulation.deleteRegulationChangelog(deletedChangelogId)
            setRegulationChangelogs((prev) => {
                const newChangelogs = [...prev]
                newChangelogs.splice(deletedChangelogIndex, 1)
                return newChangelogs
            })
            dispatch(
                SET_MESSAGE({
                    text: 'Успешно удален',
                    type: 'success',
                })
            )
        } catch (e: any) {
            dispatch(
                SET_MESSAGE({
                    text: `Не удалось удалить. Ошибка: ${
                        e?.response?.data?.message ?? e.message
                    }`,
                    type: 'error',
                })
            )
        }
        setDeleteRegulationChangelog(false)
    }

    const sendRegulationPush = async (groupId: number) => {
        if (!isAuth) return

        setSendRegulationPushLoading(true)
        try {
            await Apis.regulation.sendRegulationPush({
                params: { group_id: groupId, send_push: true },
            })
        } catch (e) {}
        setSendRegulationPushLoading(false)
    }

    return {
        getRegulations: {
            getRegulations: fetchRegulations,
            getRegulationsLoading: loading,
        },
        createRegulation: {
            createRegulation,
            createRegulationLoading,
        },
        updateRegulation: {
            updateRegulation,
            updateRegulationLoading,
        },
        deleteRegulation: {
            deleteRegulation,
            deleteRegulationLoading,
        },
        getRegulationChangelogs: {
            getRegulationChangelogs,
            getRegulationChangelogsLoading,
            regulationChangelogs,
        },
        updatedRegulationChangelogs: {
            updatedRegulationChangelogs,
            updatedRegulationChangelogsLoading,
        },
        deleteRegulationChangelog: {
            deleteRegulationChangelog,
            deleteRegulationChangelogLoading,
        },
        sendRegulationPush: {
            sendRegulationPush,
            sendRegulationPushLoading,
        },
    }
}
