import React, { useState } from 'react'
import { GetStaffPermissionsResponseItem } from '@apis/staffPermission'
import { useStaffPermission } from '@hooks'
import { Button, Input, Modal } from 'antd'
import { Box } from '@components'

type EditStaffPermissionModalProps = {
    open: boolean
    setOpen: (open: boolean) => void
    values: GetStaffPermissionsResponseItem | undefined
    visitReasonIndex: number | undefined
}

export const EditStaffPermissionModal: React.FC<
    EditStaffPermissionModalProps
> = (props) => {
    const { open, setOpen, visitReasonIndex, values } = props
    const {
        updateStaffPermission: {
            updateStaffPermission,
            updateStaffPermissionLoading,
        },
    } = useStaffPermission()

    const [name, setName] = useState('')

    const onUpdateStaffPermission = async () => {
        if (
            values?.id !== undefined &&
            name !== values.name &&
            visitReasonIndex !== undefined
        ) {
            await updateStaffPermission(
                { id: values.id, body: { name } },
                visitReasonIndex
            )
            setOpen(false)
        }
    }

    return (
        <Modal
            open={open}
            onCancel={() => setOpen(false)}
            footer={null}
            width="330px"
            destroyOnClose
            title="Изменение доступа сотруника"
        >
            <Box style={{ flexDirection: 'column', gap: '20px' }}>
                <Input
                    defaultValue={values?.name}
                    onChange={(e) => setName(e.target.value)}
                />
                <Button
                    onClick={onUpdateStaffPermission}
                    loading={updateStaffPermissionLoading}
                    disabled={!name.trim() || name === values?.name}
                >
                    Submit
                </Button>
            </Box>
        </Modal>
    )
}
