import React, { useEffect, useState } from 'react'
import { useAppSelector } from '@/redux/hook'
import { Button, DatePicker, Form, Modal, Table } from 'antd'
import type { TableProps } from 'antd'
import { useGuest } from '@hooks'
import { GetGuestResponseItem } from '@apis/guest'
import moment from 'moment'
import { TopBarLayout } from '@layouts'
import styled from 'styled-components'
import { getColumnData } from '@/utils'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const Title = styled.h3`
    margin: 0;
    text-align: center;
`

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
`

const columns: TableProps<GetGuestResponseItem>['columns'] = [
    {
        title: '№',
        dataIndex: 'index',
        render: (_: any, __: any, index: number) => index + 1,
        width: '5%',
        align: 'center',
        responsive: ['xs', 'sm'],
    },
    {
        title: 'Кто зарегестрировал',
        dataIndex: 'staff',
        render: (_, { staff }) => getColumnData(staff.full_name),
        width: '20%',
    },
    {
        title: 'Имя гостя',
        dataIndex: 'name',
        render: getColumnData,
        width: '20%',
    },
    // {
    //     title: 'Причина визита',
    //     dataIndex: 'visit_reason',
    //     render: getColumnData,
    // },
    {
        title: 'Check-in',
        dataIndex: 'check_in_time',
        render: (_, { check_in_time }) =>
            check_in_time
                ? moment(check_in_time).format('MMMM Do YYYY, H:mm:ss')
                : getColumnData(check_in_time),
        align: 'center',
    },
]

const Guests: React.FC = () => {
    const {
        items,
        count,
        loading,
        filters: { page, limit, search },
    } = useAppSelector((state) => state.guest)
    const [isOpen, setIsOpen] = useState(false)
    const {
        getGuests: { getGuests },
        closeSessionOfGuest: {
            closeSessionOfGuest,
            closeSessionOfGuestLoading,
        },
    } = useGuest()

    const [guestId, setGuestId] = useState<number>()
    const [guestIndex, setGuestIndex] = useState<number>()
    const [searchValue, setSearchValue] = useState(search || '')

    useEffect(() => {
        if (!count) {
            getGuests()
        }
    }, [count, getGuests])

    const onCloseSessionOfGuest = async (guestData: {
        check_out_date_time: Date
    }) => {
        if (guestId !== undefined && guestIndex !== undefined) {
            await closeSessionOfGuest(
                { id: guestId, body: guestData },
                guestIndex
            )
        }
        setIsOpen(false)
        setGuestId(undefined)
    }

    const onSearch = async () => {
        const search = searchValue.trim()
        if (search) {
            await getGuests({ page: 1, search })
        } else {
            await getGuests({ page: 1, search: undefined })
        }
    }

    const onClick = (guestId: number, guestIndex: number) => {
        setGuestId(guestId)
        setGuestIndex(guestIndex)
        setIsOpen(true)
    }

    const getColumns = () => {
        return columns.concat({
            title: 'Check-out',
            dataIndex: 'check_out_time',
            render: (_, { check_out_time, id }, index) =>
                check_out_time === null ? (
                    <Button onClick={() => onClick(id, index)}>
                        Уĸазать время ухода
                    </Button>
                ) : (
                    moment(check_out_time).format('MMMM Do YYYY, H:mm:ss')
                ),
            align: 'center',
        })
    }

    return (
        <TopBarLayout
            title="Гости"
            withSearchBox
            onSearch={onSearch}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
        >
            <Table<GetGuestResponseItem>
                style={{ cursor: 'pointer' }}
                dataSource={items}
                columns={getColumns()}
                rowKey="id"
                loading={loading}
                pagination={{
                    total: count,
                    pageSize: limit,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    hideOnSinglePage: true,
                    onChange: (page, pageSize) =>
                        getGuests({ page, limit: pageSize }),
                    current: page,
                }}
            />
            <Modal
                open={isOpen}
                onCancel={() => setIsOpen(false)}
                footer={null}
                width="300px"
                destroyOnClose
            >
                <Wrapper>
                    <Title>Закрыть сессию гостя</Title>
                    <Form onFinish={onCloseSessionOfGuest} layout="vertical">
                        <Form.Item
                            label="Дата"
                            name="check_out_date_time"
                            rules={[
                                {
                                    required: true,
                                    message: 'Дата обязательное поле!',
                                },
                            ]}
                        >
                            <DatePicker
                                style={{ width: '100%' }}
                                format="YYYY-MM-DD HH:mm:ss"
                                showTime
                            />
                        </Form.Item>
                        <ButtonWrapper>
                            <Button
                                loading={closeSessionOfGuestLoading}
                                style={{ width: '100%' }}
                                htmlType="submit"
                            >
                                Сохранить
                            </Button>
                        </ButtonWrapper>
                    </Form>
                </Wrapper>
            </Modal>
        </TopBarLayout>
    )
}

export default Guests
