import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Button, Form, Input } from 'antd'
import { useAppSelector } from '@/redux/hook'
import { useAuth } from '@hooks'

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
`

const Title = styled.h1`
    margin: 0 0 20px 0;
    text-align: center;
`
const Auth: React.FC = () => {
    const { is_auth } = useAppSelector((state) => state.user)
    const {
        checkAuth: { checkAuth },
        logIn: { logIn, logInLoading },
    } = useAuth()

    useEffect(() => {
        if (is_auth === 'initial') {
            checkAuth()
        }
    }, [])

    return (
        <Wrapper>
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                onFinish={logIn}
                autoComplete="off"
                layout="vertical"
                style={{ width: '300px' }}
            >
                <Title>Вход</Title>
                <Form.Item
                    label="Почта"
                    name="email"
                    rules={[
                        {
                            required: true,
                            message:
                                'Это не похоже на адрес электронной почты!',
                            type: 'email',
                        },
                    ]}
                >
                    <Input style={{ width: '300px' }} />
                </Form.Item>

                <Form.Item
                    label="Пароль"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message:
                                'Длина пароля должна быть не мнее 8 символов!',
                            min: 8,
                        },
                    ]}
                >
                    <Input.Password style={{ width: '300px' }} />
                </Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={logInLoading}
                    style={{ textAlign: 'center', width: '100%' }}
                >
                    Войти
                </Button>
            </Form>
        </Wrapper>
    )
}

export default Auth
