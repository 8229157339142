import React, { useEffect, useState } from 'react'
import { useRegulation } from '@hooks'
import { useAppSelector } from '@/redux/hook'
import { getGroupRegulation } from '@/redux/regulation/selectors'
import { ChangelogsModal, RegulationsList } from '.'
import { Box } from '@components'
import { Button, Spin } from 'antd'
import styled from 'styled-components'

type OneRegulationProps = {
    groupId: number
    indexes: number[]
}

const OneRegulationWrapper = styled(Box)`
    display: grid;
`

const OneRegulationContent = styled(Box)`
    min-height: calc(100vh - 257px);
    align-items: flex-start;
`

const OneRegulationFooter = styled.footer`
    border-top: 1px solid rgba(5, 5, 5, 0.06);
    padding: 10px 30px;
    position: sticky;
    bottom: 0;
    background-color: white;
`

export const RootRegulation: React.FC<OneRegulationProps> = (props) => {
    const { groupId, indexes } = props
    const {
        getRegulations: { getRegulations },
    } = useRegulation()
    const groupRegulation = useAppSelector((state) =>
        getGroupRegulation(state, groupId)
    )

    const [openChangelogs, setOpenChangelogs] = useState(false)

    useEffect(() => {
        getRegulations({ group_id: groupId })
    }, [])

    const handleSave = () => {
        setOpenChangelogs(true)
    }

    return groupRegulation !== undefined ? (
        <OneRegulationWrapper>
            <OneRegulationContent>
                <RegulationsList
                    groupId={groupId}
                    indexes={indexes}
                    regulations={groupRegulation.items}
                    parent_id={null}
                />
            </OneRegulationContent>
            <OneRegulationFooter
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignContent: 'center',
                }}
            >
                <Button type="primary" onClick={handleSave}>
                    Отправить пуш-уведомление
                </Button>
            </OneRegulationFooter>
            <ChangelogsModal
                open={openChangelogs}
                onClose={() => setOpenChangelogs(false)}
                group_id={groupId}
            />
        </OneRegulationWrapper>
    ) : (
        <Box style={{ width: '100%', justifyContent: 'center' }}>
            <Spin />
        </Box>
    )
}
