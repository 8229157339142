import httpService from '../httpService'
import {
    CreateBookCategoryProps,
    CreateBookCategoryResponse,
    GetOneBookCategoryResponse,
    GetBookCategoriesProps,
    GetBookCategoriesResponse,
    UpdateBookCategoryProps,
    UpdateBookCategoryResponse,
} from './types'

export default {
    getBookCategories({ params }: GetBookCategoriesProps) {
        return httpService.get<GetBookCategoriesResponse>(
            '/dashboard/book-categories',
            { params }
        )
    },
    createBookCategory({ body }: CreateBookCategoryProps) {
        return httpService.post<CreateBookCategoryResponse>(
            '/dashboard/book-categories',
            body
        )
    },
    getOneBookCategory(id: number) {
        return httpService.get<GetOneBookCategoryResponse>(
            `/dashboard/book-categories/${id}`
        )
    },
    updateBookCategory({ id, body }: UpdateBookCategoryProps) {
        return httpService.put<UpdateBookCategoryResponse>(
            `/dashboard/book-categories/${id}`,
            body
        )
    },
    deleteBookCategory(id: number) {
        return httpService.delete<null>(`/dashboard/book-categories/${id}`)
    },
}
