import React, { useState } from 'react'
import styled from 'styled-components'
import Avatar from 'react-avatar'
import { ImgPickerProps } from './types'

const Wrapper = styled.div`
  position: relative;
  width: min-content;
  cursor: pointer;
`

const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`

const AvatarStyled = styled(Avatar)<{ forBook: boolean }>`
  ${({ forBook }) => {
    if (forBook) return {
      width: '100px !important',
      height: '150px !important',
    }
  }}
  & {
    border: ${(p) => `solid 1px black`};
  }

  & > * {
    border: none;
  }
`

export const ImgPicker = (props: ImgPickerProps) => {
    const { onFilePicked, fileTypes, src, size, round = false, forBook = false } = props
    const [url, setUrl] = useState('')
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e?.target?.files?.length) {
            const file = e.target.files[0] as File & { url: string }
            const fileUrl = (window.URL || window.webkitURL).createObjectURL(
                file,
            )
            file['url'] = fileUrl
            setUrl(fileUrl)
            onFilePicked(file)
        }
    }

    const accept = fileTypes?.join(', ').trim() || ''

    return (
        <Wrapper className='heheh'>
            <AvatarStyled
                color='unset'
                round={round}
                src={url.length ? url : src}
                size={size}
                forBook={forBook}
            />
            <Input type='file' onChange={onChange} accept={accept} />
        </Wrapper>
    )
}
