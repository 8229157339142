import React, { useEffect, useState } from 'react'
import { TopBarLayout } from '@layouts'
import { Button, Popconfirm, Table } from 'antd'
import type { TableProps } from 'antd'
import { getColumnData, getAvatarUrl } from '@/utils'
import { GetStaffResponseItem } from '@apis/staff'
import { useStaff } from '@hooks'
import { FaTrashRestore } from 'react-icons/fa'

const columns: TableProps<GetStaffResponseItem>['columns'] = [
    {
        title: 'Фото',
        dataIndex: 'photo_filename',
        render: (_, { photo_filename }) => (
            <img
                src={getAvatarUrl(photo_filename)}
                width={100}
                height={100}
                alt="avatar"
            />
        ),
        width: '100px',
    },
    {
        title: 'Полное имя',
        dataIndex: 'full_name',
        render: getColumnData,
    },
    {
        title: 'Номер телефона',
        dataIndex: 'phone_number',
        render: getColumnData,
    },
    {
        title: 'Группа',
        dataIndex: 'staff_group',
        render: (staff_group) => getColumnData(staff_group?.name),
    },
    {
        title: 'Проект',
        dataIndex: 'staff_team',
        render: (staff_team) => getColumnData(staff_team?.name),
    },
    {
        title: 'Статус',
        dataIndex: 'staff_status',
        render: (staff_status) => getColumnData(staff_status?.name),
    },
]

const StaffArchive: React.FC = () => {
    const {
        getArchivedStaff: { getArchivedStaff, archivedStaff },
        restoreStaff: { restoreStaff },
    } = useStaff()

    const [value, setValue] = useState('')

    const onSearch = async () => {
        const search = value.trim()

        if (search.length) {
            await getArchivedStaff({ search, page: 1 })
        } else {
            await getArchivedStaff({ search: undefined })
        }
    }

    const getColumns = () => {
        const newColumns = [...columns]
        newColumns.push({
            title: <FaTrashRestore size="20px" />,
            render: (_, { id }, index) => (
                <Popconfirm
                    title="Восстановление сотрудника"
                    description="Вы хотите восстановить этого сотрудника?"
                    okText="Да"
                    cancelText="Нет"
                    onConfirm={() => restoreStaff(id, index)}
                >
                    <Button>Восстановить</Button>
                </Popconfirm>
            ),
            width: '130px',
            align: 'center',
        })

        return newColumns
    }

    useEffect(() => {
        getArchivedStaff()
    }, [])

    return (
        <TopBarLayout
            title="Архив сотрудников"
            withGoBack
            withSearchBox
            onSearch={onSearch}
            onChange={(event) => setValue(event.target.value)}
            value={value}
        >
            <Table
                columns={getColumns()}
                dataSource={archivedStaff.items}
                loading={archivedStaff.loading}
                pagination={{
                    current: archivedStaff.filters.page,
                    pageSize: archivedStaff.filters.limit,
                    hideOnSinglePage: true,
                    onChange: (page) => getArchivedStaff({ page }),
                }}
            />
        </TopBarLayout>
    )
}

export default StaffArchive
