import { useAppSelector } from '@/redux/hook'

export const useRestriction = () => {
    const user = useAppSelector((state) => state.user)
    const isAuth = user.is_auth === true
    const isSuper = user.is_super ?? false

    return {
        isAuth,
        isSuper,
    }
}
