import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@/redux/hook'
import type { TableProps } from 'antd'
import { Button, Modal, Table } from 'antd'
import { useRestriction } from '@hooks'
import { getQrCodes } from '@/redux/qrCode'
import { GetQrCodesResponseItem } from '@apis/qrCode'
import { getColumnData, getAvatarUrl } from '@/utils'
import moment from 'moment'
import { DownloadOutlined } from '@ant-design/icons'
import axios from 'axios'
import { TopBarLayout } from '@layouts'
import Apis from '@apis'
import { AiOutlineDelete } from 'react-icons/ai'

const PhotoColumn = ({ photoFilename }: { photoFilename: string | null }) => {
    const [isPreviewOpen, setIsPreviewOpen] = useState(false)
    const [previewImage, setPreviewImage] = useState('')

    const handlePreview = () => {
        const imageUrl = getAvatarUrl(photoFilename)
        setPreviewImage(imageUrl)
        setIsPreviewOpen(true)
    }

    const handleDownload = () => {
        try {
            if (photoFilename) {
                const imageUrl = getAvatarUrl(photoFilename)
                axios(imageUrl, {
                    method: 'GET',
                    responseType: 'blob',
                }).then((response) => {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data])
                    )

                    const link = document.createElement('a')
                    link.href = url
                    link.download = photoFilename

                    document.body.appendChild(link)

                    link.click()

                    document.body.removeChild(link)
                    window.URL.revokeObjectURL(url)
                })
            }
        } catch (e) {}
    }

    return (
        <div>
            <img
                onClick={handlePreview}
                src={getAvatarUrl(photoFilename)}
                width="120px"
                height="120px"
                alt="qr-code"
            />
            <Modal
                open={isPreviewOpen}
                title="Image Preview"
                footer={null}
                onCancel={() => setIsPreviewOpen(false)}
            >
                <img
                    alt="qr-code"
                    style={{ width: '100%' }}
                    src={previewImage}
                />
                <Button
                    type="primary"
                    onClick={handleDownload}
                    icon={<DownloadOutlined />}
                    block
                >
                    Download
                </Button>
            </Modal>
        </div>
    )
}

const QrGenerators: React.FC = () => {
    const columns: TableProps<GetQrCodesResponseItem>['columns'] = [
        {
            title: 'ID',
            dataIndex: 'id',
            render: getColumnData,
            width: '65px',
        },
        {
            title: 'QR',
            dataIndex: 'photo_filename',
            render: (photoFilename) => (
                <PhotoColumn photoFilename={photoFilename} />
            ),
            align: 'center',
        },
        {
            title: 'Дата создания',
            dataIndex: 'created_at',
            render: (created_at) =>
                created_at
                    ? moment(created_at).format('MMMM Do YYYY, H:mm:ss')
                    : getColumnData(created_at),
            align: 'center',
        },
        {
            title: <AiOutlineDelete style={{ width: '100%' }} size={20} />,
            dataIndex: 'actions',
            render: (actions, record) => (
                <Button
                    onClick={() => {
                        Modal.confirm({
                            title: 'Вы уверены, что хотите удалить этот QR?',
                            onOk: () => {
                                onDeleted(record.id.toString())
                            },
                        })
                    }}
                    danger
                >
                    Удалить
                </Button>
            ),
            align: 'center',
        },
    ]

    const dispatch = useAppDispatch()
    const { isAuth } = useRestriction()
    const {
        items,
        count,
        loading,
        filters: { page, limit },
    } = useAppSelector((state) => state.qrCode)

    useEffect(() => {
        fetchQrCodes()
    }, [isAuth])

    const fetchQrCodes = async () => {
        if (isAuth && !count) {
            await dispatch(getQrCodes({}))
        }
    }

    const onDeleted = async (id: string) => {
        // await dispatch(deleteQrCode({ id }))
        await Apis.qrCode.deleteQrCode(id)
        await dispatch(getQrCodes({}))
    }

    const onCreate = async () => {
        await Apis.qrCode.createQrCode()
        await dispatch(getQrCodes({}))
    }

    return (
        // add delete button
        <TopBarLayout postfix={<Button onClick={onCreate}>Новый QR</Button>}>
            <Table<GetQrCodesResponseItem>
                style={{ cursor: 'pointer' }}
                dataSource={items}
                columns={columns}
                rowKey="id"
                loading={loading}
                pagination={{
                    total: count,
                    pageSize: limit,
                    hideOnSinglePage: true,
                    onChange: (page) => dispatch(getQrCodes({ page })),
                    current: page,
                }}
            />
        </TopBarLayout>
    )
}

export default QrGenerators
