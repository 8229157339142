import { useAppDispatch, useAppSelector } from '@/redux/hook'
import { useState } from 'react'
import { ADD_STAFF, getStaff, REMOVE_STAFF, UPDATE_STAFF } from '@/redux/staff'
import {
    CreateStaffProps,
    GetOneStaffResponse,
    GetStaffProps,
    GetStaffResponseItem,
    UpdateStaffProps,
} from '@apis/staff'
import { SET_MESSAGE } from '@/redux/message'
import Apis from '@apis'
import { useRestriction } from './useRestriction'
import { useNavigate } from 'react-router-dom'

type ArchivedStaff = {
    items: GetStaffResponseItem[]
    loading: boolean
    count: number
    filters: Omit<GetStaffProps['params'], 'is_deleted'> &
        Required<Pick<GetStaffProps['params'], 'is_deleted'>>
}

export const useStaff = () => {
    const { loading } = useAppSelector((state) => state.staff)
    const dispatch = useAppDispatch()
    const { isAuth } = useRestriction()
    const nav = useNavigate()

    const [createStaffLoading, setCreateStaffLoading] = useState(false)
    const [deleteStaffLoading, setDeleteStaffLoading] = useState(false)
    const [getOneStaffLoading, setGetOneStaffLoading] = useState(false)
    const [resendInviteLoading, setResendInviteLoading] = useState(false)
    const [updateStaffLoading, setUpdateStaffLoading] = useState(false)
    const [restoreStaffLoading, setRestoreStaffLoading] = useState(false)

    const [oneStaff, setOneStaff] = useState<GetOneStaffResponse | undefined>()
    const [archivedStaff, setArchivedStaff] = useState<ArchivedStaff>({
        items: [],
        count: 0,
        loading: false,
        filters: {
            page: 1,
            limit: 20,
            is_deleted: true,
        },
    })

    const fetchStaff = async (
        params?: Partial<Omit<GetStaffProps['params'], 'is_deleted'>>
    ) => {
        if (!isAuth) return

        try {
            await dispatch(getStaff(params ?? {}))
        } catch (e) {
            dispatch(
                SET_MESSAGE({
                    text: 'Staff not loaded',
                    type: 'error',
                })
            )
        }
    }

    const getArchivedStaff = async (
        params?: Partial<Omit<GetStaffProps['params'], 'is_deleted'>>
    ) => {
        if (!isAuth) return

        const newParams = { ...archivedStaff.filters, ...params }

        setArchivedStaff((prevState) => ({ ...prevState, loading: true }))
        try {
            const { data } = await Apis.staff.getStaff({ params: newParams })

            const items =
                newParams.page === 1
                    ? data.items
                    : [...archivedStaff.items, ...data.items]
            setArchivedStaff((prevState) => ({
                items,
                count: data.count,
                loading: true,
                filters: { ...newParams },
            }))
        } catch (e) {
            dispatch(
                SET_MESSAGE({
                    text: 'Сотрудники из архива не загружены',
                    type: 'error',
                })
            )
        }
        setArchivedStaff((prevState) => ({ ...prevState, loading: false }))
    }

    const createStaff = async (
        newStaffData: CreateStaffProps['body'] & { avatar?: File }
    ) => {
        if (!isAuth) return

        const { avatar, ...otherValues } = newStaffData
        let staffId
        let isError = false

        setCreateStaffLoading(true)
        try {
            const { data } = await Apis.staff.createStaff({ body: otherValues })
            dispatch(ADD_STAFF(data))
            staffId = data.id
            dispatch(
                SET_MESSAGE({
                    text: 'User successfully created',
                    type: 'success',
                })
            )
        } catch (e) {
            isError = true
            dispatch(
                SET_MESSAGE({
                    text: 'User not created',
                    type: 'error',
                })
            )
        }

        if (avatar && !isError && staffId !== undefined) {
            try {
                const formData = new FormData()
                formData.append('file', avatar)
                const { data } = await Apis.staff.updateStaffAvatar({
                    body: formData,
                    id: staffId,
                })
                dispatch(UPDATE_STAFF({ updatedStaffData: data }))
            } catch (e) {
                dispatch(
                    SET_MESSAGE({
                        text: 'User avatar not updated',
                        type: 'error',
                    })
                )
            }
        }

        setCreateStaffLoading(false)
    }

    const getOneStaff = async (staffId: number) => {
        if (!isAuth) return

        setGetOneStaffLoading(true)
        try {
            const { data } = await Apis.staff.getOneStaff(staffId)
            setOneStaff(data)
        } catch (e) {
            dispatch(
                SET_MESSAGE({
                    text: 'User not loaded',
                    type: 'error',
                })
            )
        }
        setGetOneStaffLoading(false)
    }

    const deleteStaff = async (deletedStaffId: number) => {
        if (!isAuth) return

        setDeleteStaffLoading(true)
        try {
            await Apis.staff.deleteStaff(deletedStaffId)
            dispatch(REMOVE_STAFF(deletedStaffId))
            dispatch(
                SET_MESSAGE({
                    text: 'User successfully deleted',
                    type: 'success',
                })
            )
            nav(-1)
        } catch (e) {
            dispatch(
                SET_MESSAGE({
                    text: 'User not deleted',
                    type: 'error',
                })
            )
        }
        setDeleteStaffLoading(false)
    }

    const resendInvite = async (staffId: number) => {
        if (!isAuth) return

        setResendInviteLoading(true)
        try {
            await Apis.staff.resendInviteToStaff(staffId)
            dispatch(
                SET_MESSAGE({
                    text: 'Invitation successfully sent',
                    type: 'success',
                })
            )
        } catch (e) {
            dispatch(
                SET_MESSAGE({
                    text: 'Invitation not sent',
                    type: 'error',
                })
            )
        }
        setResendInviteLoading(false)
    }
    const updateStaff = async (
        updatedStaffData: UpdateStaffProps['body'] & {
            avatar?: File
            id: number
        }
    ) => {
        if (!isAuth) return

        const { avatar, id, ...otherValues } = updatedStaffData
        setUpdateStaffLoading(true)
        try {
            const { data } = await Apis.staff.updateStaff({
                body: otherValues,
                id,
            })
            dispatch(UPDATE_STAFF({ updatedStaffData: data }))
            dispatch(
                SET_MESSAGE({
                    text: 'Сотрудник успешно обновлен',
                    type: 'success',
                })
            )
            nav('/', {
                replace: true,
            })
        } catch (e) {
            dispatch(
                SET_MESSAGE({
                    text: 'User not updated',
                    type: 'error',
                })
            )
        }

        if (avatar) {
            try {
                const formData = new FormData()
                formData.append('file', avatar)
                const { data } = await Apis.staff.updateStaffAvatar({
                    body: formData,
                    id,
                })
                dispatch(UPDATE_STAFF({ updatedStaffData: data }))
            } catch (e) {
                dispatch(
                    SET_MESSAGE({
                        text: 'User avatar not updated',
                        type: 'error',
                    })
                )
            }
        }
        setUpdateStaffLoading(false)
    }

    const restoreStaff = async (
        restoredStaffId: number,
        restoredStaffIndex: number
    ) => {
        if (!isAuth) return

        setRestoreStaffLoading(true)
        try {
            const { data } = await Apis.staff.restoreStaff(restoredStaffId)

            setArchivedStaff((prevState) => {
                const newStaff = [...prevState.items]
                newStaff.splice(restoredStaffIndex, 1)
                return {
                    ...prevState,
                    items: newStaff,
                    count: newStaff.length,
                }
            })

            dispatch(ADD_STAFF(data))

            dispatch(
                SET_MESSAGE({
                    text: 'Сотрудник успешно восстановлен',
                    type: 'success',
                })
            )
        } catch (e: any) {
            dispatch(
                SET_MESSAGE({
                    text: `Не удалось восстановить сотрудника. Ошибка: ${
                        e?.response?.data?.message ?? e.message
                    }`,
                    type: 'error',
                })
            )
        }
        setRestoreStaffLoading(false)
    }

    return {
        getStaff: {
            getStaff: fetchStaff,
            getStaffLoading: loading,
        },
        createStaff: {
            createStaff,
            createStaffLoading,
        },
        deleteStaff: {
            deleteStaff,
            deleteStaffLoading,
        },
        getOneStaff: {
            getOneStaff,
            getOneStaffLoading,
            oneStaff,
        },
        resendInvite: {
            resendInvite,
            resendInviteLoading,
        },
        updateStaff: {
            updateStaff,
            updateStaffLoading,
        },
        getArchivedStaff: {
            getArchivedStaff,
            archivedStaff,
        },
        restoreStaff: {
            restoreStaff,
            restoreStaffLoading,
        },
    }
}
