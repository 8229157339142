import React, { useState } from 'react'
import { Button, Form, Input, Modal, Select } from 'antd'
import { useAppSelector } from '@/redux/hook'
import { ImgPicker, StaffPermissionsPanel } from '@/common'
import { CreateUserModalProps } from './types'
import styled, { createGlobalStyle } from 'styled-components'
import { getAvatarUrl } from '@/utils'
import { useStaff } from '@hooks'
import { CreateStaffProps } from '@apis/staff'

const GlobalStyle = createGlobalStyle`
  .ant-form-item-label {
    max-width: 100%;
  }

  .ant-form-item-control {
    max-width: 100%;
  }
`

const initialState = {
    avatar: undefined,
    full_name: undefined,
    phone_number: undefined,
    group_id: undefined,
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;

    .checkbox-panel {
        left: calc(50% + 70px);
        top: 208px;
    }

    .checkbox-panel__list {
        max-height: 290px;
    }
`

const Field = styled.span`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
`

const Title = styled.h3`
    margin: 0 0 20px 0;
`

export const CreateUserModal: React.FC<CreateUserModalProps> = (props) => {
    const { changeVisibility, visibility } = props
    const staffGroups = useAppSelector((state) => state.staffGroup)
    const teams = useAppSelector((state) => state.team)
    const {
        createStaff: { createStaff, createStaffLoading },
    } = useStaff()

    const [avatar, setAvatar] = useState<(File & { url: string }) | undefined>()
    const [staffPermissions, setStaffPermissions] = useState<number[]>([])

    const onSubmit = async (
        values: Omit<CreateStaffProps['body'], 'staff_permissions'>
    ) => {
        await createStaff({
            ...values,
            phone_number: `+${values.phone_number}`,
            permissions: staffPermissions,
            avatar,
        })
        setAvatar(undefined)
        changeVisibility(false)
    }

    const onCancel = () => {
        changeVisibility(false)
    }

    return (
        <Modal
            open={visibility}
            onCancel={onCancel}
            footer={null}
            destroyOnClose
            width="580px"
        >
            <GlobalStyle />
            <Wrapper>
                <Title>Новый сотрудник</Title>
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={onSubmit}
                    autoComplete="off"
                    layout="vertical"
                    style={{ width: '100%' }}
                    initialValues={initialState}
                >
                    <Field>
                        <ImgPicker
                            src={avatar ? avatar.url : getAvatarUrl(null)}
                            onFilePicked={(file) => setAvatar(file)}
                            size="100px"
                            fileTypes={['.png', '.jpg', '.jpeg']}
                            round={true}
                        />
                        {avatar?.name ?? 'Фото не загружено'}
                    </Field>
                    <Form.Item
                        label="Полное имя"
                        name="full_name"
                        style={{ width: '300px' }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your full name!',
                            },
                        ]}
                    >
                        <Input placeholder="Elon Musk" />
                    </Form.Item>

                    <Form.Item
                        label="Номер телефона"
                        name="phone_number"
                        style={{ width: '300px' }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your phone number!',
                                pattern: /^\d{12}$/,
                            },
                        ]}
                    >
                        <Input
                            style={{ width: '300px' }}
                            placeholder="998991234567"
                            prefix="+"
                        />
                    </Form.Item>

                    <Form.Item
                        label="Группа"
                        name="group_id"
                        style={{ width: '300px' }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your group!',
                            },
                        ]}
                    >
                        <Select
                            loading={staffGroups.loading}
                            placeholder="Маркетолог"
                        >
                            {staffGroups.items.map((staffGroup) => (
                                <Select.Option
                                    key={staffGroup.id}
                                    value={staffGroup.id}
                                >
                                    {staffGroup.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Проект"
                        name="team_id"
                        style={{ width: '300px' }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your team!',
                            },
                        ]}
                    >
                        <Select
                            loading={staffGroups.loading}
                            placeholder="Отдел маркетинга"
                        >
                            {teams.items.map((team) => (
                                <Select.Option key={team.id} value={team.id}>
                                    {team.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={createStaffLoading}
                        >
                            Создать
                        </Button>
                    </Form.Item>
                </Form>
                <StaffPermissionsPanel
                    onChangeStaffPermissions={(values) =>
                        setStaffPermissions(values)
                    }
                    staffPermissions={staffPermissions}
                />
            </Wrapper>
        </Modal>
    )
}
