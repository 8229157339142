import { useAppDispatch, useAppSelector } from '@/redux/hook'
import { useState } from 'react'
import {
    CreateBookCategoryProps,
    GetBookCategoriesProps,
    UpdateBookCategoryProps,
} from '@apis/bookCategory'
import { useRestriction } from './useRestriction'
import {
    ADD_BOOK_CATEGORY,
    getBookCategories,
    REMOVE_BOOK_CATEGORY,
    UPDATE_BOOK_CATEGORY,
} from '@/redux/bookCategory'
import { SET_MESSAGE } from '@/redux/message'
import Apis from '@apis'
import { getBooks } from '@/redux/book'

export const useBookCategory = () => {
    const { loading } = useAppSelector((state) => state.bookCategory)
    const dispatch = useAppDispatch()
    const { isAuth } = useRestriction()

    const [updateBookCategoryLoading, setUpdateBookCategoryLoading] =
        useState(false)
    const [deleteBookCategoryLoading, setDeleteBookCategoryLoading] =
        useState(false)
    const [createBookCategoryLoading, setCreateBookCategoryLoading] =
        useState(false)

    const fetchBookCategory = async (
        params?: Partial<GetBookCategoriesProps['params']>
    ) => {
        if (!isAuth) return

        try {
            await dispatch(getBookCategories(params ?? {}))
        } catch (e: any) {
            await dispatch(
                SET_MESSAGE({
                    text: `Не удалось загрузить категории книг. Ошибка ${
                        e?.response?.data?.message ?? e.message
                    }`,
                    type: 'error',
                })
            )
        }
    }

    const updateBookCategory = async (
        updatedBookCategoryData: UpdateBookCategoryProps,
        updatedBookCategoryIndex: number
    ) => {
        if (!isAuth) return

        setUpdateBookCategoryLoading(true)
        try {
            const { data } = await Apis.bookCategory.updateBookCategory(
                updatedBookCategoryData
            )
            dispatch(
                UPDATE_BOOK_CATEGORY({
                    updatedBookCategoryData: data,
                    updatedBookCategoryIndex,
                })
            )
            await dispatch(getBooks())
            await dispatch(
                SET_MESSAGE({
                    text: 'Категория успешно обновлена',
                    type: 'success',
                })
            )
        } catch (e: any) {
            await dispatch(
                SET_MESSAGE({
                    text: `Не удалось обновить категорию. Ошибка: ${
                        e?.response?.data?.message ?? e.message
                    }`,
                    type: 'error',
                })
            )
        }
        setUpdateBookCategoryLoading(false)
    }

    const createBookCategory = async (
        newBookCategoryData: CreateBookCategoryProps
    ) => {
        if (!isAuth) return

        setCreateBookCategoryLoading(true)
        try {
            const { data } =
                await Apis.bookCategory.createBookCategory(newBookCategoryData)
            dispatch(ADD_BOOK_CATEGORY(data))
            await dispatch(getBooks())
            await dispatch(
                SET_MESSAGE({
                    text: 'Категория успешно создана',
                    type: 'success',
                })
            )
        } catch (e: any) {
            await dispatch(
                SET_MESSAGE({
                    text: `Не удалось создать категорию. Ошибка: ${
                        e?.response?.data?.message ?? e.message
                    }`,
                    type: 'error',
                })
            )
        }
        setCreateBookCategoryLoading(false)
    }

    const deleteBookCategory = async (
        deletedBookCategoryId: number,
        deletedBookCategoryIndex: number
    ) => {
        if (!isAuth) return

        setDeleteBookCategoryLoading(true)
        try {
            await Apis.bookCategory.deleteBookCategory(deletedBookCategoryId)
            await dispatch(getBooks())
            dispatch(REMOVE_BOOK_CATEGORY(deletedBookCategoryIndex))
            await dispatch(
                SET_MESSAGE({
                    text: 'Категория успешно удалена',
                    type: 'success',
                })
            )
        } catch (e: any) {
            await dispatch(
                SET_MESSAGE({
                    text: `Не удалось удалить категорию. Ошибка: ${
                        e?.response?.data?.message ?? e.message
                    }`,
                    type: 'error',
                })
            )
        }
        setDeleteBookCategoryLoading(false)
    }

    return {
        getBookCategories: {
            getBookCategories: fetchBookCategory,
            getBookCategoriesLoading: loading,
        },
        updateBookCategory: {
            updateBookCategory,
            updateBookCategoryLoading,
        },
        createBookCategory: {
            createBookCategory,
            createBookCategoryLoading,
        },
        deleteBookCategory: {
            deleteBookCategory,
            deleteBookCategoryLoading,
        },
    }
}
