import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Apis from '@apis'
import {
    AddLocalRegulationAction,
    AddRegulationAction,
    RegulationStore,
    RegulationStoreGroups,
    RemoveRegulationAction,
} from './types'
import { RootState } from '../store'
import { GetRegulationsProps } from '@apis/regulations'
import { checkIsParentRegulation } from '@/utils'
import { v4 } from 'uuid'

export const initialState: RegulationStore = {
    groups: [],
    loading: false,
    error: null,
}

export const getRegulations = createAsyncThunk<
    RegulationStoreGroups,
    GetRegulationsProps['params'],
    { state: RootState }
>('GET_REGULATIONS', async (payload) => {
    const { data } = await Apis.regulation.getRegulations({ params: payload })
    return {
        group_id: payload.group_id,
        items: data,
    }
})

const RegulationSlice = createSlice({
    name: 'regulation',
    initialState,
    reducers: {
        ADD_REGULATION: (state, action: AddRegulationAction) => {
            const {
                payload: { indexes, regulation },
            } = action
            const isParent = checkIsParentRegulation(regulation)
            const group = state.groups.find(
                (item) => item.group_id === regulation.group_id
            )

            if (group === undefined) return

            if (isParent) {
                group.items.push(regulation)
            } else {
                group.items[indexes[0]].children.push(regulation)
            }
        },
        ADD_LOCAL_REGULATION: (state, action: AddLocalRegulationAction) => {
            const { parent_id, indexes, group_id } = action.payload
            const isParent = parent_id === null
            const group = state.groups.find(
                (item) => item.group_id === group_id
            )

            if (group === undefined) return

            if (isParent) {
                group.items.push({
                    id: v4(),
                    title: '',
                    body: '',
                    parent_id: null,
                    group_id,
                    children: [],
                })
            } else {
                group.items[indexes[0]].children.push({
                    id: v4(),
                    title: '',
                    body: '',
                    parent_id,
                    group_id,
                })
            }
        },
        REMOVE_REGULATION: (state, action: RemoveRegulationAction) => {
            const {
                payload: { indexes, group_id },
            } = action
            const isParent = indexes.length % 2 !== 0
            const group = state.groups.find(
                (item) => item.group_id === group_id
            )

            if (group === undefined) return

            if (isParent) {
                group.items.splice(indexes[0], 1)
            } else {
                group.items[indexes[0]].children.splice(indexes[1], 1)
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getRegulations.pending, (state, action) => {
            state.error = null
            state.loading = true
        })
        builder.addCase(getRegulations.fulfilled, (state, action) => {
            state.loading = false

            const index = state.groups.findIndex(
                (item) => item.group_id === action.payload.group_id
            )

            if (index !== -1) {
                state.groups.splice(index, 1, action.payload)
            } else {
                state.groups.push(action.payload)
            }
        })
        builder.addCase(getRegulations.rejected, (state, action) => {
            state.loading = false
            state.error = action.error
        })
    },
})

export const { ADD_REGULATION, REMOVE_REGULATION, ADD_LOCAL_REGULATION } =
    RegulationSlice.actions
export default RegulationSlice.reducer
