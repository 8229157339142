import httpService from '../httpService'
import {
    CreateAdminProps,
    CreateAdminResponse,
    GetAdminsProps,
    GetAdminsResponse,
    GetOneAdminResponse,
    UpdateAdminProps,
} from './types'

export default {
    getAdmins({ params }: GetAdminsProps) {
        return httpService.get<GetAdminsResponse>('/dashboard/admins', {
            params,
        })
    },
    createAdmin({ body }: CreateAdminProps) {
        return httpService.post<CreateAdminResponse>('/dashboard/admins', body)
    },
    getOneAdmin(id: number) {
        return httpService.get<GetOneAdminResponse>(`/dashboard/admins/${id}`)
    },
    // FIXME: nothing is returned in the backend now, then when something is returned, you need to change the type from <null> to the <UpdateAdminResponse>
    updateAdmin({ body, id }: UpdateAdminProps) {
        return httpService.patch<null>(`/dashboard/admins/${id}`, body)
    },
    deleteAdmin(id: number) {
        return httpService.delete<null>(`/dashboard/admins/${id}`)
    },
}
