import React, { useEffect, useState } from 'react'
import { TopBarLayout } from '@layouts'
import Apis from '@apis'
import { useParams } from 'react-router-dom'
import { Button, Spin, Table } from 'antd'
import type { TableProps } from 'antd'
import {
    GetStaffDinnerReportResponse,
    StaffWeekDinnerInfo,
    WeekDinnerDay,
} from '@apis/staffDinner'
import { Box } from '@components'
import { weekDays } from '@screens/dinners/staffDinner'
import { useStaffDinner } from '@hooks'
import { useAppDispatch } from '@/redux/hook'
import { SET_MESSAGE } from '@/redux/message'
import copy from 'copy-to-clipboard'
import moment from 'moment'

const columns1: TableProps<WeekDinnerDay>['columns'] = [
    {
        title: 'Дни недели',
        render: (_, __, index) => weekDays[index],
    },
    {
        title: 'Обеды с мясом',
        dataIndex: 'total_meat_dish',
    },
    {
        title: 'Обеды без мяса',
        dataIndex: 'total_vegan_dish',
    },
]

const columns2: TableProps<StaffWeekDinnerInfo>['columns'] = [
    {
        title: 'Назание проекта',
        dataIndex: 'staff_team',
        render: (value) => value.name,
    },
    {
        title: 'Обеды с мясом',
        dataIndex: 'total_meat_dish',
    },
    {
        title: 'Обеды без мяса',
        dataIndex: 'total_vegan_dish',
    },
    {
        title: 'Общая сумма',
        dataIndex: 'total_price',
    },
]

const columns3: TableProps<GetStaffDinnerReportResponse>['columns'] = [
    {
        title: 'Обеды с мясом',
        dataIndex: 'total_meat_dish',
    },
    {
        title: 'Обеды без мяса',
        dataIndex: 'total_vegan_dish',
    },
    {
        title: 'Общая сумма',
        dataIndex: 'total_price',
    },
]
const DinnerReport: React.FC = (props) => {
    const { id } = useParams()
    const dispatch = useAppDispatch()

    const [data, setData] = useState<GetStaffDinnerReportResponse>()

    const {
        payForLunchForStaff: {
            payForLunchForStaff,
            payForLunchForStaffLoading,
        },
    } = useStaffDinner()

    useEffect(() => {
        getReport()
    }, [])

    const formatDateRange = (startDate: Date, endDate: Date) => {
        const start = new Date(startDate)
        const end = new Date(endDate)

        const startDay = start.getDate().toString().padStart(2, '0')
        const startMonth = (start.getMonth() + 1).toString().padStart(2, '0')

        const endDay = end.getDate().toString().padStart(2, '0')
        const endMonth = (end.getMonth() + 1).toString().padStart(2, '0')

        const formattedStartDate = `${startDay}.${startMonth}`
        const formattedEndDate = `${endDay}.${endMonth}`

        return `${formattedStartDate}-${formattedEndDate}`
    }

    function formatNumber(number: number | string): string {
        // Преобразуем число в строку и разбиваем его на массив по каждой цифре
        let digits = String(number).split('')

        // Создаем новый массив для форматированных цифр
        let formattedDigits: string[] = []

        // Итерируемся по массиву цифр, начиная с конца
        for (let i = digits.length - 1, j = 1; i >= 0; i--, j++) {
            // Добавляем текущую цифру в начало массива форматированных цифр
            formattedDigits.unshift(digits[i])

            // Если достигнуто условие для добавления разделителя тысячи
            if (j % 3 === 0 && i !== 0) {
                // Добавляем разделитель тысячи в начало массива форматированных цифр
                formattedDigits.unshift(',')
            }
        }

        // Соединяем массив форматированных цифр в строку
        let formattedNumber: string = formattedDigits.join('')

        return formattedNumber
    }

    const getTeamReport = (team_id: number) => {
        if (!data?.staff_week_dinner_info) return ''

        let info = null
        for (const row of data.staff_week_dinner_info) {
            if (row.staff_team.id === team_id) {
                info = row
                break
            }
        }

        if (!info) return ''
        let text = `${moment(new Date()).format('YYYY-MM-DD HH:MM:SS')}\n\n`
        text += `Проект: ${info.staff_team.name}\n`
        text += `Даты: ${formatDateRange(data.start_date, data.end_date)}\n`
        text += `Количество обедов: ${
            info.total_meat_dish + info.total_vegan_dish
        }\n`
        if (+info.total_vegan_dish + +info.total_meat_dish === 0)
            text += 'Сумма: 0*0=0\n'
        else
            text += `Сумма: ${formatNumber(
                +info.total_vegan_dish + +info.total_meat_dish
            )}*${formatNumber(
                checkAndGetNumberValue(info.total_price) /
                    (+info.total_vegan_dish + +info.total_meat_dish)
            )}=${formatNumber(checkAndGetNumberValue(info.total_price))}\n`
        text += `Баланс: ${formatNumber(checkAndGetNumberValue(info.saldo))}\n`
        text += `К оплате: ${formatNumber(
            Number(info.total_price) - (Number(info.saldo) || 0)
        )}\n`

        return text
    }
    const onCopy = async (team_id: number) => {
        if (id) {
            const teamReport = getTeamReport(team_id)
            const { data: data2 } =
                await Apis.staffDinner.getStaffDinnerReportForCopy({
                    dinner_id: +id,
                    team_id,
                })
            if (!data?.staff_week_dinner_info) return ''

            let info = null
            for (const row of data.staff_week_dinner_info) {
                if (row.staff_team.id === team_id) {
                    info = row
                    break
                }
            }

            if (!info) return ''

            let text = '\n'

            for (const member of data2) {
                text += `${member.full_name} - ${
                    member.total_meat_dish + member.total_vegan_dish
                }\n`
            }
            copy(`${teamReport}${text}`)
        }
        dispatch(
            SET_MESSAGE({
                text: 'Скопировано!',
                type: 'success',
            })
        )
    }

    const getReport = async () => {
        if (id) {
            try {
                const { data } = await Apis.staffDinner.getStaffDinnerReport(
                    +id
                )
                setData(data)
            } catch (e) {}
        }
    }

    const onPayForLunchForStaff = async () => {
        if (id) {
            await payForLunchForStaff(+id)
            await getReport()
        }
    }

    const getColumns2 = () => {
        const newColumns = [...columns2]
        if (data?.is_paid) {
            newColumns.push(
                {
                    title: 'Сальдо',
                    dataIndex: 'saldo',
                },
                {
                    title: 'Оплачено',
                    dataIndex: 'paid',
                }
            )
        } else {
            newColumns.push(
                {
                    title: 'Баланс',
                    dataIndex: 'balance',
                },
                {
                    title: 'К оплате',
                    render: (_, { total_price, balance }) =>
                        checkAndGetNumberValue(total_price) -
                        checkAndGetNumberValue(balance),
                }
            )
        }
        newColumns.push({
            title: 'Отчет по сотрудникам',
            render: (_, { team_id }) => (
                <Button onClick={() => onCopy(team_id)}>Скопировать</Button>
            ),
        })

        return newColumns
    }

    const checkAndGetNumberValue = (value: string | null) => {
        return value !== null ? +value : 0
    }

    const text3 = () => {
        if (!data) return ''
        let text = `${moment(new Date()).format('YYYY-MM-DD HH:MM:SS')}\n\n`
        text += `Даты: ${formatDateRange(data.start_date, data.end_date)}\n`
        text += `Количество обедов: ${
            data.total_meat_dish + data.total_vegan_dish
        }\n`
        if (data.total_meat_dish + data.total_vegan_dish === 0)
            text += `Сумма: 0 * 0=0\n`
        else
            text += `Сумма: ${formatNumber(
                checkAndGetNumberValue(data.total_price) /
                    (data.total_meat_dish + data.total_vegan_dish)
            )}*${formatNumber(
                data.total_meat_dish + data.total_vegan_dish
            )}=${formatNumber(checkAndGetNumberValue(data.total_price))}\n`
        text += `Баланс: ${formatNumber(checkAndGetNumberValue(data.saldo))}\n`
        text += `К оплате: ${formatNumber(
            Number(data.total_price)
        )}-${formatNumber(Number(data.saldo) || 0)}=${formatNumber(
            Number(data.total_price) - (Number(data.saldo) || 0)
        )}\n\n`

        data.week_dinner_days.forEach((week, index) => {
            text += `${weekDays[index]} - ${week.total_meat_dish} + ${week.total_vegan_dish}\n`
        })

        copy(text)
        dispatch(
            SET_MESSAGE({
                text: 'Скопировано!',
                type: 'success',
            })
        )
    }

    const getColumns3 = () => {
        const newColumns = [...columns3]
        if (data?.is_paid) {
            newColumns.push(
                {
                    title: 'Сальдо',
                    dataIndex: 'saldo',
                },
                {
                    title: 'Оплачено',
                    dataIndex: 'paid',
                }
            )
        } else {
            newColumns.push(
                {
                    title: 'Баланс',
                    dataIndex: 'balance',
                },
                {
                    title: 'К оплате',
                    render: (_, { total_price, balance }) =>
                        checkAndGetNumberValue(total_price) -
                        checkAndGetNumberValue(balance),
                }
            )
        }
        newColumns.push({
            title: 'Отчет для оплаты',
            render: () => <Button onClick={text3}>Скопировать</Button>,
        })

        return newColumns
    }

    return (
        <TopBarLayout
            title={`Отчет обедов - ${data?.title}`}
            withGoBack
            postfix={
                data?.is_paid !== true && (
                    <Button
                        loading={payForLunchForStaffLoading}
                        onClick={onPayForLunchForStaff}
                    >
                        Оплатить
                    </Button>
                )
            }
        >
            {data !== undefined ? (
                <Box
                    style={{
                        flexDirection: 'column',
                        alignItems: 'stretch',
                        gap: '40px',
                    }}
                >
                    <Box
                        style={{
                            flexDirection: 'column',
                            alignItems: 'stretch',
                        }}
                    >
                        <h3 style={{ textAlign: 'center' }}>
                            Отчет по неделям
                        </h3>
                        <Table
                            dataSource={data.week_dinner_days}
                            columns={columns1}
                            pagination={false}
                            rowKey="id"
                        />
                    </Box>
                    <Box
                        style={{
                            flexDirection: 'column',
                            alignItems: 'stretch',
                        }}
                    >
                        <h3 style={{ textAlign: 'center' }}>
                            Отчет по проектам
                        </h3>
                        <Table
                            dataSource={data.staff_week_dinner_info}
                            columns={getColumns2()}
                            pagination={false}
                            rowKey="id"
                        />
                    </Box>
                    <Box
                        style={{
                            flexDirection: 'column',
                            alignItems: 'stretch',
                        }}
                    >
                        <h3 style={{ textAlign: 'center' }}>Общий отчет</h3>
                        <Table
                            dataSource={[data]}
                            columns={getColumns3()}
                            pagination={false}
                            rowKey="id"
                        />
                    </Box>
                </Box>
            ) : (
                <Box
                    style={{
                        width: '100%',
                        height: '100%',
                        justifyContent: 'center',
                    }}
                >
                    <Spin />
                </Box>
            )}
        </TopBarLayout>
    )
}

export default DinnerReport
