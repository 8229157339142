import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '@/redux/hook'
import { LoginProps } from '@apis/auth'
import Apis from '@apis'
import { LOG_OUT, SET_USER } from '@/redux/user'
import { SET_MESSAGE } from '@/redux/message'

export const useAuth = () => {
    const nav = useNavigate()
    const dispatch = useAppDispatch()

    const [logInLoading, setLogInLoading] = useState(false)
    const [logOutLoading, setLogOutLoading] = useState(false)
    const [checkAuthLoading, setCheckAuthLoading] = useState(false)

    const logIn = async (values: LoginProps['body']) => {
        setLogInLoading(true)
        try {
            const response = await Apis.auth.login({ body: values })
            if (response.status === 201) {
                await dispatch(SET_USER({ ...response.data, is_auth: true }))
                nav('/', {
                    replace: true,
                })
            }
        } catch (e) {
            await dispatch(
                SET_MESSAGE({
                    text: 'Incorrect email or password',
                    type: 'error',
                }),
            )
        }
        setLogInLoading(false)
    }

    const logOut = async () => {
        setLogOutLoading(true)
        try {
            await Apis.auth.logout()
            await dispatch(LOG_OUT())
            nav('/auth', {
                replace: true,
            })
        } catch (e) {
            await dispatch(
                SET_MESSAGE({
                    text: 'Не удалось выйти',
                    type: 'error',
                }),
            )
        }
        setLogOutLoading(false)
    }

    const checkAuth = async () => {
        setCheckAuthLoading(true)
        try {
            const { data } = await Apis.auth.checkAuth()
            dispatch(SET_USER({ ...data, is_auth: true }))
            nav(-1)
        } catch (e) {
            await dispatch(LOG_OUT())
            await dispatch(
                SET_MESSAGE({
                    text: 'Вы не авторизованы',
                    type: 'error',
                }),
            )
        }
        setCheckAuthLoading(false)
    }

    return {
        logIn: {
            logIn,
            logInLoading,
        },
        logOut: {
            logOut,
            logOutLoading,
        },
        checkAuth: {
            checkAuth,
            checkAuthLoading,
        },
    }
}
