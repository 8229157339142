import React from 'react'
import { CreateAdminModalProps } from './types'
import { Button, Form, Input, Modal } from 'antd'
import styled from 'styled-components'
import { useAdmin } from '@hooks'

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
`
export const CreateAdminModal: React.FC<CreateAdminModalProps> = (props) => {
    const { visibility, changeVisibility, mode, values } = props
    const {
        updateAdmin: { updateAdmin, updateAdminLoading },
        createAdmin: { createAdmin, createAdminLoading },
    } = useAdmin()

    const onSubmit = async (props: { email: string; password: string }) => {
        if (mode === 'create') {
            await createAdmin(props)
        } else if (values?.data) {
            await updateAdmin({ body: props, id: values.data.id })
        }
        changeVisibility(false)
    }

    return (
        <Modal
            open={visibility}
            onCancel={() => changeVisibility(false)}
            footer={null}
            destroyOnClose
            title={mode === 'edit' ? 'Изменить админа' : 'Новый админ'}
            width="350px"
        >
            <Wrapper>
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={onSubmit}
                    autoComplete="off"
                    layout="vertical"
                    initialValues={values?.data}
                >
                    <Form.Item
                        label="Почта"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your email!',
                                type: 'email',
                            },
                        ]}
                    >
                        <Input style={{ width: '300px' }} />
                    </Form.Item>

                    <Form.Item
                        label="Пароль"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                                min: 8,
                            },
                        ]}
                    >
                        <Input.Password style={{ width: '300px' }} />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={updateAdminLoading || createAdminLoading}
                            style={{ textAlign: 'center' }}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Wrapper>
        </Modal>
    )
}
