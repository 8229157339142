import React from 'react'
import { useStaffPermission } from '@hooks'
import { Button, Form, Input, Modal } from 'antd'

type CreateStaffPermissionModalProps = {
    open: boolean
    setOpen: (open: boolean) => void
}

export const CreateStaffPermissionModal: React.FC<
    CreateStaffPermissionModalProps
> = (props) => {
    const { open, setOpen } = props
    const {
        createStaffPermission: {
            createStaffPermission,
            createStaffPermissionLoading,
        },
    } = useStaffPermission()

    const onCreate = async ({ name }: { name: string }) => {
        await createStaffPermission({ body: { name } })
        setOpen(false)
    }

    return (
        <Modal
            open={open}
            onCancel={() => setOpen(false)}
            footer={null}
            width="350px"
            destroyOnClose
            title="Новый доступ"
        >
            <Form onFinish={onCreate} layout="vertical">
                <Form.Item
                    label="Название"
                    rules={[
                        { required: true, message: 'Это обязательное поле' },
                    ]}
                    name="name"
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button
                        loading={createStaffPermissionLoading}
                        htmlType="submit"
                    >
                        Создать
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}
