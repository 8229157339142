import React, { useId } from 'react'
import { NameModalProps } from './types'
import { Button, Form, Input, Modal } from 'antd'

export const NameModal: React.FC<NameModalProps> = (props) => {
    const {
        title,
        open,
        onVisibilityChange,
        onFinish,
        defaultValue,
        loading,
    } = props
    const formId = useId()

    const onClose = () => {
        onVisibilityChange(false)
    }

    const handleFinish: NameModalProps['onFinish'] = (values) => {
        onFinish(values)
        onVisibilityChange(false)
    }

    return (
        <Modal
            open={open}
            onCancel={onClose}
            title={title}
            width='330px'
            destroyOnClose
            okButtonProps={{
                form: formId,
                htmlType: 'submit',
                loading,
            }}
        >
            <Form
                id={formId}
                onFinish={handleFinish}
                layout='vertical'
                initialValues={{
                    name: defaultValue ?? '',
                }}
            >
                <Form.Item
                    label='Название'
                    rules={[
                        { required: true, message: 'Это обязательное поле' },
                    ]}
                    name='name'
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    )
}
