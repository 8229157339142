import React, { useMemo } from 'react'
import { TopBarLayout } from '@layouts'
import { useAppSelector } from '@/redux/hook'
import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import { RootRegulation } from '@screens/common/regulations'

const Regulations: React.FC = () => {
    const { staffGroup } = useAppSelector((state) => state)

    const items = useMemo<TabsProps['items']>(() => {
        return staffGroup.items.map((staffGroup) => ({
            label: staffGroup.name,
            key: String(staffGroup.id),
            children: <RootRegulation groupId={staffGroup.id} indexes={[]} />,
        }))
    }, [staffGroup.items])

    return (
        <TopBarLayout title="Регламент">
            <Tabs items={items} />
        </TopBarLayout>
    )
}

export default Regulations
