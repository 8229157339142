import React, { useEffect, useState, useRef, useCallback } from 'react'
import styled from 'styled-components'
import { Box } from '@components'
import { OneLocalRegulation } from '@apis/regulations'
import { Button, Divider, Input } from 'antd'
import { MdOutlineClose } from 'react-icons/md'
import { RegulationsList } from './regulationsList'
import {
    checkIsNotLocal,
    checkIsNotLocalParentRegulation,
    checkIsParentRegulation,
} from '@/utils'
import { useAppDispatch } from '@/redux/hook'
import { REMOVE_REGULATION } from '@/redux/regulation'
import { ChangelogModal } from '@screens/common/regulations/changelogModal'
import { useRegulation } from '@hooks'
import JoditEditor, { Jodit } from 'jodit-react'

type RegulationsListItemProps = {
    indexes: number[]
    regulation: OneLocalRegulation
    groupId: number
}

const RegulationCardStyled = styled(Box)`
    flex-direction: column;
    align-items: stretch;
    gap: 15px;
    padding: 10px;
    border: 1px solid black;
    border-radius: 3px;
    width: 100%;
`

const EditorWrapper = styled(Box)`
    display: grid;
`

export const RegulationsListItem: React.FC<RegulationsListItemProps> = (
    props
) => {
    const { indexes, regulation, groupId } = props
    const dispatch = useAppDispatch()
    const {
        updateRegulation: { updateRegulation, updateRegulationLoading },
        createRegulation: { createRegulation, createRegulationLoading },
        deleteRegulation: { deleteRegulation, deleteRegulationLoading },
    } = useRegulation()

    const editorRef = useRef<Jodit | null>(null)
    const [bodyValue, setBodyValue] = useState(regulation.body)
    const [titleValue, setTitleValue] = useState(regulation.title)
    const [changelogOpen, setChangelogOpen] = useState(false)
    const [mode, setMode] = useState<'delete' | 'createOrUpdate'>(
        'createOrUpdate'
    )

    const isParent = checkIsParentRegulation(regulation)
    const isNotLocalParent = checkIsNotLocalParentRegulation(regulation)
    const isNotLocal = checkIsNotLocal(regulation)

    useEffect(() => {
        console.log('Initial bodyValue:', bodyValue)
    }, [])

    const handleSave = async (withChangelog: boolean) => {
        if (withChangelog) {
            setChangelogOpen(true)
            setMode('createOrUpdate')
            return
        }

        if (isNotLocal) {
            await updateRegulation({
                body: {
                    parent_id: regulation.parent_id,
                    changelog: null,
                    title: titleValue,
                    body: bodyValue,
                    group_id: regulation.group_id,
                },
                id: regulation.id,
            })
        } else {
            await createRegulation({
                body: {
                    parent_id: regulation.parent_id,
                    changelog: null,
                    title: titleValue,
                    body: bodyValue,
                    group_id: regulation.group_id,
                },
            })
        }
    }

    const handleDelete = () => {
        if (isNotLocal) {
            setMode('delete')
            setChangelogOpen(true)
            return
        }

        dispatch(REMOVE_REGULATION({ indexes, group_id: groupId }))
    }

    const onFinish = async ({ changelog }: { changelog: string }) => {
        setChangelogOpen(false)
        if (mode === 'createOrUpdate') {
            if (isNotLocal) {
                await updateRegulation({
                    body: {
                        title: titleValue,
                        body: bodyValue,
                        parent_id: regulation.parent_id,
                        group_id: groupId,
                        changelog,
                    },
                    id: regulation.id,
                })
            } else {
                await createRegulation({
                    body: {
                        title: titleValue,
                        body: bodyValue,
                        parent_id: regulation.parent_id,
                        group_id: groupId,
                        changelog,
                    },
                })
            }
        } else {
            isNotLocal &&
                (await deleteRegulation({
                    id: regulation.id,
                    group_id: groupId,
                    params: { changelog },
                }))
        }
    }

    const checkIsDisabled = () => {
        if (isParent) return titleValue.length === 0
        return (
            titleValue.length === 0 ||
            bodyValue === '<p><br></p>' ||
            bodyValue === ''
        )
    }

    const getBodyIsPermitted = () => {
        let result = true

        if (isNotLocalParent) {
            result = regulation.children.length === 0
        }

        return result
    }

    return (
        <RegulationCardStyled as="li">
            <Button
                onClick={handleDelete}
                style={{ marginLeft: 'auto' }}
                danger
                type="primary"
                icon={<MdOutlineClose size="24px" />}
                loading={deleteRegulationLoading}
                disabled={
                    isParent &&
                    regulation.children &&
                    regulation.children.length !== 0
                }
            />
            <Input
                value={titleValue}
                onChange={(e) => setTitleValue(e.target.value)}
                style={{ width: '100%' }}
            />
            {getBodyIsPermitted() && (
                <EditorWrapper>
                    <JoditEditor
                        ref={editorRef}
                        value={bodyValue}
                        config={{
                            buttons: [
                                'source',
                                '|',
                                'bold',
                                'italic',
                                'underline',
                                'strikethrough',
                                'link',
                                '|',
                                'ul',
                                'ol',
                                '|',
                                'font',
                                'fontsize',
                                '|',
                                'undo',
                                'redo',
                                '|',
                                'hr',
                            ],
                        }}
                        onBlur={(newValue: any) => {
                            setBodyValue(newValue)
                        }}
                    />
                </EditorWrapper>
            )}
            <Box
                style={{
                    justifyContent: isNotLocal ? 'flex-end' : 'center',
                    gap: '20px',
                }}
            >
                {isNotLocal && (
                    <Button
                        onClick={() => handleSave(false)}
                        type="primary"
                        disabled={checkIsDisabled()}
                        loading={
                            updateRegulationLoading || createRegulationLoading
                        }
                    >
                        Сохранить
                    </Button>
                )}
                <Button
                    onClick={() => handleSave(true)}
                    type="primary"
                    disabled={checkIsDisabled()}
                    loading={updateRegulationLoading || createRegulationLoading}
                >
                    Сохранить с Changelog
                </Button>
            </Box>
            {isNotLocalParent && (
                <Box style={{ flexDirection: 'column' }}>
                    <Divider />
                    <RegulationsList
                        groupId={groupId}
                        regulations={regulation.children}
                        indexes={indexes}
                        parent_id={regulation.id}
                    />
                </Box>
            )}
            <ChangelogModal
                open={changelogOpen}
                onClose={() => setChangelogOpen(false)}
                onFinish={onFinish}
            />
        </RegulationCardStyled>
    )
}
