import React, { useEffect, useState } from 'react'
import { TopBarLayout } from '@layouts'
import { useAppSelector } from '@/redux/hook'
import type { TableProps } from 'antd'
import { Button, DatePicker, Modal, Table } from 'antd'
import { GetStaffDinnersResponseItem } from '@apis/staffDinner'
import { useStaffDinner } from '@hooks'
import { getColumnData } from '@/utils'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { Box } from '@components'
import dayjs, { Dayjs } from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'

dayjs.extend(weekOfYear)

const columns: TableProps<GetStaffDinnersResponseItem>['columns'] = [
    {
        title: '№',
        dataIndex: 'index',
        render: (_: any, __: any, index: number) => index + 1,
        width: '5%',
        align: 'center',
        responsive: ['xs', 'sm'],
    },
    {
        title: `Даты`,
        dataIndex: 'title',
        render: getColumnData,
        width: '18%',
        align: 'center',
    },
    {
        title: 'Дедлайн',
        dataIndex: 'deadline',
        render: (value) =>
            value
                ? moment(value).format('DD/MM/YYYY H:mm')
                : getColumnData(value),
        align: 'center',
        width: '15%',
    },
    {
        title: 'Статус',
        dataIndex: 'is_paid',
        render: (value, { id }) => (value ? 'оплачено' : 'не оплачено'),
        align: 'center',
    },
    {
        title: 'Общее кол-во обедов',
        render: (_, { total_meat_dish, total_vegan_dish }) =>
            total_meat_dish + total_vegan_dish,
        align: 'center',
        width: '10%',
    },
    {
        title: 'Цена',
        dataIndex: 'total_price',
        render: getColumnData,
        align: 'center',
    },
]

const Dinners: React.FC = () => {
    const {
        items,
        count,
        loading,
        filters: { page, limit },
    } = useAppSelector((state) => state.staffDinner)
    const {
        getStaffDinners: { getStaffDinners },
        createStaffMenu: { createStaffMenu, createStaffMenuLoading },
    } = useStaffDinner()
    const nav = useNavigate()

    const [open, setOpen] = useState(false)
    const [weekValue, setWeekValue] = useState<{
        week_no: number
        year: number
        dayjs: Dayjs
    }>()

    const onCreateStaffMenu = async () => {
        await createStaffMenu({
            body: {
                week_no: weekValue?.week_no ?? null,
                year: weekValue?.year ?? null,
            },
        })
    }

    const onClick = (event: React.MouseEvent<any, MouseEvent>, id: number) => {
        nav(String(id))
    }

    const onChangeWeek = (dayjsValue: Dayjs | null, weekValue: string) => {
        const now = dayjs()
        if (dayjsValue && now <= dayjsValue) {
            setWeekValue({
                week_no: +weekValue.split('-')[1],
                year: +weekValue.split('-')[0],
                dayjs: dayjsValue,
            })
        } else {
            setWeekValue(undefined)
        }
    }

    const onClose = () => {
        setWeekValue(undefined)
        setOpen(false)
    }

    useEffect(() => {
        if (!count) {
            getStaffDinners()
        }
    }, [])

    const checkDisabled = (date: Dayjs) => {
        const now = dayjs()

        if (date < now) return true

        if (
            now.year() === date.year() &&
            (now.month() === date.month() || now.month() + 1 === date.month())
        ) {
            return now.week() === date.week() || now.week() === date.week()
        }

        return false
    }

    return (
        <TopBarLayout
            title="Обеды"
            postfix={
                <Button onClick={() => setOpen(true)}>Добавить меню</Button>
            }
        >
            <Table<GetStaffDinnersResponseItem>
                dataSource={items}
                loading={loading}
                columns={columns}
                rowKey="id"
                onRow={({ id }) => ({
                    onClick: (event) => onClick(event, id),
                })}
                pagination={{
                    total: count,
                    current: page,
                    pageSize: limit,
                    onChange: (page, pageSize) =>
                        getStaffDinners({ page, limit: pageSize }),
                }}
            />
            <Modal
                open={open}
                onCancel={onClose}
                footer={null}
                destroyOnClose
                width="300px"
                title="Новое меню"
            >
                <Box
                    style={{
                        flexDirection: 'column',
                        gap: '30px',
                        padding: '20px 0',
                    }}
                >
                    <DatePicker
                        picker="week"
                        onChange={onChangeWeek}
                        allowClear={false}
                        value={weekValue?.dayjs ?? null}
                        disabledDate={checkDisabled}
                    />
                    <Button
                        loading={createStaffMenuLoading}
                        onClick={onCreateStaffMenu}
                    >
                        Создать
                    </Button>
                </Box>
            </Modal>
        </TopBarLayout>
    )
}

export default Dinners
