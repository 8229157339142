import httpService from '../httpService'
import { LoginProps, LoginResponse } from './types'

export default {
    login({ body }: LoginProps) {
        return httpService.post<LoginResponse>('/dashboard/auth', body)
    },
    checkAuth() {
        return httpService.get<LoginResponse>('/dashboard/auth')
    },
    logout() {
        return httpService.delete('/dashboard/auth')
    },
}
