import React, { useEffect, useState } from 'react'
import { useAppSelector } from '@/redux/hook'
import { useBookCategory } from '@hooks'
import { Button, Popconfirm, Table, TableProps } from 'antd'
import { TopBarLayout } from '@layouts'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import { getColumnData } from '@/utils'
import { GetBookCategoriesResponseItem } from '@apis/bookCategory'
import { CreateAndEditBookCategory } from '@screens/common/settings/createAndEditBookCategory'

const columns: TableProps<GetBookCategoriesResponseItem>['columns'] = [
    {
        title: '№',
        dataIndex: 'index',
        render: (_: any, __: any, index: number) => index + 1,
        width: '5%',
        align: 'center',
        responsive: ['xs', 'sm'],
    },
    {
        title: 'Название',
        dataIndex: 'name',
        render: getColumnData,
    },
]

const BookCategories: React.FC = () => {
    const {
        items,
        loading,
        count,
        filters: { search, page, limit },
    } = useAppSelector((state) => state.bookCategory)
    const {
        getBookCategories: { getBookCategories },
        createBookCategory: { createBookCategory, createBookCategoryLoading },
        updateBookCategory: { updateBookCategory, updateBookCategoryLoading },
        deleteBookCategory: { deleteBookCategory, deleteBookCategoryLoading },
    } = useBookCategory()
    const [searchValue, setSearchValue] = useState(search || '')
    const [open, setOpen] = useState(false)
    const [mode, setMode] = useState<'create' | 'edit'>('create')
    const [editedBookCategoryName, setEditedBookCategoryName] = useState('')
    const [editedBookCategoryIndex, setEditedBookCategoryIndex] = useState(0)
    const [editedBookCategoryId, setEditedBookCategoryId] = useState(0)

    const onSearch = async () => {
        if (searchValue) {
            await getBookCategories({ page: 1, search: searchValue })
        } else {
            await getBookCategories({ page: 1, search: undefined })
        }
    }

    const onEdit = (
        values: GetBookCategoriesResponseItem,
        bookCategoryIndex: number
    ) => {
        setMode('edit')
        setEditedBookCategoryName(values.name)
        setEditedBookCategoryId(values.id)
        setEditedBookCategoryIndex(bookCategoryIndex)
        setOpen(true)
    }

    const onCreate = () => {
        setOpen(true)
        setMode('create')
    }

    const onDelete = async (
        deletedBookCategoryId: number,
        deletedBookCategoryIndex: number
    ) => {
        await deleteBookCategory(
            deletedBookCategoryId,
            deletedBookCategoryIndex
        )
    }

    const getColumns = () => {
        const result = [...columns]
        result.push(
            {
                title: <AiOutlineEdit style={{ width: '100%' }} size={20} />,
                render: (_, values, staffGroupIndex) => (
                    <Button onClick={() => onEdit(values, staffGroupIndex)}>
                        Изменить
                    </Button>
                ),
                align: 'center',
                width: '15%',
            },
            {
                title: <AiOutlineDelete style={{ width: '100%' }} size={20} />,
                render: (_, { id, name }, staffGroupIndex) => (
                    <>
                        <Popconfirm
                            title="Удаление группы"
                            description={
                                0 === 0
                                    ? 'Вы хотите удалить эту категорию?'
                                    : `Невозможно удалить категорию, так как есть книги, которые относятся к этой категории.
                                Для удаления группы она должна быть пустой.`
                            }
                            okText="Yes"
                            okButtonProps={{ disabled: !!false }}
                            cancelText="No"
                            onConfirm={() => onDelete(id, staffGroupIndex)}
                        >
                            <Button danger loading={deleteBookCategoryLoading}>
                                Удалить
                            </Button>
                        </Popconfirm>
                    </>
                ),
                align: 'center',
                width: '15%',
            }
        )
        return result
    }

    useEffect(() => {
        if (!count) {
            getBookCategories()
        }
    }, [])

    return (
        <TopBarLayout
            withSearchBox
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onSearch={onSearch}
            postfix={<Button onClick={onCreate}>Новая категория</Button>}
        >
            <Table<GetBookCategoriesResponseItem>
                dataSource={items}
                columns={getColumns()}
                rowKey="id"
                loading={loading}
                pagination={{
                    total: count,
                    pageSize: limit,
                    hideOnSinglePage: true,
                    onChange: (page) => getBookCategories({ page }),
                    current: page,
                }}
            />
            {mode === 'create' ? (
                <CreateAndEditBookCategory
                    open={open}
                    onVisibilityChange={(open) => setOpen(open)}
                    mode="create"
                />
            ) : (
                <CreateAndEditBookCategory
                    open={open}
                    onVisibilityChange={(open) => setOpen(open)}
                    mode="edit"
                    editedBookCategoryName={editedBookCategoryName}
                    editedBookCategoryIndex={editedBookCategoryIndex}
                    editedBookCategoryId={editedBookCategoryId}
                />
            )}
        </TopBarLayout>
    )
}

export default BookCategories
