import React, { useEffect, useState } from 'react'
import { TopBarLayout } from '@layouts'
import { useParams } from 'react-router-dom'
import { useStaffDinner } from '@hooks'
import { Box } from '@components'
import { Button, Checkbox, Spin, Table } from 'antd'
import type { TableProps } from 'antd'
import moment from 'moment'
import styled from 'styled-components'
import { StaffDinnerStatus } from '@apis/types'
import { UpdateStaffDinnerPerWeekPropsBodyItem } from '@apis/staffDinner'

export const weekDays = [
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
    'Воскресенье',
]

const Staff = styled.p`
    margin: 0;
`

const StaffTeam = styled.p`
    margin: 0;
    font-weight: 600;
    font-size: 16px;
`

const StaffGroup = styled.p`
    margin: 0;
`

const CustomCheckbox = (props: {
    disabled: boolean
    state: StaffDinnerStatus
    onChange: (state: StaffDinnerStatus) => void
}) => {
    const { state, onChange } = props

    const handleChange = () => {
        switch (state) {
            case 0:
                onChange(1)
                break
            case 1:
                onChange(2)
                break
            case 2:
                onChange(0)
                break
        }
    }
    return (
        <Box
            style={{
                width: '25px',
                height: '25px',
                justifyContent: 'center',
                borderRadius: '5px',
                backgroundColor: state === 2 ? 'greenyellow' : 'white',
            }}
        >
            <Checkbox
                disabled={props.disabled}
                checked={!!state}
                onChange={handleChange}
            />
        </Box>
    )
}

const TableTitle: React.FC<{ menu: string[] }> = (props) => {
    const { menu } = props
    if (menu.length === 0) return <>Свободный день</>
    return (
        <ol>
            {menu.map((menuItem, index) => (
                <li key={menuItem + index}>{menuItem}</li>
            ))}
        </ol>
    )
}

export const StaffDinner: React.FC = () => {
    const { id } = useParams()
    const {
        getOneStaffDinner: { getOneStaffDinner, oneStaffDinner },
        getStaffDinnerPerWeek: { getStaffDinnerPerWeek, staffDinnerPerWeek },
        updateStaffDinnerPerWeek: {
            updateStaffDinnerPerWeek,
        },
    } = useStaffDinner()

    const [items, setItems] = useState<any[]>([])
    const [data, setData] = useState<UpdateStaffDinnerPerWeekPropsBodyItem[]>(
        []
    )
    const getValue = (key: 'staff' | 'group' | 'team', data: any) => {
        switch (key) {
            case 'group':
                return <StaffGroup>{data.staff_group.name}</StaffGroup>
            case 'team':
                return <StaffTeam>{data.staff_team.name}</StaffTeam>
            case 'staff':
                return <Staff>{data.staff.name}</Staff>
        }
    }

    const onToggle = (
        status: StaffDinnerStatus,
        staff_id: number,
        week_dinner_day_id: number,
        index: number
    ) => {
        setItems((prevState) =>
            prevState.map((item) => {
                if (item.key === 'staff' && item.staff.id === staff_id) {
                    const statuses = [...item.staff.status]
                    statuses.splice(index, 1, status)
                    const result = {
                        ...item,
                        staff: {
                            ...item.staff,
                            status: statuses,
                        },
                    }

                    return { ...result }
                }

                return { ...item }
            })
        )

        let isExist = false
        const result = data.map((item) => {
            if (
                item.staff_id === staff_id &&
                week_dinner_day_id === item.week_dinner_day_id
            ) {
                isExist = true
                return {
                    ...item,
                    status,
                }
            }

            return { ...item }
        })

        if (isExist) setData([...result])
        else
            setData((prevState) => [
                ...prevState,
                {
                    staff_id,
                    status,
                    week_dinner_day_id,
                },
            ])
    }

    const getColumns = () => {
        const columns: TableProps<any>['columns'] = []

        if (oneStaffDinner !== undefined && staffDinnerPerWeek) {
            const weekColumns: TableProps<any>['columns'] =
                oneStaffDinner.week_dinner_days.map((day, index) => {
                    return {
                        title: moment(day.date).format('DD/MM'),
                        children: [
                            {
                                title: weekDays[index],
                                children: [
                                    {
                                        title: <TableTitle menu={day.menu} />,
                                        render: (_, { key, staff }) =>
                                            key === 'staff' && (
                                                <CustomCheckbox
                                                    onChange={(status) =>
                                                        onToggle(
                                                            status,
                                                            staff.id,
                                                            oneStaffDinner
                                                                .week_dinner_days[
                                                                index
                                                            ].id,
                                                            index
                                                        )
                                                    }
                                                    state={staff.status[index]}
                                                    disabled={day.is_free}
                                                />
                                            ),
                                    },
                                ],
                            },
                        ],
                    }
                })

            columns.push(
                {
                    title: oneStaffDinner.title,
                    children: [
                        {
                            title: 'ФИО',
                            dataIndex: 'key',
                            width: '250px',
                            render: (key, data) => getValue(key, data),
                        },
                    ],
                },
                ...weekColumns
            )
        }

        return columns
    }

    useEffect(() => {
        if (id) {
            getOneStaffDinner(+id)
            getStaffDinnerPerWeek(+id)
        }
    }, [])

    useEffect(() => {
        if (staffDinnerPerWeek) {
            const result: any[] = []
            staffDinnerPerWeek.items.forEach((team) => {
                result.push({
                    id: `${team.id}`,
                    key: 'team',
                    staff_team: team,
                })
                team.staff.forEach((staff: any) => {
                    result.push({
                        id: `${team.id}, ${staff.id}`,
                        key: 'staff',
                        staff: staff,
                    })
                })
            })

            setItems(result)
        }
    }, [staffDinnerPerWeek])

    const onFinish = () => {
        if (id) {
            updateStaffDinnerPerWeek({
                id: +id,
                body: {
                    items: data,
                },
            })
        }
    }
    return (
        <TopBarLayout
            title="Обед сотрудников"
            withGoBack
            postfix={<Button onClick={onFinish}>Сохранить</Button>}
        >
            {staffDinnerPerWeek !== undefined &&
            oneStaffDinner !== undefined ? (
                <Box>
                    <Table
                        columns={getColumns()}
                        style={{ width: '100%' }}
                        dataSource={items}
                        bordered
                        rowKey="id"
                        pagination={false}
                    ></Table>
                </Box>
            ) : (
                <Box
                    style={{
                        width: '100%',
                        height: '100%',
                        justifyContent: 'center',
                    }}
                >
                    <Spin />
                </Box>
            )}
        </TopBarLayout>
    )
}

export default StaffDinner
