import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Apis from '@apis'
import { QrCodeStore } from './types'
import { RootState } from '../store'
import {
    GetQrCodesProps,
    GetQrCodesResponse,
    GetQrCodesResponseItem,
} from '@apis/qrCode'

export const initialState: QrCodeStore = {
    items: [],
    filters: {
        page: 1,
        limit: 20,
    },
    count: 0,
    loading: false,
    error: null,
}

export const getQrCodes = createAsyncThunk<
    { data: GetQrCodesResponse; filters: GetQrCodesProps['params'] },
    Partial<GetQrCodesProps['params']>,
    { state: RootState }
>('GET_QR_CODES', async (payload, thunkAPI) => {
    const { qrCode } = thunkAPI.getState()
    const filters = { ...qrCode.filters, ...payload }
    const { data } = await Apis.qrCode.getQrCodes({ params: filters })
    return { data, filters }
})

// export const deleteQrCode = createAsyncThunk<
//     void,
//     { id: string },
//     { state: RootState }
// >('DELETE_QR_CODE', async ({ id }, thunkAPI) => {
//     await Apis.qrCode.deleteQrCode(id)
// })

const QrCodeSlice = createSlice({
    name: 'qrCodes',
    initialState,
    reducers: {
        ADD_QR_CODE: (state, action: { payload: GetQrCodesResponseItem }) => {
            state.items.push(action.payload)
            state.count = state.items.length
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getQrCodes.pending, (state, action) => {
            state.error = null
            state.loading = true
        })
        builder.addCase(getQrCodes.fulfilled, (state, action) => {
            const {
                filters,
                data: { items, count },
            } = action.payload
            state.filters = filters
            state.items = [...items]
            state.count = count
            state.loading = false
        })
        builder.addCase(getQrCodes.rejected, (state, action) => {
            state.loading = false
            state.error = action.error
        })
    },

})

export const { ADD_QR_CODE } = QrCodeSlice.actions
export default QrCodeSlice.reducer
