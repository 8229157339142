import React, { useEffect, useState } from 'react'
import { ImgPicker, StaffPermissionsPanel } from '@common'
import { Button, Form, Input, Popconfirm, Select, Spin } from 'antd'
import styled from 'styled-components'
import { useAppSelector } from '@/redux/hook'
import { useParams } from 'react-router-dom'
import { UpdateStaffProps } from '@apis/staff'
import { getAvatarUrl } from '@/utils'
import { useStaff } from '@hooks'
import { TopBarLayout } from '@layouts'
import { Box } from '@components'
import { StaffBooksPanel } from '@common/staffBooksPanel'

const Field = styled.span`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
`

const SpinWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const StyledBox = styled(Box)`
    position: relative;

    .checkbox-panel {
        left: calc(50% + 180px);
    }

    .books-panel {
        left: calc(50% - 400px);
    }
`

const OneStaff: React.FC = () => {
    const { team, staffGroup } = useAppSelector((state) => state)
    const { id } = useParams()
    const {
        getOneStaff: { getOneStaff, getOneStaffLoading, oneStaff },
        updateStaff: { updateStaff, updateStaffLoading },
        deleteStaff: { deleteStaff, deleteStaffLoading },
    } = useStaff()

    const [avatar, setAvatar] = useState<any>()
    const [staffPermissions, setStaffPermissions] = useState<number[]>([])

    useEffect(() => {
        if (id) {
            getOneStaff(+id)
        }
    }, [id])

    useEffect(() => {
        if (oneStaff)
            setStaffPermissions(
                oneStaff.staff_permissions.map(
                    (staffPermissions) => staffPermissions.id
                )
            )
    }, [oneStaff])

    const onUpdateStaff = async (
        values: Omit<UpdateStaffProps['body'], 'staff_permissions'>
    ) => {
        if (id) {
            await updateStaff({
                ...values,
                phone_number: `+${values.phone_number}`,
                id: +id,
                avatar,
                permissions: staffPermissions,
            })
        }
    }

    const onDeleteStaff = async () => {
        if (id === undefined) return

        if (
            oneStaff &&
            oneStaff.staff_permissions.length &&
            staffPermissions.length === 0
        ) {
            await updateStaff({ permissions: [], id: +id })
        }
        await deleteStaff(+id)
    }

    return (
        <TopBarLayout
            title={oneStaff?.full_name}
            withGoBack
            postfix={
                <Popconfirm
                    title="Архивирование сотрудника"
                    description={
                        <>
                            <div>
                                Вы уверены, что хотите переместить сотрудника в
                                архив?
                            </div>
                            <div>
                                Примечание: Для перемещения в архив необходимо
                                снять все доступы
                            </div>
                        </>
                    }
                    okText="Переместить в архив"
                    cancelText="Отменить"
                    okButtonProps={{
                        disabled:
                            staffPermissions.length !== 0 ||
                            oneStaff?.staff_book_rents.length !== 0,
                    }}
                    onConfirm={onDeleteStaff}
                >
                    <Button danger loading={deleteStaffLoading}>
                        Архивировать
                    </Button>
                </Popconfirm>
            }
        >
            <StyledBox>
                {!getOneStaffLoading && oneStaff ? (
                    <>
                        <StaffBooksPanel items={oneStaff.staff_book_rents} />
                        <Form
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            onFinish={onUpdateStaff}
                            autoComplete="off"
                            layout="vertical"
                            style={{ width: '300px', margin: '0 auto' }}
                            initialValues={{
                                ...oneStaff,
                                group_id: oneStaff.staff_group.id,
                                phone_number:
                                    oneStaff.phone_number.split('+')[1],
                                team_id: oneStaff.staff_team?.id,
                            }}
                        >
                            <Field>
                                <ImgPicker
                                    src={getAvatarUrl(
                                        oneStaff?.photo_filename ?? null
                                    )}
                                    onFilePicked={(file) => setAvatar(file)}
                                    size="100px"
                                    fileTypes={['.png', '.jpg', '.jpeg']}
                                    round={true}
                                />
                                {oneStaff?.photo_filename
                                    ? 'User avatar'
                                    : avatar?.name
                                    ? avatar.name
                                    : 'Avatar is not loaded'}
                            </Field>
                            <Form.Item
                                label="Полное имя"
                                name="full_name"
                                key="full_name"
                            >
                                <Input
                                    style={{ width: '300px' }}
                                    placeholder="Elon Musk"
                                />
                            </Form.Item>

                            <Form.Item
                                label="Номер телефона"
                                name="phone_number"
                                style={{
                                    width: '100%',
                                }}
                                rules={[
                                    {
                                        message:
                                            'Please input valid phone number!',
                                        pattern: /^\d{12}$/,
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    style={{ width: '300px' }}
                                    placeholder="998991234567"
                                    prefix="+"
                                />
                            </Form.Item>

                            <Form.Item
                                label="Группа"
                                name="group_id"
                                key="group_id"
                                rules={[
                                    {
                                        message: 'Группа обязательное поле!',
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    loading={staffGroup.loading}
                                    placeholder="Маркетолог"
                                    style={{ width: '300px' }}
                                >
                                    {staffGroup.items.map((staffGroup) => (
                                        <Select.Option
                                            value={staffGroup.id}
                                            key={staffGroup.id}
                                        >
                                            {staffGroup.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Проект"
                                name="team_id"
                                key="team_id"
                                rules={[
                                    {
                                        message: 'Проект обязательное поле!',
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    loading={staffGroup.loading}
                                    placeholder="Отдел маркетинга"
                                    style={{ width: '300px' }}
                                >
                                    {team.items.map((team) => (
                                        <Select.Option
                                            value={team.id}
                                            key={team.id}
                                        >
                                            {team.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={updateStaffLoading}
                                >
                                    Сохранить
                                </Button>
                            </Form.Item>
                        </Form>
                        <StaffPermissionsPanel
                            staffPermissions={staffPermissions}
                            onChangeStaffPermissions={(staffPermissions) =>
                                setStaffPermissions(staffPermissions)
                            }
                        />
                    </>
                ) : (
                    <SpinWrapper>
                        <Spin />
                    </SpinWrapper>
                )}
            </StyledBox>
        </TopBarLayout>
    )
}

export default OneStaff
