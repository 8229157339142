import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Apis from '@apis'
import { BookStore } from './types'
import { RootState } from '../store'
import {
    GetBooksResponse,
    GetBooksResponseItem,
    UpdateBookResponse,
} from '@apis/book'

export const initialState: BookStore = {
    items: [],
    loading: false,
    error: null,
}

export const getBooks = createAsyncThunk<
    { items: GetBooksResponse },
    undefined,
    { state: RootState }
>('GET_BOOKS', async () => {
    const { data } = await Apis.book.getBooks()
    return { items: data }
})

const BookSlice = createSlice({
    name: 'book',
    initialState,
    reducers: {
        ADD_BOOK: (state, action: { payload: GetBooksResponseItem }) => {
            state.items.push(action.payload)
        },
        UPDATE_BOOK: (state, action: { payload: UpdateBookResponse }) => {},
        REMOVE_BOOK: (
            state,
            action: { payload: { bookId: number; categoryId: number } }
        ) => {
            const { categoryId, bookId } = action.payload

            const categoryIndex = state.items.findIndex(
                (category) => category.id === categoryId
            )
            const bookIndex = state.items[categoryIndex].books.findIndex(
                (book) => book.id === bookId
            )

            state.items[categoryIndex].books.splice(bookIndex, 1)
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getBooks.pending, (state, action) => {
            state.error = null
            state.loading = true
        })
        builder.addCase(getBooks.fulfilled, (state, action) => {
            const { items } = action.payload
            state.items = [...items]
            state.loading = false
        })
        builder.addCase(getBooks.rejected, (state, action) => {
            state.loading = false
            state.error = action.error
        })
    },
})

export const { ADD_BOOK, UPDATE_BOOK, REMOVE_BOOK } = BookSlice.actions

export default BookSlice.reducer
