import { useAppDispatch, useAppSelector } from '@/redux/hook'
import { useState } from 'react'
import {
    CreateStaffPermissionProps,
    GetStaffPermissionsProps,
    UpdateStaffPermissionProps,
} from '@apis/staffPermission'
import { useRestriction } from './useRestriction'
import {
    ADD_STAFF_PERMISSION,
    getStaffPermissions,
    REMOVE_STAFF_PERMISSION,
    UPDATE_STAFF_PERMISSION,
} from '@/redux/staffPermission'
import { SET_MESSAGE } from '@/redux/message'
import Apis from '@apis'

export const useStaffPermission = () => {
    const { loading } = useAppSelector((state) => state.visitReason)
    const dispatch = useAppDispatch()
    const { isAuth } = useRestriction()

    const [updateStaffPermissionLoading, setUpdateStaffPermissionLoading] =
        useState(false)
    const [deleteStaffPermissionLoading, setDeleteStaffPermissionLoading] =
        useState(false)
    const [createStaffPermissionLoading, setCreateStaffPermissionLoading] =
        useState(false)

    const fetchStaffPermission = async (
        params?: Partial<GetStaffPermissionsProps['params']>
    ) => {
        if (!isAuth) return

        try {
            await dispatch(getStaffPermissions(params ?? {}))
        } catch (e: any) {
            await dispatch(
                SET_MESSAGE({
                    text: `Не удалось загрузить список доступов. Ошибка: ${
                        e?.response?.data?.message ?? e.message
                    }`,
                    type: 'error',
                })
            )
        }
    }

    const updateStaffPermission = async (
        updatedStaffPermissionData: UpdateStaffPermissionProps,
        updatedStaffPermissionIndex: number
    ) => {
        if (!isAuth) return

        setUpdateStaffPermissionLoading(true)
        try {
            const { data } = await Apis.staffPermission.updateStaffPermission(
                updatedStaffPermissionData
            )
            dispatch(
                UPDATE_STAFF_PERMISSION({
                    updatedStaffPermissionData: data,
                    updatedStaffPermissionIndex,
                })
            )
            await dispatch(
                SET_MESSAGE({
                    text: 'Доступ сотрудника успешно обновлен',
                    type: 'success',
                })
            )
        } catch (e: any) {
            await dispatch(
                SET_MESSAGE({
                    text: `Не удалось обновить доступ сотрудника. Ошибка: ${
                        e?.response?.data?.message ?? e.message
                    }`,
                    type: 'error',
                })
            )
        }
        setUpdateStaffPermissionLoading(false)
    }

    const createStaffPermission = async (
        newStaffPermissionData: CreateStaffPermissionProps
    ) => {
        if (!isAuth) return

        setCreateStaffPermissionLoading(true)
        try {
            const { data } = await Apis.staffPermission.createStaffPermission(
                newStaffPermissionData
            )
            dispatch(ADD_STAFF_PERMISSION(data))
            await dispatch(
                SET_MESSAGE({
                    text: 'Новый доступ сотрдуника успешно создан',
                    type: 'success',
                })
            )
        } catch (e: any) {
            await dispatch(
                SET_MESSAGE({
                    text: `Не удалось создать новый доступ сотрудника. Ошибка: ${
                        e?.response?.data?.message ?? e.message
                    }`,
                    type: 'error',
                })
            )
        }
        setCreateStaffPermissionLoading(false)
    }

    const deleteStaffPermission = async (
        deletedStaffPermissionId: number,
        deletedStaffPermissionIndex: number
    ) => {
        if (!isAuth) return

        setDeleteStaffPermissionLoading(true)
        try {
            await Apis.staffPermission.deleteStaffPermission(
                deletedStaffPermissionId
            )
            dispatch(REMOVE_STAFF_PERMISSION(deletedStaffPermissionIndex))
            await dispatch(
                SET_MESSAGE({
                    text: 'Доступ сотрудника успешно удален',
                    type: 'success',
                })
            )
        } catch (e: any) {
            await dispatch(
                SET_MESSAGE({
                    text: `Не удалось удалить доступ сотрудника. Ошибка: ${
                        e?.response?.data?.message ?? e.message
                    }`,
                    type: 'error',
                })
            )
        }
        setDeleteStaffPermissionLoading(false)
    }

    return {
        getStaffPermissions: {
            getStaffPermissions: fetchStaffPermission,
            getStaffPermissionsLoading: loading,
        },
        updateStaffPermission: {
            updateStaffPermission,
            updateStaffPermissionLoading,
        },
        createStaffPermission: {
            createStaffPermission,
            createStaffPermissionLoading,
        },
        deleteStaffPermission: {
            deleteStaffPermission,
            deleteStaffPermissionLoading,
        },
    }
}
