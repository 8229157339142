import React from 'react'
import { Box } from '@components'
import { StaffPermissionsPanelProps } from './types'
import { useAppSelector } from '@/redux/hook'
import { Checkbox } from 'antd'
import styled from 'styled-components'

const StyledBox = styled(Box)`
  position: absolute;
  top: 163px;
  max-width: 250px;

  display: flex;
  flex-direction: column;
  gap: 30px;
  text-align: left;

  .checkbox-panel__list {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    max-height: 311px;
    overflow-y: auto;
  }
`

const PermissionsTitle = styled.h3`
  margin: 0;
  width: 100%;
`

export const StaffPermissionsPanel: React.FC<StaffPermissionsPanelProps> = (
    props
) => {
    const { onChangeStaffPermissions, staffPermissions } = props
    const { items } = useAppSelector((state) => state.staffPermission)

    return (
        <StyledBox className="checkbox-panel">
            <PermissionsTitle>Доступы</PermissionsTitle>
            <Checkbox.Group
                onChange={(checkedValues) =>
                    onChangeStaffPermissions(
                        checkedValues.map((value) => +value)
                    )
                }
                value={staffPermissions}
            >
                <Box className="checkbox-panel__list">
                    {items.map((permission) => (
                        <Checkbox key={permission.id} value={permission.id}>
                            {permission.name}
                        </Checkbox>
                    ))}
                </Box>
            </Checkbox.Group>
        </StyledBox>
    )
}
