import axios from 'axios'

import { store as storeInstance } from '../redux/store'

let store: typeof storeInstance

export const injectStore = (_store: typeof storeInstance) => {
    store = _store
}

axios.interceptors.request.use(
    // Do something before request is sent
    (config) => {
        config.baseURL = process.env.REACT_APP_API_URL
        config.withCredentials = true
        return config
    },
    (error) => {
        throw Error
    }
)

axios.interceptors.response.use(
    function (response) {
        // Do something with response data
        return response
    },
    function (error) {
        // Do something with response error
        if (
            error.response.status === 401 &&
            window.location.pathname !== '/auth'
        ) {
            window.location.href = '/'
        }
        throw error
    }
)

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    patch: axios.patch,
}
