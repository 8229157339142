import {
    CreateWallProps,
    GetWallProps,
    GetWallResponse,
    GetWallResponseItem,
    UpdateWallProps,
    UpdateWallImageProps,
    DeleteWallResponse,
} from './types'
import httpService from '@apis/httpService'

const wallApi = {
    getWall: (props: GetWallProps) => {
        return httpService.get<GetWallResponse>('/dashboard/wall', {
            params: props.params,
        })
    },

    getOneWall: (id: number) => {
        return httpService.get<GetWallResponseItem>(`/dashboard/wall/${id}`)
    },

    createWall: (props: CreateWallProps) => {
        return httpService.post<GetWallResponseItem>(
            '/dashboard/wall',
            props.body
        )
    },

    updateWall: (props: UpdateWallProps) => {
        return httpService.put<GetWallResponseItem>(
            `/dashboard/wall/${props.id}`,
            props.body
        )
    },

    updateWallPhoto: ({ id, body }: UpdateWallImageProps) => {
        return httpService.patch<null>(`/dashboard/wall/${id}`, body)
    },

    deleteWall: (id: number) => {
        return httpService.delete<DeleteWallResponse>(`/dashboard/wall/${id}`)
    },
}

export default wallApi
