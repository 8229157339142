import { useAppDispatch, useAppSelector } from '@/redux/hook'
import { CreateTeamProps, GetTeamsProps, UpdateTeamProps } from '@apis/team'
import { useRestriction } from '@hooks'
import { ADD_TEAM, getTeams, REMOVE_TEAM, UPDATE_TEAM } from '@/redux/team'
import { SET_MESSAGE } from '@/redux/message'
import Apis from '@apis'
import { useState } from 'react'

export const useTeam = () => {
    const { loading } = useAppSelector((state) => state.team)
    const dispatch = useAppDispatch()
    const { isAuth } = useRestriction()

    const [getOneTeamLoading, setGetOneTeamLoading] = useState(false)
    const [updateTeamLoading, setUpdateLoading] = useState(false)
    const [deleteTeamLoading, setDeleteTeamLoading] = useState(false)
    const [createTeamLoading, setCreateTeamLoading] = useState(false)

    const fetchTeams = async (params?: Partial<GetTeamsProps['params']>) => {
        if (!isAuth) return

        try {
            await dispatch(getTeams(params ?? {}))
        } catch (e) {
            await dispatch(
                SET_MESSAGE({
                    text: 'Teams not loaded',
                    type: 'error',
                })
            )
        }
    }

    const getOneTeams = async (id: number) => {
        if (!isAuth) return

        setGetOneTeamLoading(true)
        try {
            await Apis.staff.getOneStaff(id)
        } catch (e) {
            await dispatch(
                SET_MESSAGE({
                    text: 'Team not loaded',
                    type: 'error',
                })
            )
        }
        setGetOneTeamLoading(false)
    }

    const updateTeam = async (
        updatedTeamData: UpdateTeamProps,
        updatedTeamIndex: number
    ) => {
        if (!isAuth) return

        setUpdateLoading(true)
        try {
            const { data } = await Apis.team.updateTeam(updatedTeamData)
            dispatch(UPDATE_TEAM({ updatedTeamData: data, updatedTeamIndex }))
            await dispatch(
                SET_MESSAGE({
                    text: 'Team successfully updated',
                    type: 'success',
                })
            )
        } catch (e) {
            await dispatch(
                SET_MESSAGE({
                    text: 'Team not updated',
                    type: 'error',
                })
            )
        }
        setUpdateLoading(false)
    }

    const deleteTeam = async (
        deletedTeamId: number,
        deletedTeamIndex: number
    ) => {
        if (!isAuth) return

        setDeleteTeamLoading(true)
        try {
            await Apis.team.deleteTeam(deletedTeamId)
            dispatch(REMOVE_TEAM(deletedTeamIndex))
            await dispatch(
                SET_MESSAGE({
                    text: 'Team successfully deleted',
                    type: 'success',
                })
            )
        } catch (e) {
            await dispatch(
                SET_MESSAGE({
                    text: 'Team not deleted',
                    type: 'error',
                })
            )
        }
        setDeleteTeamLoading(false)
    }

    const createTeam = async (newTeamData: CreateTeamProps) => {
        if (!isAuth) return

        setCreateTeamLoading(true)
        try {
            const { data } = await Apis.team.createTeam(newTeamData)
            dispatch(ADD_TEAM(data))
            await dispatch(
                SET_MESSAGE({
                    text: 'Team successfully created',
                    type: 'success',
                })
            )
        } catch (e) {
            await dispatch(
                SET_MESSAGE({
                    text: 'Team not created',
                    type: 'error',
                })
            )
        }
        setCreateTeamLoading(false)
    }

    return {
        getTeams: {
            getTeams: fetchTeams,
            getTeamsLoading: loading,
        },
        getOneTeams: {
            getOneTeams,
            getOneTeamLoading,
        },
        updateTeam: {
            updateTeam,
            updateTeamLoading,
        },
        deleteTeam: {
            deleteTeam,
            deleteTeamLoading,
        },
        createTeam: {
            createTeam,
            createTeamLoading,
        },
    }
}
