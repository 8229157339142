import { createGlobalStyle } from 'styled-components'

export const Global = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: sans-serif;
  }

  html {
    height: 100%;
  }

  body {
    margin: 0;
    padding: 0;
    height: inherit;
  }
  
  #root {
    height: inherit;
  }
`
