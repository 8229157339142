import httpService from '../httpService'
import {
    CreateStaffPermissionProps,
    GetOneStaffPermissionResponse,
    GetStaffPermissionsProps,
    GetStaffPermissionsResponse,
    CreateStaffPermissionResponse,
    UpdateStaffPermissionProps,
    UpdateStaffPermissionResponse,
    DeleteStaffPermissionResponse,
} from './types'

export default {
    getStaffPermissions({ params }: GetStaffPermissionsProps) {
        return httpService.get<GetStaffPermissionsResponse>(
            '/dashboard/staff-permission',
            { params }
        )
    },
    createStaffPermission({ body }: CreateStaffPermissionProps) {
        return httpService.post<CreateStaffPermissionResponse>(
            '/dashboard/staff-permission',
            body
        )
    },
    getOneStaffPermission(id: number) {
        return httpService.get<GetOneStaffPermissionResponse>(
            `/dashboard/staff-permission/${id}`
        )
    },
    updateStaffPermission({ id, body }: UpdateStaffPermissionProps) {
        return httpService.patch<UpdateStaffPermissionResponse>(
            `/dashboard/staff-permission/${id}`,
            body
        )
    },
    deleteStaffPermission(id: number) {
        return httpService.delete<DeleteStaffPermissionResponse>(
            `/dashboard/staff-permission/${id}`
        )
    },
}
