import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@/redux/hook'
import type { TableProps } from 'antd'
import { Button, Popconfirm, Table } from 'antd'
import { useAdmin, useRestriction } from '@hooks'
import { GetAdminsResponseItem } from '@apis/admin'
import { getAdmins } from '@/redux/admin'
import { CreateAdminModal } from '@common'
import { TopBarLayout } from '@layouts'
import { getColumnData } from '@/utils'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'

const columns: TableProps<GetAdminsResponseItem>['columns'] = [
    {
        title: '№',
        dataIndex: 'index',
        render: (_: any, __: any, index: number) => index + 1,
        width: '5%',
        align: 'center',
        responsive: ['xs', 'sm'],
    },
    {
        title: 'Почта',
        dataIndex: 'email',
        render: getColumnData,
        align: 'center',
        width: '30%',
    },
    {
        title: 'Супер админ',
        dataIndex: 'is_super',
        render: (_, { is_super }) => `${is_super}`,
        align: 'center',
        width: '30%',
    },
]

const Admins: React.FC = () => {
    const dispatch = useAppDispatch()
    const { isAuth, isSuper } = useRestriction()
    const {
        items,
        loading,
        count,
        filters: { page, limit, search },
    } = useAppSelector((state) => state.admins)
    const { email } = useAppSelector((state) => state.user)

    const [visibility, setVisibility] = useState(false)
    const [searchValue, setSearchValue] = useState(search || '')
    const [mode, setMode] = useState<'create' | 'edit'>('create')
    const [values, setValues] = useState<{
        index?: number
        data?: GetAdminsResponseItem
    }>()

    const {
        deleteAdmin: { deleteAdmin },
    } = useAdmin()

    useEffect(() => {
        if (!count) {
            dispatch(getAdmins({}))
        }
    }, [isAuth])

    const onSearch = async () => {
        const search = searchValue.trim()
        if (search) {
            await dispatch(getAdmins({ page: 1, search }))
        } else {
            await dispatch(getAdmins({ page: 1, search: undefined }))
        }
    }

    const onEdit = async (
        values: GetAdminsResponseItem,
        editedAdminIndex: number
    ) => {
        setValues({
            data: values,
            index: editedAdminIndex,
        })
        setMode('edit')
        setVisibility(true)
    }

    const onDelete = async (id: number, deletedAdminIndex: number) => {
        await deleteAdmin(id, deletedAdminIndex)
    }

    const onCreate = () => {
        setValues(undefined)
        setMode('create')
        setVisibility(true)
    }

    const getColumns = () => {
        const result = [...columns]

        result.push({
            title: <AiOutlineEdit style={{ width: '100%' }} size={20} />,
            render: (_, values, index) =>
                (values.email === email || isSuper) && (
                    <Button onClick={() => onEdit(values, index)}>
                        Изменить
                    </Button>
                ),
            align: 'center',
        })

        if (isSuper) {
            result.push({
                title: <AiOutlineDelete style={{ width: '100%' }} size={20} />,
                render: (_, admin, index) => (
                    <Popconfirm
                        title="Delete admin"
                        description="Are you sure to delete this item?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => onDelete(admin.id, index)}
                    >
                        <Button danger>Удалить</Button>
                    </Popconfirm>
                ),
                align: 'center',
            })
        }

        return result
    }

    return (
        <TopBarLayout
            title="Админы"
            withSearchBox
            onSearch={onSearch}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            postfix={isSuper && <Button onClick={onCreate}>Новый админ</Button>}
        >
            <Table<GetAdminsResponseItem>
                style={{ cursor: 'pointer', height: 'inherit' }}
                dataSource={items}
                columns={getColumns()}
                rowKey="id"
                loading={loading}
                pagination={{
                    total: count,
                    pageSize: limit,
                    hideOnSinglePage: true,
                    onChange: (page) => dispatch(getAdmins({ page })),
                    current: page,
                }}
            />
            <CreateAdminModal
                values={values}
                changeVisibility={(open) => setVisibility(open)}
                visibility={visibility}
                mode={mode}
            />
        </TopBarLayout>
    )
}

export default Admins
