import { useAppDispatch, useAppSelector } from '@/redux/hook'
import { SET_MESSAGE } from '@/redux/message'
import { useRestriction } from './useRestriction'
import {
    CreateStaffGroupProps,
    GetOneStaffGroupResponse,
    GetStaffGroupsProps,
    UpdateStaffGroupProps,
} from '@apis/staffGroup'
import {
    ADD_STAFF_GROUP,
    getStaffGroups,
    REMOVE_STAFF_GROUP,
    UPDATE_STAFF_GROUP,
} from '@/redux/staffGroup'
import Apis from '@apis'
import { useState } from 'react'

export const useStaffGroup = () => {
    const { loading } = useAppSelector((state) => state.staffGroup)
    const dispatch = useAppDispatch()
    const { isAuth } = useRestriction()

    const [updateStaffGroupLoading, setUpdateStaffGroupLoading] =
        useState(false)
    const [createStaffGroupLoading, setCreateStaffGroupLoading] =
        useState(false)
    const [getOneStaffGroupLoading, setGetOneStaffGroupLoading] =
        useState(false)
    const [deleteStaffGroupLoading, setDeleteStaffGroupLoading] =
        useState(false)

    const [oneStaffGroup, setOneStaffGroup] = useState<
        GetOneStaffGroupResponse | undefined
    >()

    const fetchStaffGroups = async (
        params?: Partial<GetStaffGroupsProps['params']>
    ) => {
        if (!isAuth) return

        try {
            await dispatch(getStaffGroups(params ?? {}))
        } catch (e) {
            await dispatch(
                SET_MESSAGE({
                    text: 'Staff groups not loaded',
                    type: 'error',
                })
            )
        }
    }

    const getOneStaffGroup = async (staffGroupId: number) => {
        if (!isAuth) return
        setGetOneStaffGroupLoading(true)
        try {
            const { data } =
                await Apis.staffGroup.getOneStaffGroup(staffGroupId)
            setOneStaffGroup(data)
        } catch (e) {
            await dispatch(
                SET_MESSAGE({
                    text: 'User group not loaded',
                    type: 'error',
                })
            )
        }
        setGetOneStaffGroupLoading(false)
    }

    const updateStaffGroup = async (
        updatedStaffGroupData: UpdateStaffGroupProps,
        updatedStaffGroupIndex: number
    ) => {
        if (!isAuth) return

        setUpdateStaffGroupLoading(true)
        try {
            const { data } = await Apis.staffGroup.updateStaffGroup(
                updatedStaffGroupData
            )
            dispatch(
                UPDATE_STAFF_GROUP({
                    updatedStaffGroupData: data,
                    updatedStaffGroupIndex,
                })
            )
            await dispatch(
                SET_MESSAGE({
                    text: 'User group successfully updated',
                    type: 'success',
                })
            )
        } catch (e) {
            await dispatch(
                SET_MESSAGE({
                    text: 'User group not updated',
                    type: 'error',
                })
            )
        }
        setUpdateStaffGroupLoading(false)
    }

    const createStaffGroup = async (
        newStaffGroupData: CreateStaffGroupProps
    ) => {
        if (!isAuth) return

        setCreateStaffGroupLoading(true)
        try {
            const { data } =
                await Apis.staffGroup.createStaffGroup(newStaffGroupData)
            dispatch(ADD_STAFF_GROUP(data))
            await dispatch(
                SET_MESSAGE({
                    text: 'User group successfully created',
                    type: 'success',
                })
            )
        } catch (e) {
            await dispatch(
                SET_MESSAGE({
                    text: 'User group not created',
                    type: 'error',
                })
            )
        }
        setCreateStaffGroupLoading(false)
    }

    const deleteStaffGroup = async (
        deletedStaffGroupId: number,
        deletedStaffGroupIndex: number
    ) => {
        if (!isAuth) return

        setDeleteStaffGroupLoading(true)
        try {
            await Apis.staffGroup.deleteStaffGroup(deletedStaffGroupId)
            dispatch(REMOVE_STAFF_GROUP(deletedStaffGroupIndex))
            dispatch(
                SET_MESSAGE({
                    text: 'Группа сотрудника была успешно удалена',
                    type: 'success',
                })
            )
        } catch (e: any) {
            dispatch(
                SET_MESSAGE({
                    text: `Не удалось удалить группу сотрудника. Ошибка: ${
                        e?.response?.data?.message ?? e.message
                    }`,
                    type: 'error',
                })
            )
        }
        setDeleteStaffGroupLoading(false)
    }

    return {
        getStaffGroups: {
            getStaffGroups: fetchStaffGroups,
            getStaffGroupsLoading: loading,
        },
        updateStaffGroup: {
            updateStaffGroup,
            updateStaffGroupLoading,
        },
        createStaffGroup: {
            createStaffGroup,
            createStaffGroupLoading,
        },
        getOneStaffGroup: {
            getOneStaffGroup,
            getOneStaffGroupLoading,
            oneStaffGroup,
        },
        deleteStaffGroup: {
            deleteStaffGroup,
            deleteStaffGroupLoading,
        },
    }
}
