import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Apis from '@apis'
import { StaffGroupStore } from './types'
import { RootState } from '../store'
import {
    GetStaffGroupsProps,
    GetStaffGroupsResponse,
    GetStaffGroupsResponseItem,
} from '@apis/staffGroup'

export const initialState: StaffGroupStore = {
    items: [],
    filters: {
        page: 1,
        limit: 50,
        search: undefined,
    },
    count: 0,
    loading: false,
    error: null,
}

export const getStaffGroups = createAsyncThunk<
    { data: GetStaffGroupsResponse; filters: GetStaffGroupsProps['params'] },
    Partial<GetStaffGroupsProps['params']>,
    { state: RootState }
>('GET_STAFF_GROUPS', async (payload, thunkAPI) => {
    const { staffGroup } = thunkAPI.getState()
    const filters = { ...staffGroup.filters, ...payload }
    const { data } = await Apis.staffGroup.getStaffGroups({ params: filters })
    return { data, filters }
})

const StaffGroupSlice = createSlice({
    name: 'staffGroup',
    initialState,
    reducers: {
        ADD_STAFF_GROUP: (
            state,
            action: { payload: GetStaffGroupsResponseItem }
        ) => {
            state.items.push(action.payload)
            state.count = state.items.length
        },
        UPDATE_STAFF_GROUP: (
            state,
            action: {
                payload: {
                    updatedStaffGroupData: GetStaffGroupsResponseItem
                    updatedStaffGroupIndex: number
                }
            }
        ) => {
            state.items.splice(
                action.payload.updatedStaffGroupIndex,
                1,
                action.payload.updatedStaffGroupData
            )
        },
        REMOVE_STAFF_GROUP: (state, action: { payload: number }) => {
            state.items.splice(action.payload, 1)
            state.count = state.items.length
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getStaffGroups.pending, (state, action) => {
            state.error = null
            state.loading = true
        })
        builder.addCase(getStaffGroups.fulfilled, (state, action) => {
            const {
                filters,
                data: { items, count },
            } = action.payload
            state.filters = filters
            state.items = [...items]
            state.count = count
            state.loading = false
        })
        builder.addCase(getStaffGroups.rejected, (state, action) => {
            state.loading = false
            state.error = action.error
        })
    },
})

export const { ADD_STAFF_GROUP, UPDATE_STAFF_GROUP, REMOVE_STAFF_GROUP } =
    StaffGroupSlice.actions

export default StaffGroupSlice.reducer
