import admin from './admin/admin'
import guest from './guest/guest'
import staff from './staff/staff'
import auth from './auth/auth'
import staffGroup from './staffGroup/staffGroup'
import visitReason from './visitReason/visitReason'
import qrCode from './qrCode/qrCode'
import team from './team/team'
import staffDinner from './staffDinner/staffDinner'
import staffPermission from './staffPermission/staffPermission'
import regulation from './regulations/regulation'
import readme from './readme/readme'
import bookCategory from './bookCategory/bookCategory'
import book from './book/book'
import requests from './requests/requests'
import wall from './wall/wall'

export default {
    admin,
    guest,
    staff,
    auth,
    staffGroup,
    visitReason,
    qrCode,
    team,
    staffDinner,
    staffPermission,
    regulation,
    readme,
    bookCategory,
    book,
    requests,
    wall,
}
