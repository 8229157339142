import { useAppDispatch, useAppSelector } from '@/redux/hook';
import { useState } from 'react';
import { getArchivedRequests, getRequests, REMOVE_REQUEST, UPDATE_REQUEST, UNARCHIVE_REQUEST } from '@/redux/requests';
import {
    GetOneRequestResponse,
    GetRequestsProps,
    UpdateRequestProps,
} from '@apis/requests';
import { SET_MESSAGE } from '@/redux/message';
import Apis from '@apis';
import { useRestriction } from './useRestriction';
import { useNavigate } from 'react-router-dom';

export const useRequests = () => {
    const { loading } = useAppSelector((state) => state.requests);
    const dispatch = useAppDispatch();
    const { isAuth } = useRestriction();
    const nav = useNavigate();

    const [deleteRequestLoading, setDeleteRequestLoading] = useState(false);
    const [getOneRequestLoading, setGetOneRequestLoading] = useState(false);
    const [updateRequestLoading, setUpdateRequestLoading] = useState(false);
    const [unarchiveRequestLoading, setUnarchiveRequestLoading] = useState(false);

    
    const [oneRequest, setOneRequest] = useState<GetOneRequestResponse | undefined>();

    const fetchRequests = async (
        params?: Partial<GetRequestsProps['params']>
    ) => {
        if (!isAuth) return;

        // const updatedParams = {
        //     ...params,
        //     page: params?.page ? Number(params.page) : 1,
        //     limit: params?.limit ? Number(params.limit) : 20,
        // };

        try {
            await dispatch(getRequests(params ?? {}));
        } catch (e) {
            dispatch(
                SET_MESSAGE({
                    text: 'Requests not loaded',
                    type: 'error',
                })
            );
        }
    };

    const fetchArchivedRequests = async (
        params?: Partial<GetRequestsProps['params']>
    ) => {
        if (!isAuth) return;

        const updatedParams = {
            ...params,
            page: params?.page ? Number(params.page) : 1,
            limit: params?.limit ? Number(params.limit) : 20,
        };

        try {
            await dispatch(getArchivedRequests(updatedParams));
        } catch (e) {
            dispatch(
                SET_MESSAGE({
                    text: 'Requests not loaded',
                    type: 'error',
                })
            );
        }
    };

    const unarchiveRequest = async (id: number) => {
        if (!isAuth) return;

        setUnarchiveRequestLoading(true);
        try {
            await Apis.requests.unarchive(id);
            dispatch(UNARCHIVE_REQUEST(id));
            dispatch(
                SET_MESSAGE({
                    text: 'Request successfully unarchived',
                    type: 'success',
                })
            );
        } catch (e) {
            dispatch(
                SET_MESSAGE({
                    text: 'Request not unarchived',
                    type: 'error',
                })
            );
        }
        setUnarchiveRequestLoading(false);
    }

    const getOneRequest = async (requestId: number) => {
        if (!isAuth) return;

        setGetOneRequestLoading(true);
        try {
            const { data } = await Apis.requests.getOneRequest(requestId);
            setOneRequest(data);
        } catch (e) {
            dispatch(
                SET_MESSAGE({
                    text: 'Request not loaded',
                    type: 'error',
                })
            );
        }
        setGetOneRequestLoading(false);
    };

    const deleteRequest = async (deletedRequestId: number) => {
        if (!isAuth) return;

        setDeleteRequestLoading(true);
        try {
            await Apis.requests.deleteRequest(deletedRequestId);
            dispatch(REMOVE_REQUEST(deletedRequestId));
            dispatch(
                SET_MESSAGE({
                    text: 'Request successfully deleted',
                    type: 'success',
                })
            );
            nav(-1);
        } catch (e) {
            dispatch(
                SET_MESSAGE({
                    text: 'Request not deleted',
                    type: 'error',
                })
            );
        }
        setDeleteRequestLoading(false);
    };

    const updateRequest = async (
        updatedRequestData: UpdateRequestProps['body'] & {
            avatar?: File;
            id: number;
        }
    ) => {
        if (!isAuth) return;

        const { avatar, id, ...otherValues } = updatedRequestData;
        setUpdateRequestLoading(true);
        try {
            const { data } = await Apis.requests.updateRequest({
                body: otherValues,
                id,
            });
            dispatch(UPDATE_REQUEST({ updatedRequestData: data }));
            dispatch(
                SET_MESSAGE({
                    text: 'Request successfully updated',
                    type: 'success',
                })
            );
            // nav('/', {
            //     replace: true,
            // });
        } catch (e) {
            dispatch(
                SET_MESSAGE({
                    text: 'Request not updated',
                    type: 'error',
                })
            );
        }

        if (avatar) {
            try {
                const formData = new FormData();
                formData.append('file', avatar);
                const { data } = await Apis.requests.updateRequestAvatar({
                    body: formData,
                    id,
                });
                dispatch(UPDATE_REQUEST({ updatedRequestData: data }));
            } catch (e) {
                dispatch(
                    SET_MESSAGE({
                        text: 'Request avatar not updated',
                        type: 'error',
                    })
                );
            }
        }
        setUpdateRequestLoading(false);
    };

    return {
        getRequests: {
            getRequests: fetchRequests,
            getRequestsLoading: loading,
        },
        getArchivedRequests: {
            getArchivedRequests: fetchArchivedRequests,
            getArchivedRequestsLoading: loading,
        },
        deleteRequest: {
            deleteRequest,
            deleteRequestLoading,
        },
        getOneRequest: {
            getOneRequest,
            getOneRequestLoading,
            oneRequest,
        },
        updateRequest: {
            updateRequest,
            updateRequestLoading,
        },
        unarchiveRequest: {
            unarchiveRequestLoading,
            unarchiveRequest,
        },
    };
};
