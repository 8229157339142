import React from 'react'
import { NameModal, NameModalProps } from '@common/nameModal'
import { useBookCategory } from '@hooks'
import { CreateAndEditBookCategoryProps, EditBookCategoryProps } from './types'


export const CreateAndEditBookCategory: React.FC<CreateAndEditBookCategoryProps> = (props) => {
    const {
        mode,
        ...otherProps
    } = props
    const {
        createBookCategory: {
            createBookCategory,
            createBookCategoryLoading,
        },
        updateBookCategory: {
            updateBookCategory,
            updateBookCategoryLoading,
        },
    } = useBookCategory()

    const isEdit = (props: CreateAndEditBookCategoryProps): props is EditBookCategoryProps => props.mode === 'edit'

    const onFinish: NameModalProps['onFinish'] = (values) => {
        if (isEdit(props)) {
            updateBookCategory({ id: props.editedBookCategoryId, body: values }, props.editedBookCategoryIndex)
            return
        }

        createBookCategory({ body: values })
    }

    return (
        <>
            <NameModal
                title={isEdit(props) ? 'Изменение категории' : 'Новая категория'}
                onFinish={onFinish}
                defaultValue={isEdit(props) ? props.editedBookCategoryName : undefined}
                loading={isEdit(props) ? updateBookCategoryLoading : createBookCategoryLoading}
                {...otherProps}
            />
        </>
    )
}
