import httpService from '../httpService'
import {
    GetReadmeResponse,
    UpdateReadmeProps,
    UpdateReadmeResponse,
} from './types'

export default {
    getReadme() {
        return httpService.get<GetReadmeResponse>('/dashboard/readme')
    },
    updateReadme(body: UpdateReadmeProps) {
        return httpService.put<UpdateReadmeResponse>('/dashboard/readme', body)
    },
}
