import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Apis from '@apis'
import { GuestStore } from './types'
import { RootState } from '../store'
import {
    GetGuestResponseItem,
    GetGuestsProps,
    GetGuestsResponse,
} from '@apis/guest'

export const initialState: GuestStore = {
    items: [],
    filters: {
        page: 1,
        limit: 20,
        search: undefined,
    },
    count: 0,
    loading: false,
    error: null,
}

export const getGuests = createAsyncThunk<
    { data: GetGuestsResponse; filters: GetGuestsProps['params'] },
    Partial<GetGuestsProps['params']>,
    { state: RootState }
>('GET_GUESTS', async (payload, thunkAPI) => {
    const { guest } = thunkAPI.getState()
    const filters = { ...guest.filters, ...payload }
    const { data } = await Apis.guest.getGuests({ params: filters })
    return { data, filters }
})

const GuestSlice = createSlice({
    name: 'guest',
    initialState,
    reducers: {
        UPDATE_GUEST: (
            state,
            action: {
                payload: {
                    updatedGuestData: GetGuestResponseItem
                    updatedGuestIndex: number
                }
            }
        ) => {
            state.items.splice(
                action.payload.updatedGuestIndex,
                1,
                action.payload.updatedGuestData
            )
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getGuests.pending, (state, action) => {
            state.error = null
            state.loading = true
        })
        builder.addCase(getGuests.fulfilled, (state, action) => {
            const {
                filters,
                data: { items, count },
            } = action.payload
            state.filters = filters
            if (filters.page !== 1) {
                state.items = [...state.items, ...items]
            } else {
                state.items = [...items]
            }
            state.count = count
            state.loading = false
        })
        builder.addCase(getGuests.rejected, (state, action) => {
            state.loading = false
            state.error = action.error
        })
    },
})

export const { UPDATE_GUEST } = GuestSlice.actions
export default GuestSlice.reducer
