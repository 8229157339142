import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, Table, Select, Spin, Input } from 'antd'
import { useBook } from '@hooks'
import { TopBarLayout } from '@layouts'
import { useAppSelector } from '@/redux/hook'
import { getBookImgUrl } from '@/utils'
import { GetBooksResponseItem, GetBooksResponseItemBook } from '@apis/book'
import { Link, useNavigate } from 'react-router-dom'
import { ColumnsType } from 'antd/es/table'

const StatusText = styled.span<{ status: string }>`
    color: ${({ status }) => (status === 'available' ? '#50BB74' : 'grey')};
`

const BookImg = styled.img`
    width: 90px;
    height: 120px;

    @media (max-width: 768px) {
        width: 60px;
        height: 80px;
    }
`

const StyledBox = styled.div`
    .book-row {
        cursor: pointer;
    }

    .search-bar {
        width: 100%;
    }

    .filter-bar {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: stretch;
        }
    }

    .filter-bar > *:not(:last-child) {
        margin-right: 8px;

        @media (max-width: 768px) {
            margin-right: 0;
            margin-bottom: 8px;
        }
    }

    .filter-bar > span {
        margin-right: 16px;

        @media (max-width: 768px) {
            margin-right: 0;
        }
    }

    .ant-table {
        @media (max-width: 768px) {
            .ant-table-thead > tr > th {
                font-size: 12px;
            }

            .ant-table-tbody > tr > td {
                font-size: 12px;
            }
        }
    }
`

interface BookData {
    id: number
    photo: string | null
    name: string
    author: string
    year: number
    status: string
    renter?: string | null
}

const bookColumns: ColumnsType<BookData> = [
    {
        title: '№',
        dataIndex: 'index',
        render: (_: any, __: BookData, index: number) => index + 1,
        width: '2%',
        align: 'center',
    },
    {
        title: 'Обложка',
        dataIndex: 'photo',
        render: (photo: string | null) => (
            <BookImg src={photo ? getBookImgUrl(photo) : ''} alt="book image" />
        ),
        width: '10%',
        align: 'center',
    },
    {
        title: 'Название',
        dataIndex: 'name',
        width: '40%',
        render: (text: string) => <strong>{text}</strong>,
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        render: (status: string, record: BookData) => (
            <StatusText status={status}>
                {status === 'available'
                    ? 'Свободна'
                    : record.renter || 'Неизвестно'}
            </StatusText>
        ),
        align: 'center',
    },
    {
        title: 'Автор',
        dataIndex: 'author',
        width: '15%',
        align: 'center',
    },
    {
        title: 'Год',
        dataIndex: 'year',
        width: '5%',
    },
]

const Books: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [selectedCategory, setSelectedCategory] = useState<
        string | undefined
    >(undefined)
    const [filteredBooks, setFilteredBooks] = useState<BookData[]>([])

    const nav = useNavigate()
    const { items, loading } = useAppSelector((state) => state.book)
    const { getBooks } = useBook()

    useEffect(() => {
        if (!items.length) {
            getBooks.getBooks()
        }
    }, [getBooks, items.length])

    useEffect(() => {
        const searchLower = searchTerm.toLowerCase()
        let books: BookData[] = []

        if (selectedCategory && selectedCategory !== 'all') {
            const category = items.find(
                (item) => item.name === selectedCategory
            )
            if (category) {
                books = category.books.map((book) => ({
                    id: book.id,
                    photo: book.photo,
                    name: book.name,
                    author: book.author,
                    year: book.year,
                    status: book.status,
                    renter: book.renter ? book.renter.full_name : null,
                }))
            }
        } else {
            books = items.flatMap((item) =>
                item.books.map((book) => ({
                    id: book.id,
                    photo: book.photo,
                    name: book.name,
                    author: book.author,
                    year: book.year,
                    status: book.status,
                    renter: book.renter ? book.renter.full_name : null,
                }))
            )
        }

        books = books.filter((book) =>
            book.name.toLowerCase().includes(searchLower)
        )

        setFilteredBooks(books)
    }, [searchTerm, selectedCategory, items])

    const categoryOptions = [
        { label: 'Все книги', value: 'all' },
        ...items.map((item) => ({
            label: `${item.name} (${item.books.length})`,
            value: item.name,
        })),
    ]

    return (
        <TopBarLayout
            title="Книги"
            postfix={
                <Link to="new">
                    <Button>Новая книга</Button>
                </Link>
            }
        >
            <StyledBox>
                <div className="filter-bar">
                    <span>{filteredBooks.length}</span>
                    <Input
                        className="search-bar"
                        placeholder="Поиск по названию"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <Select
                        placeholder="Выберите категорию"
                        style={{ width: 300 }}
                        options={categoryOptions}
                        onChange={setSelectedCategory}
                        value={selectedCategory}
                    />
                </div>
                {loading ? (
                    <Spin />
                ) : (
                    <Table<BookData>
                        rowKey="id"
                        columns={bookColumns}
                        dataSource={filteredBooks}
                        pagination={false}
                        onRow={(item) => ({
                            onClick: () => nav(`/books/${item.id}`),
                        })}
                    />
                )}
            </StyledBox>
        </TopBarLayout>
    )
}

export default Books
