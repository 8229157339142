import { useAppDispatch, useAppSelector } from '@/redux/hook'
import { useState } from 'react'
import {
    CreateVisitReasonProps,
    GetVisitReasonsProps,
    UpdateVisitReasonProps,
} from '@apis/visitReason'
import { useRestriction } from './useRestriction'
import {
    ADD_VISIT_REASON,
    getVisitReasons,
    REMOVE_VISIT_REASON,
    UPDATE_VISIT_REASON,
} from '@/redux/visitReason'
import { SET_MESSAGE } from '@/redux/message'
import Apis from '@apis'

export const useVisitReason = () => {
    const { loading } = useAppSelector((state) => state.visitReason)
    const dispatch = useAppDispatch()
    const { isAuth } = useRestriction()

    const [updateVisitReasonLoading, setUpdateVisitReasonLoading] =
        useState(false)
    const [deleteVisitReasonLoading, setDeleteVisitReasonLoading] =
        useState(false)
    const [createVisitReasonLoading, setCreateVisitReasonLoading] =
        useState(false)

    const fetchVisitReason = async (
        params?: Partial<GetVisitReasonsProps['params']>
    ) => {
        if (!isAuth) return

        try {
            await dispatch(getVisitReasons(params ?? {}))
        } catch (e) {
            await dispatch(
                SET_MESSAGE({
                    text: 'Visit reasons not loaded',
                    type: 'error',
                })
            )
        }
    }

    const updateVisitReason = async (
        updatedVisitReasonData: UpdateVisitReasonProps,
        updatedVisitReasonIndex: number
    ) => {
        if (!isAuth) return

        setUpdateVisitReasonLoading(true)
        try {
            const { data } = await Apis.visitReason.updateVisitReason(
                updatedVisitReasonData
            )
            dispatch(
                UPDATE_VISIT_REASON({
                    updatedVisitReasonData: data,
                    updatedVisitReasonIndex,
                })
            )
            await dispatch(
                SET_MESSAGE({
                    text: 'Visit reason successfully updated',
                    type: 'success',
                })
            )
        } catch (e) {
            await dispatch(
                SET_MESSAGE({
                    text: 'Visit reason not updated',
                    type: 'error',
                })
            )
        }
        setUpdateVisitReasonLoading(false)
    }

    const createVisitReason = async (
        newVisitReasonData: CreateVisitReasonProps
    ) => {
        if (!isAuth) return

        setCreateVisitReasonLoading(true)
        try {
            const { data } =
                await Apis.visitReason.createVisitReason(newVisitReasonData)
            dispatch(ADD_VISIT_REASON(data))
            await dispatch(
                SET_MESSAGE({
                    text: 'Visit reason successfully created',
                    type: 'success',
                })
            )
        } catch (e) {
            await dispatch(
                SET_MESSAGE({
                    text: 'Visit reason not created',
                    type: 'error',
                })
            )
        }
        setCreateVisitReasonLoading(false)
    }

    const deleteVisitReason = async (
        deletedVisitReasonId: number,
        deletedVisitReasonIndex: number
    ) => {
        if (!isAuth) return

        setDeleteVisitReasonLoading(true)
        try {
            await Apis.visitReason.deleteVisitReason(deletedVisitReasonId)
            dispatch(REMOVE_VISIT_REASON(deletedVisitReasonIndex))
            await dispatch(
                SET_MESSAGE({
                    text: 'Visit reason successfully deleted',
                    type: 'success',
                })
            )
        } catch (e) {
            await dispatch(
                SET_MESSAGE({
                    text: 'Visit reason not deleted',
                    type: 'error',
                })
            )
        }
        setDeleteVisitReasonLoading(false)
    }

    return {
        getVisitReasons: {
            getVisitReasons: fetchVisitReason,
            getVisitReasonsLoading: loading,
        },
        updateVisitReason: {
            updateVisitReason,
            updateVisitReasonLoading,
        },
        createVisitReason: {
            createVisitReason,
            createVisitReasonLoading,
        },
        deleteVisitReason: {
            deleteVisitReason,
            deleteVisitReasonLoading,
        },
    }
}
