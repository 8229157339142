import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Apis from '../../apis'
import { StaffDinnerStore } from './types'
import { RootState } from '../store'
import {
    GetStaffDinnersProps,
    GetStaffDinnersResponse,
    GetStaffDinnersResponseItem,
} from '@apis/staffDinner/types'

export const initialState: StaffDinnerStore = {
    items: [],
    filters: {
        page: 1,
        limit: 20,
    },
    count: 0,
    loading: false,
    error: null,
}

export const getStaffDinners = createAsyncThunk<
    { data: GetStaffDinnersResponse; filters: GetStaffDinnersProps['params'] },
    Partial<GetStaffDinnersProps['params']>,
    { state: RootState }
>('GET_STAFF_DINNERS', async (payload, thunkAPI) => {
    const { staffDinner } = thunkAPI.getState()
    const filters = { ...staffDinner.filters, ...payload }
    const { data } = await Apis.staffDinner.getStaffDinners({ params: filters })
    return { data, filters }
})

const StaffDinnerSlice = createSlice({
    name: 'staff',
    initialState,
    reducers: {
        ADD_STAFF_DINNER: (
            state,
            action: { payload: GetStaffDinnersResponseItem }
        ) => {
            state.items.unshift(action.payload)
            state.count = state.items.length
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getStaffDinners.pending, (state, action) => {
            state.error = null
            state.loading = true
        })
        builder.addCase(getStaffDinners.fulfilled, (state, action) => {
            const {
                filters,
                data: { items, count },
            } = action.payload
            state.filters = filters
            state.items = [...items]
            state.count = count
            state.loading = false
        })
        builder.addCase(getStaffDinners.rejected, (state, action) => {
            state.loading = false
            state.error = action.error
        })
    },
})

export const { ADD_STAFF_DINNER } = StaffDinnerSlice.actions

export default StaffDinnerSlice.reducer
