
import { useAppDispatch, useAppSelector } from '@/redux/hook';
import { CreateWallProps, GetWallResponseItem, UpdateWallProps } from "@/apis/wall"
import { useRestriction } from './useRestriction';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { getWall } from '@/redux/wall';
import { GetRequestsProps } from '@/apis/requests';
import { SET_MESSAGE } from '@/redux/message';
import Apis from '@apis';


export const useWall = () => {
    const loading = useAppSelector((state) => state.wall);
    const dispatch = useAppDispatch();
    const { isAuth } = useRestriction();
    const nav = useNavigate();

    const [createWallLoading, setCreateWallLoading] = useState(false);
    const [deleteWallLoading, setDeleteWallLoading] = useState(false);
    const [getOneWallLoading, setGetOneWallLoading] = useState(false);
    const [updateWallLoading, setUpdateWallLoading] = useState(false);

    const [oneWall, setOneWall] = useState<GetWallResponseItem | undefined>();

    const fetchWall = async (
        params?: Partial<GetRequestsProps['params']>
    ) => {
        if (!isAuth) return;

        try {
            await dispatch(getWall(params ?? {}));
        } catch (e) {
            dispatch(
                SET_MESSAGE({
                    text: 'Не удалось загрузить посты',
                    type: 'error',
                })
            );
        }
    };

    const getOneWall = async (id: number) => {
        if (!isAuth) return;

        try {
            setGetOneWallLoading(true);
            const { data } = await Apis.wall.getOneWall(id);
            setOneWall(data);
        } catch (e) {
            dispatch(
                SET_MESSAGE({
                    text: 'Не удалось загрузить книгу пост',
                    type: 'error',
                })
            );
        } finally {
            setGetOneWallLoading(false);
        }
    }

    const deleteWall = async (id: number) => {
        if (!isAuth) return;

        try {
            setDeleteWallLoading(true);
            await Apis.wall.deleteWall(id);
            fetchWall();
        } catch (e) {
            dispatch(
                SET_MESSAGE({
                    text: 'Wall not deleted',
                    type: 'error',
                })
            );
        } finally {
            setDeleteWallLoading(false);
        }
    }

    const createWall = async (data: CreateWallProps) => {
        if (!isAuth) return;

        try {
            setCreateWallLoading(true);
            await Apis.wall.createWall(data);
            nav('/wall');
        } catch (e) {
            dispatch(
                SET_MESSAGE({
                    text: 'Wall not created',
                    type: 'error',
                })
            );
        } finally {
            setCreateWallLoading(false);
        }
    }

    const updateWall = async (id: number, data: UpdateWallProps) => {
        if (!isAuth) return;

        try {
            setUpdateWallLoading(true);
            await Apis.wall.updateWall(data);
            fetchWall();
        } catch (e) {
            dispatch(
                SET_MESSAGE({
                    text: 'Wall not updated',
                    type: 'error',
                })
            );
        } finally {
            setUpdateWallLoading(false);
        }
    }

    return {
        getWall: {
            getWall: fetchWall,
            getWallLoading: loading,
        },
        getOneWall: {
            getOneWall,
            getOneWallLoading,
            oneWall,
        },
        createWall: {
            createWall,
            createWallLoading,
        },
        updateWall: {
            updateWall,
            updateWallLoading,
        },
        deleteWall: {
            deleteWall,
            deleteWallLoading,
        },
        
    }
    
}