import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { Provider } from 'react-redux'
import { store } from './redux/store'
import { BrowserRouter } from 'react-router-dom'
import { Global } from './styles/global'
import { injectStore } from '@apis/httpService'
import { ConfigProvider } from 'antd'
import 'dayjs/locale/ru'
import locale from 'antd/locale/ru_RU'
import dayjs from 'dayjs'
dayjs.locale('ru')

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

injectStore(store)

root.render(
    <React.StrictMode>
        <ConfigProvider locale={locale}>
            <Provider store={store}>
                <BrowserRouter>
                    <Global />
                    <App />
                </BrowserRouter>
            </Provider>
        </ConfigProvider>
    </React.StrictMode>
)
