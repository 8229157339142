import httpService from '../httpService'
import {
    CreateTeamProps,
    CreateTeamResponse,
    GetOneTeamResponse,
    GetTeamsProps,
    GetTeamsResponse,
    UpdateTeamProps,
    UpdateTeamResponse,
} from './types'

export default {
    getTeams({ params }: GetTeamsProps) {
        return httpService.get<GetTeamsResponse>('/dashboard/staff-teams', {
            params,
        })
    },
    getOneTeam(id: number) {
        return httpService.get<GetOneTeamResponse>(
            `/dashboard/staff-teams/${id}`
        )
    },
    updateTeam({ body, id }: UpdateTeamProps) {
        return httpService.patch<UpdateTeamResponse>(
            `/dashboard/staff-teams/${id}`,
            body
        )
    },
    createTeam({ body }: CreateTeamProps) {
        return httpService.post<CreateTeamResponse>(
            '/dashboard/staff-teams',
            body
        )
    },
    deleteTeam(id: number) {
        return httpService.delete<null>(`/dashboard/staff-teams/${id}`)
    },
}
