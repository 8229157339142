import React from 'react'
import { useStaffGroup } from '@hooks'
import { Button, Form, Input, Modal } from 'antd'

type CreateStaffGroupModalProps = {
    open: boolean
    setOpen: (open: boolean) => void
}

export const CreateStaffGroupModal: React.FC<CreateStaffGroupModalProps> = (
    props
) => {
    const { open, setOpen } = props
    const {
        createStaffGroup: { createStaffGroup, createStaffGroupLoading },
    } = useStaffGroup()

    const onCreate = async ({ name }: { name: string }) => {
        await createStaffGroup({ body: { name } })
        setOpen(false)
    }

    return (
        <Modal
            open={open}
            onCancel={() => setOpen(false)}
            footer={null}
            width="350px"
            destroyOnClose
            title="Новая группа"
        >
            <Form onFinish={onCreate} layout="vertical">
                <Form.Item
                    label="Название"
                    rules={[
                        { required: true, message: 'Это обязательное поле' },
                    ]}
                    name="name"
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button loading={createStaffGroupLoading} htmlType="submit">
                        Создать
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}
