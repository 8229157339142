import React from 'react'
import { useAppDispatch } from '@/redux/hook'
import styled from 'styled-components'
import { Box } from '@components'
import { Button } from 'antd'
import { RegulationsListItem } from './regulationsListItem'
import { OneLocalRegulation } from '@apis/regulations'
import { AiOutlinePlus } from 'react-icons/ai'
import { ADD_LOCAL_REGULATION } from '@/redux/regulation'

type OneRegulationProps = {
    groupId: number
    indexes: number[]
    regulations: OneLocalRegulation[]
    parent_id: number | null
}

const RegulationsListStyled = styled(Box)`
    flex-direction: column;
    align-items: stretch;
    margin: 0;
    gap: 20px;
    width: 100%;
    padding: 0 40px;
`

export const RegulationsList: React.FC<OneRegulationProps> = (props) => {
    const { groupId, indexes, regulations, parent_id } = props
    const dispatch = useAppDispatch()

    const onCreate = () => {
        dispatch(
            ADD_LOCAL_REGULATION({ indexes, parent_id, group_id: groupId })
        )
    }

    return (
        <RegulationsListStyled as="ul">
            {regulations.map((regulation, index) => (
                <RegulationsListItem
                    indexes={[...indexes, index]}
                    regulation={regulation}
                    groupId={groupId}
                    key={regulation.id}
                />
            ))}
            <Button
                onClick={onCreate}
                style={{ margin: '0 auto' }}
                type="primary"
                icon={<AiOutlinePlus size="24px" />}
            />
        </RegulationsListStyled>
    )
}
