import httpService from '../httpService'
import {
    CloseSessionOfGuestProps,
    GetGuestsProps,
    GetGuestsResponse,
    GetOneGuestResponse,
} from './types'

export default {
    getGuests({ params }: GetGuestsProps) {
        return httpService.get<GetGuestsResponse>('/dashboard/guests', {
            params,
        })
    },
    getOneGuest(id: number) {
        return httpService.get<GetOneGuestResponse>(`/dashboard/guests/${id}`)
    },
    closeSessionOfGuest({ id, body }: CloseSessionOfGuestProps) {
        return httpService.patch(`/dashboard/guests/${id}`, body)
    },
}
