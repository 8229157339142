import React, { useEffect, useState } from 'react'
import { useAppSelector } from '@/redux/hook'
import { Button, Popconfirm, Table } from 'antd'
import type { TableProps } from 'antd'
import { useStaffPermission } from '@hooks'
import { GetStaffPermissionsResponseItem } from '@apis/staffPermission'
import { getColumnData } from '@/utils'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import { TopBarLayout } from '@layouts'
import {
    CreateStaffPermissionModal,
    EditStaffPermissionModal,
} from '@screens/common/settings'

const columns: TableProps<GetStaffPermissionsResponseItem>['columns'] = [
    {
        title: '№',
        dataIndex: 'index',
        render: (_: any, __: any, index: number) => index + 1,
        width: '5%',
        align: 'center',
        responsive: ['xs', 'sm'],
    },
    {
        title: 'Название',
        dataIndex: 'name',
        render: getColumnData,
    },
]

const StaffPermissions: React.FC = () => {
    const {
        items,
        count,
        loading,
        filters: { page, limit, search },
    } = useAppSelector((state) => state.staffPermission)
    const {
        getStaffPermissions: { getStaffPermissions },
        deleteStaffPermission: { deleteStaffPermission },
    } = useStaffPermission()

    const [searchValue, setSearchValue] = useState(search || '')
    const [values, setValues] = useState<GetStaffPermissionsResponseItem>()
    const [open, setOpen] = useState(false)
    const [createOpen, setCreateOpen] = useState(false)
    const [visitReasonIndex, setStaffPermissionIndex] = useState<number>()

    useEffect(() => {
        if (!count) {
            getStaffPermissions()
        }
    }, [])

    const onSearch = async () => {
        const search = searchValue.trim()
        if (search) {
            await getStaffPermissions({ page: 1, search })
        } else {
            await getStaffPermissions({ page: 1, search: undefined })
        }
    }

    const onEdit = (
        values: GetStaffPermissionsResponseItem,
        visitReasonIndex: number
    ) => {
        setValues(values)
        setStaffPermissionIndex(visitReasonIndex)
        setOpen(true)
    }

    const onDelete = async (
        visitReasonId: number,
        visitReasonIndex: number
    ) => {
        await deleteStaffPermission(visitReasonId, visitReasonIndex)
    }

    const getColumns = () => {
        const result = [...columns]
        result.push(
            {
                title: <AiOutlineEdit style={{ width: '100%' }} size={20} />,
                render: (_, values, visitReasonIndex) => (
                    <Button onClick={() => onEdit(values, visitReasonIndex)}>
                        Изменить
                    </Button>
                ),
                align: 'center',
                width: '15%',
            },
            {
                title: <AiOutlineDelete style={{ width: '100%' }} size={20} />,
                render: (_, { id }, visitReasonIndex) => (
                    <>
                        <Popconfirm
                            title="Delete visit reason"
                            description="Are you sure to delete this item?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => onDelete(id, visitReasonIndex)}
                        >
                            <Button danger>Удалить</Button>
                        </Popconfirm>
                    </>
                ),
                align: 'center',
                width: '15%',
            }
        )

        return result
    }

    return (
        <TopBarLayout
            withSearchBox
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onSearch={onSearch}
            postfix={
                <Button onClick={() => setCreateOpen(true)}>
                    Новый доступ
                </Button>
            }
        >
            <Table<GetStaffPermissionsResponseItem>
                dataSource={items}
                columns={getColumns()}
                rowKey="id"
                loading={loading}
                pagination={{
                    total: count,
                    pageSize: limit,
                    hideOnSinglePage: true,
                    onChange: (page) => getStaffPermissions({ page }),
                    current: page,
                }}
            />
            <EditStaffPermissionModal
                visitReasonIndex={visitReasonIndex}
                open={open}
                setOpen={(open) => setOpen(open)}
                values={values}
            />
            <CreateStaffPermissionModal
                open={createOpen}
                setOpen={(open) => setCreateOpen(open)}
            />
        </TopBarLayout>
    )
}

export default StaffPermissions
