import React, { useState } from 'react'
import { GetStaffGroupsResponseItem } from '@apis/staffGroup'
import { useStaffGroup } from '@hooks'
import { Button, Input, Modal } from 'antd'
import { Box } from '@components'

type EditStaffGroupModalProps = {
    open: boolean
    setOpen: (open: boolean) => void
    values: GetStaffGroupsResponseItem | undefined
    staffGroupIndex: number | undefined
}

export const EditStaffGroupModal: React.FC<EditStaffGroupModalProps> = (
    props
) => {
    const { open, setOpen, values, staffGroupIndex } = props
    const {
        updateStaffGroup: { updateStaffGroup, updateStaffGroupLoading },
    } = useStaffGroup()

    const [name, setName] = useState('')

    const onUpdateStaffGroup = async () => {
        if (
            values?.id !== undefined &&
            name !== values.name &&
            staffGroupIndex !== undefined
        ) {
            await updateStaffGroup(
                { id: values.id, body: { name } },
                staffGroupIndex
            )
            setOpen(false)
        }
    }

    return (
        <Modal
            open={open}
            onCancel={() => setOpen(false)}
            footer={null}
            width="330px"
            destroyOnClose
            title="Изменение группы сотрудника"
        >
            <Box style={{ flexDirection: 'column', gap: '20px' }}>
                <Input
                    defaultValue={values?.name}
                    onChange={(e) => setName(e.target.value)}
                />
                <Button
                    onClick={onUpdateStaffGroup}
                    loading={updateStaffGroupLoading}
                    disabled={!name.trim() || name === values?.name}
                >
                    Submit
                </Button>
            </Box>
        </Modal>
    )
}
