import React from 'react'
import { Button, Form, Input, Modal } from 'antd'

type ChangelogModalProps = {
    open: boolean
    onClose: () => void
    onFinish: (values: { changelog: string }) => void
}

export const ChangelogModal: React.FC<ChangelogModalProps> = (props) => {
    const { open, onClose, onFinish } = props

    return (
        <Modal
            footer={null}
            open={open}
            onCancel={onClose}
            destroyOnClose
            title="Изменения"
        >
            <Form<{ changelog: string }>
                onFinish={onFinish}
                initialValues={{
                    changelog: '',
                }}
            >
                <Form.Item
                    name="changelog"
                    rules={[
                        { required: true, message: 'это обязательное поле!' },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" type="primary">
                        Сохранить
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}
