import httpService from '../httpService'
import {
    CreateStaffProps,
    CreateStaffResponse,
    GetOneStaffResponse,
    GetStaffProps,
    GetStaffResponse,
    RestoreStaffResponse,
    UpdateStaffAvatarProps,
    UpdateStaffAvatarResponse,
    UpdateStaffProps,
    UpdateStaffResponse,
} from './types'

export default {
    getStaff({ params }: GetStaffProps) {
        return httpService.get<GetStaffResponse>('/dashboard/staff', { params })
    },
    createStaff({ body }: CreateStaffProps) {
        return httpService.post<CreateStaffResponse>('/dashboard/staff', body)
    },
    getOneStaff(id: number) {
        return httpService.get<GetOneStaffResponse>(`/dashboard/staff/${id}`)
    },
    updateStaff({ body, id }: UpdateStaffProps) {
        return httpService.put<UpdateStaffResponse>(
            `/dashboard/staff/${id}`,
            body
        )
    },
    updateStaffAvatar({ body, id }: UpdateStaffAvatarProps) {
        return httpService.patch<UpdateStaffAvatarResponse>(
            `/dashboard/staff/${id}`,
            body
        )
    },
    deleteStaff(id: number) {
        return httpService.delete<null>(`/dashboard/staff/${id}`)
    },
    resendInviteToStaff(id: number) {
        return httpService.post<null>(`/dashboard/staff/${id}/resend-invite`)
    },
    restoreStaff(id: number) {
        return httpService.patch<RestoreStaffResponse>(
            `/dashboard/staff/${id}/restore`
        )
    },
}
