import React, { useEffect, useState } from 'react'
import { useAppSelector } from '@/redux/hook'
import { Button, Popconfirm, Table } from 'antd'
import type { TableProps } from 'antd'
import { GetStaffGroupsResponseItem } from '@apis/staffGroup'
import { useStaffGroup } from '@hooks'
import { getColumnData } from '@/utils'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import {
    CreateStaffGroupModal,
    EditStaffGroupModal,
} from '@screens/common/settings'
import { TopBarLayout } from '@layouts'

const columns: TableProps<GetStaffGroupsResponseItem>['columns'] = [
    {
        title: '№',
        dataIndex: 'index',
        render: (_: any, __: any, index: number) => index + 1,
        width: '5%',
        align: 'center',
        responsive: ['xs', 'sm'],
    },
    {
        title: 'Название',
        dataIndex: 'name',
        render: getColumnData,
        width: '30%',
    },
]

const StaffGroups: React.FC = () => {
    const {
        items,
        count,
        loading,
        filters: { page, limit, search },
    } = useAppSelector((state) => state.staffGroup)
    const {
        getStaffGroups: { getStaffGroups },
        deleteStaffGroup: { deleteStaffGroup, deleteStaffGroupLoading },
    } = useStaffGroup()

    const [searchValue, setSearchValue] = useState(search || '')
    const [open, setOpen] = useState(false)
    const [createOpen, setCreateOpen] = useState(false)
    const [values, setValues] = useState<GetStaffGroupsResponseItem>()
    const [staffGroupIndex, setStaffGroupIndex] = useState<number>()

    useEffect(() => {
        if (!count) {
            getStaffGroups()
        }
    }, [])

    const onSearch = async () => {
        if (search) {
            await getStaffGroups({ page: 1, search })
        } else {
            await getStaffGroups({ page: 1, search: undefined })
        }
    }

    const onEdit = (
        values: GetStaffGroupsResponseItem,
        staffGroupIndex: number
    ) => {
        setValues(values)
        setOpen(true)
        setStaffGroupIndex(staffGroupIndex)
    }

    const onDelete = async (
        deletedStaffGroupId: number,
        deletedStaffGroupIndex: number
    ) => {
        await deleteStaffGroup(deletedStaffGroupId, deletedStaffGroupIndex)
    }

    const getColumns = () => {
        const result = [...columns]
        result.push(
            {
                title: <AiOutlineEdit style={{ width: '100%' }} size={20} />,
                render: (_, values, staffGroupIndex) => (
                    <Button onClick={() => onEdit(values, staffGroupIndex)}>
                        Изменить
                    </Button>
                ),
                align: 'center',
                width: '15%',
            },
            {
                title: <AiOutlineDelete style={{ width: '100%' }} size={20} />,
                render: (_, { id, _count: { staff } }, staffGroupIndex) => (
                    <>
                        <Popconfirm
                            title="Удаление группы"
                            description={
                                staff === 0
                                    ? 'Вы хотите удалить эту группу?'
                                    : `Невозможно удалить группу, так как есть пользователи, которые относятся к этой группе.
                                Для удаления группы она должна быть пустой.`
                            }
                            okText="Yes"
                            okButtonProps={{ disabled: !!staff }}
                            cancelText="No"
                            onConfirm={() => onDelete(id, staffGroupIndex)}
                        >
                            <Button danger loading={deleteStaffGroupLoading}>
                                Удалить
                            </Button>
                        </Popconfirm>
                    </>
                ),
                align: 'center',
                width: '15%',
            }
        )
        return result
    }

    return (
        <TopBarLayout
            withSearchBox
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onSearch={onSearch}
            postfix={
                <Button onClick={() => setCreateOpen(true)}>
                    Новая группа
                </Button>
            }
        >
            <Table<GetStaffGroupsResponseItem>
                dataSource={items}
                columns={getColumns()}
                rowKey="id"
                loading={loading}
                pagination={{
                    total: count,
                    pageSize: limit,
                    hideOnSinglePage: true,
                    onChange: (page) => getStaffGroups({ page }),
                    current: page,
                }}
            />
            <CreateStaffGroupModal
                open={createOpen}
                setOpen={(open) => setCreateOpen(open)}
            />
            <EditStaffGroupModal
                staffGroupIndex={staffGroupIndex}
                open={open}
                setOpen={(open) => setOpen(open)}
                values={values}
            />
        </TopBarLayout>
    )
}

export default StaffGroups
