import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Apis from '@apis'
import { WallStore } from './types'
import { RootState } from '../store'
import { GetWallProps, GetWallResponse, GetWallResponseItem } from '@apis/wall'

export const initialState: WallStore = {
    items: [],
    filters: {
        page: 1,
        limit: 20,
        search: undefined,
    },
    count: 0,
    loading: false,
    error: null,
}

export const getWall = createAsyncThunk<
    { data: GetWallResponse; filters: GetWallProps['params'] },
    Partial<GetWallProps['params']>,
    { state: RootState }
>('GET_WALL', async (payload, thunkAPI) => {
    const { wall } = thunkAPI.getState()
    const filters = { ...wall.filters, ...payload }
    const { data } = await Apis.wall.getWall({ params: filters })
    return { data, filters }
})

export const getOneWall = createAsyncThunk<
    GetWallResponseItem,
    number,
    { state: RootState }
>('GET_ONE_WALL', async (id, thunkAPI) => {
    const { data } = await Apis.wall.getOneWall(id)
    return data
})

export const updateWall = createAsyncThunk<
    GetWallResponseItem,
    { id: number; body: Partial<GetWallResponseItem> },
    { state: RootState }
>('UPDATE_WALL', async ({ id, body }, thunkAPI) => {
    const { data } = await Apis.wall.updateWall({ id, body })
    return data
})

const WallSlice = createSlice({
    name: 'wall',
    initialState,
    reducers: {
        ADD_WALL: (state, action: { payload: GetWallResponseItem }) => {
            state.items.push(action.payload)
            state.count += 1
        },
        REMOVE_WALL: (state, action: { payload: number }) => {
            state.items = state.items.filter(
                (item: GetWallResponseItem) => item.id !== action.payload
            )
            state.count -= 1
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getWall.pending, (state) => {
            state.error = null
            state.loading = true
        })
        builder.addCase(getWall.fulfilled, (state, action) => {
            const {
                filters,
                data: { items, count },
            } = action.payload
            state.filters = filters
            state.items =
                filters.page === 1 ? items : [...state.items, ...items]
            state.count = count
            state.loading = false
        })
        builder.addCase(getWall.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || 'Ошибка при загрузке стены'
        })
        builder.addCase(getOneWall.pending, (state) => {
            state.error = null
            state.loading = true
        })
        builder.addCase(getOneWall.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getOneWall.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || 'Ошибка при загрузке записи'
        })
        builder.addCase(updateWall.pending, (state) => {
            state.error = null
            state.loading = true
        })
        builder.addCase(updateWall.fulfilled, (state, action) => {
            state.items = state.items.map((item) =>
                item.id === action.payload.id ? action.payload : item
            )
            state.loading = false
        })
        builder.addCase(updateWall.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || 'Ошибка при обновлении записи'
        })
    },
})

export const { ADD_WALL, REMOVE_WALL } = WallSlice.actions

export default WallSlice.reducer
