import httpService from '@apis/httpService'
import {
    CreateStaffDinnerResponse,
    GetStaffDinnerPerWeekResponse,
    GetOneStaffDinnerResponse,
    GetStaffDinnersProps,
    GetStaffDinnersResponse,
    UpdateStaffDinnerProps,
    UpdateStaffDinnerPerWeekProps,
    GetStaffDinnerReportResponse,
    CreateStaffMenuProps,
    GetStaffDinnerReportForCopyResponse,
} from './types'

export default {
    getStaffDinners: ({ params }: GetStaffDinnersProps) => {
        return httpService.get<GetStaffDinnersResponse>('/dashboard/dinner', {
            params,
        })
    },
    createStaffMenu: ({ body }: CreateStaffMenuProps) => {
        return httpService.post<CreateStaffDinnerResponse>(
            '/dashboard/dinner',
            body
        )
    },
    getOneStaffDiner: (id: number) => {
        return httpService.get<GetOneStaffDinnerResponse>(
            `/dashboard/dinner/${id}`
        )
    },
    // FIXME: nothing is returned in the backend now, then when something is returned, you need to change the type from <null> to the <UpdateStaffDinnerResponse>
    updateStaffDinner: ({ id, body }: UpdateStaffDinnerProps) => {
        return httpService.put<null>(`/dashboard/dinner/${id}`, body)
    },
    // FIXME: nothing is returned in the backend now, then when something is returned, you need to change the type from <null> to the <PayForLunchForStaffResponse>
    payForLunchForStaff: (id: number) => {
        return httpService.patch<null>(`/dashboard/dinner/${id}`)
    },
    getStaffDinnerPerWeek: (id: number) => {
        return httpService.get<GetStaffDinnerPerWeekResponse>(
            `/dashboard/dinner/${id}/staff`
        )
    },
    // FIXME: nothing is returned in the backend now, then when something is returned, you need to change the type from <null> to the <UpdateStaffDinnerPerWeek>
    updateStaffDinnerPerWeek: ({ id, body }: UpdateStaffDinnerPerWeekProps) => {
        return httpService.post<null>(`/dashboard/dinner/${id}/staff`, body)
    },
    getStaffDinnerReport: (id: number) => {
        return httpService.get<GetStaffDinnerReportResponse>(
            `/dashboard/dinner/${id}/report`
        )
    },
    getStaffDinnerReportForCopy: ({
        team_id,
        dinner_id,
    }: {
        team_id: number
        dinner_id: number
    }) => {
        return httpService.get<GetStaffDinnerReportForCopyResponse>(
            `/dashboard/dinner/${dinner_id}/report/team/${team_id}`
        )
    },
}
