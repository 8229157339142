import React from 'react'
import styled from 'styled-components'
import { TopBarProps } from './types'

const TopBarStyled = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
`

const TopBarItem = styled.span`
    margin: 0;
    display: flex;
    align-items: center;

    &.top-bar__title {
        justify-content: center;
    }

    &.top-bar__prefix {
        justify-content: left;
    }

    &.top-bar__postfix {
        justify-content: right;
    }
`

export const TopBar: React.FC<TopBarProps> = (props) => {
    const { postfix, prefix, title, ...otherProps } = props
    return (
        <TopBarStyled className="top-bar" {...otherProps}>
            <TopBarItem className="top-bar__prefix">{prefix}</TopBarItem>
            <TopBarItem className="top-bar__title" as="h2">
                {title}
            </TopBarItem>
            <TopBarItem className="top-bar__postfix">{postfix}</TopBarItem>
        </TopBarStyled>
    )
}
