export const getAvatarUrl = (filename: string | null) => {
    const noAvatar =
        'https://i2.wp.com/vdostavka.ru/wp-content/uploads/2019/05/no-avatar.png?ssl=1'

    if (filename) {
        return `${process.env.REACT_APP_API_URL}/images/${filename}`
    }

    return noAvatar
}
