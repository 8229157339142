import { OneLocalRegulation, ParentRegulation } from '@apis/regulations'
import { checkIsParentRegulation } from './checkIsParentRegulation'
import { checkIsNotLocal } from '@/utils/checkIsNotLocal'

export const checkIsNotLocalParentRegulation = (
    regulation: OneLocalRegulation
): regulation is ParentRegulation => {
    const isParent = checkIsParentRegulation(regulation)
    const isNotLocal = checkIsNotLocal(regulation)
    return isParent && isNotLocal
}
