import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit'
import admins from './admin'
import user from './user'
import staff from './staff'
import message from './message'
import staffGroup from './staffGroup'
import guest from './guest'
import visitReason from './visitReason'
import qrCode from './qrCode'
import team from './team'
import staffDinner from './staffDinner'
import staffPermission from './staffPermission'
import regulation from './regulation'
import bookCategory from './bookCategory'
import book from './book'
import requests from './requests'
import wall from './wall'

// @ts-ignore
const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__

export const createStore = (
    options?: ConfigureStoreOptions['preloadedState'] | undefined
) =>
    configureStore({
        reducer: {
            admins,
            user,
            staff,
            message,
            staffGroup,
            guest,
            visitReason,
            qrCode,
            team,
            staffDinner,
            staffPermission,
            regulation,
            bookCategory,
            book,
            requests,
            wall,
        },
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
            }),
        devTools: true,
        ...options,
    })

export const store = createStore()

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
