import { createSlice } from '@reduxjs/toolkit'
import { MessageStore } from './types'

export const initialState: MessageStore = {
    text: undefined,
    type: undefined,
}

const MessageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
        SET_MESSAGE: (state, action: { payload: MessageStore }) => {
            return action.payload
        },
        CLEAR_MESSAGE: (state) => {
            return initialState
        },
    },
})

export const { SET_MESSAGE, CLEAR_MESSAGE } = MessageSlice.actions
export default MessageSlice.reducer
