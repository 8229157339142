import httpService from '../httpService'

import {
    CreateQrCodeResponse,
    GetOneQrCode,
    GetQrCodesProps,
    GetQrCodesResponse,
} from './types'

export default {
    getQrCodes({ params }: GetQrCodesProps) {
        return httpService.get<GetQrCodesResponse>('/dashboard/qr-codes', {
            params,
        })
    },
    createQrCode() {
        return httpService.post<CreateQrCodeResponse>('/dashboard/qr-codes')
    },
    getOneQrCode(id: string) {
        return httpService.get<GetOneQrCode>(`/dashboard/qr-codes/${id}`)
    },
    deleteQrCode(id: string) {
        return httpService.delete<void>(`/dashboard/qr-codes/${id}`)
    }
}
