import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Apis from '@apis'
import { BookCategoryStore } from './types'
import { RootState } from '../store'
import {
    GetBookCategoriesProps,
    GetBookCategoriesResponse,
    GetBookCategoriesResponseItem,
} from '@apis/bookCategory'

export const initialState: BookCategoryStore = {
    items: [],
    filters: {
        page: 1,
        limit: 50,
        search: undefined,
    },
    count: 0,
    loading: false,
    error: null,
}

export const getBookCategories = createAsyncThunk<
    { data: GetBookCategoriesResponse; filters: GetBookCategoriesProps['params'] },
    Partial<GetBookCategoriesProps['params']>,
    { state: RootState }
>('GET_BOOK_CATEGORIES', async (payload, thunkAPI) => {
    const { bookCategory } = thunkAPI.getState()
    const filters = { ...bookCategory.filters, ...payload }
    const { data } = await Apis.bookCategory.getBookCategories({ params: filters })
    return { data, filters }
})

const BookCategorySlice = createSlice({
    name: 'bookCategory',
    initialState,
    reducers: {
        ADD_BOOK_CATEGORY: (
            state,
            action: { payload: GetBookCategoriesResponseItem },
        ) => {
            state.items.push(action.payload)
            state.count = state.items.length
        },
        UPDATE_BOOK_CATEGORY: (
            state,
            action: {
                payload: {
                    updatedBookCategoryData: GetBookCategoriesResponseItem
                    updatedBookCategoryIndex: number
                }
            },
        ) => {
            state.items.splice(
                action.payload.updatedBookCategoryIndex,
                1,
                action.payload.updatedBookCategoryData,
            )
        },
        REMOVE_BOOK_CATEGORY: (state, action: { payload: number }) => {
            state.items.splice(action.payload, 1)
            state.count = state.items.length
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getBookCategories.pending, (state, action) => {
            state.error = null
            state.loading = true
        })
        builder.addCase(getBookCategories.fulfilled, (state, action) => {
            const {
                filters,
                data: { items, count },
            } = action.payload
            state.filters = filters
            state.items = [...items]
            state.count = count
            state.loading = false
        })
        builder.addCase(getBookCategories.rejected, (state, action) => {
            state.loading = false
            state.error = action.error
        })
    },
})

export const {
    ADD_BOOK_CATEGORY,
    UPDATE_BOOK_CATEGORY,
    REMOVE_BOOK_CATEGORY,
} = BookCategorySlice.actions

export default BookCategorySlice.reducer
