export const getBookImgUrl = (filename: string | null) => {
    const noBookImg
        = 'https://biotrop.org/images/default-book.png'

    if(filename) {
        return filename
    }

    return noBookImg
}
