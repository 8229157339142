import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useRestriction } from '@hooks'

export const PrivateRoute: React.FC = () => {
    const { isAuth } = useRestriction()
    if (isAuth) {
        return <Outlet />
    } else {
        return <Navigate to="/auth" />
    }
}
