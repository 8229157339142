import { createSlice } from '@reduxjs/toolkit'
import { UserStore } from './types'

export const initialState: UserStore = {
    is_auth: 'initial',
}

const UserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        SET_USER: (state, action: { payload: UserStore }) => {
            return action.payload
        },
        LOG_OUT: (state) => {
            return { ...initialState, is_auth: false }
        },
    },
})

export const { SET_USER, LOG_OUT } = UserSlice.actions
export default UserSlice.reducer
