import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Spin, Button, Form, Input, Upload, message, UploadProps } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { wallApi } from '@/apis/wall'
import { GetWallResponseItem, UpdateWallImageResponse } from '@/apis/wall/types'
import styled from 'styled-components'
import { TopBarLayout } from '@/layouts'

const SpinWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
`

const StyledBox = styled.div`
    width: 500px;
    padding: 20px;
`

const PlaceholderImage = styled.div`
    width: 100%;
    height: 200px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999;
    font-size: 18px;
`

const GetOneWall: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [photo, setPhoto] = useState<File | null>(null)
    const [oneWall, setOneWall] = useState<GetWallResponseItem | null>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [preview, setPreview] = useState<string | null>(null)
    const [refresh, setRefresh] = useState<boolean>(false)
    const [fileList, setFileList] = useState<any[]>([])
    const [isChanged, setIsChanged] = useState<boolean>(false)

    const fetchWall = async () => {
        if (id) {
            try {
                setLoading(true)
                const response = await wallApi.getOneWall(Number(id))
                setOneWall(response.data)
                setLoading(false)
            } catch (error) {
                console.error('Не удалось загрузить пост:', error)
                message.error('Не удалось загрузить пост')
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        fetchWall()
    }, [id, refresh])

    useEffect(() => {
        if (oneWall) {
            form.setFieldsValue({
                text: oneWall.text,
                photo_filename: oneWall.photo_filename,
            })
        }
    }, [oneWall, form])

    const onFinish = async (values: any) => {
        if (id) {
            try {
                const response = await wallApi.updateWall({
                    id: Number(id),
                    body: {
                        text: values.text,
                    },
                })

                if (photo) {
                    const formData = new FormData()
                    formData.append('file', photo)
                    await wallApi.updateWallPhoto({
                        id: response.data.id,
                        body: formData,
                    })
                }

                message.success('Пост успешно изменен!')
                setRefresh(!refresh)
                setFileList([])
                setPhoto(null)
                setPreview(null)
                setIsChanged(false)
            } catch (error) {
                console.error('Ошибка изменения поста:', error)
                message.error('Ошибка изменения поста')
            }
        }
    }

    const handlePhotoChange: UploadProps['onChange'] = ({ fileList }) => {
        const file = fileList[0]?.originFileObj as File
        setFileList(fileList)
        if (file) {
            setPhoto(file)
            const reader = new FileReader()
            reader.onloadend = () => {
                setPreview(reader.result as string)
            }
            reader.readAsDataURL(file)
        } else {
            setPhoto(null)
            setPreview(null)
        }
        setIsChanged(true)
    }

    const handleDelete = async () => {
        if (id) {
            try {
                await wallApi.deleteWall(Number(id))
                message.success('Пост успешно удален!')
                navigate('/wall')
            } catch (error) {
                console.error('Не удалось удалить пост:', error)
                message.error('Не удалось удалить пост')
            }
        }
    }

    const handleCancel = () => {
        if (oneWall) {
            form.setFieldsValue({
                text: oneWall.text,
                photo_filename: oneWall.photo_filename,
            })
            setFileList([])
            setPhoto(null)
            setPreview(null)
            setIsChanged(false)
        }
    }

    const handleValuesChange = () => {
        setIsChanged(true)
    }

    return (
        <Spin spinning={loading}>
            <TopBarLayout withGoBack title="Пост">
                <SpinWrapper>
                    <StyledBox>
                        {oneWall?.photo_filename ? (
                            <img
                                src={`https://api.startups-dna.com/images/${oneWall.photo_filename}`}
                                alt="uploaded"
                                style={{ width: '100%', marginBottom: '20px' }}
                            />
                        ) : (
                            <PlaceholderImage>Нет фото</PlaceholderImage>
                        )}
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                            onValuesChange={handleValuesChange}
                        >
                            <Form.Item
                                name="text"
                                label="Изменить текст"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Введите текст!',
                                    },
                                ]}
                            >
                                <Input.TextArea rows={4} />
                            </Form.Item>
                            <Form.Item
                                name="photo_filename"
                                label="Изменить фото"
                            >
                                <div>
                                    <Upload
                                        listType="picture"
                                        fileList={fileList}
                                        beforeUpload={() => false}
                                        onChange={handlePhotoChange}
                                    >
                                        <Button icon={<UploadOutlined />}>
                                            Выбрать
                                        </Button>
                                    </Upload>
                                </div>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Сохранить
                                </Button>
                                {isChanged && (
                                    <Button
                                        type="default"
                                        onClick={handleCancel}
                                        style={{ marginLeft: '10px' }}
                                    >
                                        Отмена
                                    </Button>
                                )}
                                <Button
                                    type="default"
                                    danger
                                    onClick={handleDelete}
                                    style={{ marginLeft: '10px' }}
                                >
                                    Удалить
                                </Button>
                            </Form.Item>
                        </Form>
                    </StyledBox>
                </SpinWrapper>
            </TopBarLayout>
        </Spin>
    )
}

export default GetOneWall
