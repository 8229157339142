import httpService from '../httpService'
import {
    CreateRegulationProps,
    GetOneRegulationResponse,
    GetRegulationsProps,
    GetRegulationsResponse,
    CreateRegulationResponse,
    UpdateRegulationProps,
    UpdateRegulationResponse,
    DeleteRegulationProps,
    GetRegulationChangelogsProps,
    UpdateRegulationChangelogsProps,
    SendRegulationPush,
    GetRegulationChangelogsResponse,
} from './types'

export default {
    getRegulations({ params }: GetRegulationsProps) {
        return httpService.get<GetRegulationsResponse>(
            '/dashboard/regulations',
            { params }
        )
    },
    createRegulation({ body }: CreateRegulationProps) {
        return httpService.post<CreateRegulationResponse>(
            '/dashboard/regulations',
            body
        )
    },
    getOneRegulation(id: number) {
        return httpService.get<GetOneRegulationResponse>(
            `/dashboard/regulations/${id}`
        )
    },
    updateRegulation({ id, body }: UpdateRegulationProps) {
        return httpService.put<UpdateRegulationResponse>(
            `/dashboard/regulations/${id}`,
            body
        )
    },
    deleteRegulation({ id, params }: DeleteRegulationProps) {
        return httpService.delete<null>(`/dashboard/regulations/${id}`, {
            params,
        })
    },
    updateRegulationChangelogs({ body }: UpdateRegulationChangelogsProps) {
        return httpService.put<undefined>(
            '/dashboard/regulations/changelog',
            body
        )
    },
    getRegulationChangelogs({ params }: GetRegulationChangelogsProps) {
        return httpService.get<GetRegulationChangelogsResponse>(
            '/dashboard/regulations/changelog',
            { params }
        )
    },
    deleteRegulationChangelog(id: number) {
        return httpService.delete<null>(
            `/dashboard/regulations/changelog/${id}`
        )
    },
    sendRegulationPush({ params }: SendRegulationPush) {
        return httpService.patch<undefined>(
            '/dashboard/regulations',
            undefined,
            { params }
        )
    },
}
