import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Apis from '@apis'
import { StaffPermissionStore } from './types'
import { RootState } from '../store'
import {
    GetStaffPermissionsProps,
    GetStaffPermissionsResponse,
    GetStaffPermissionsResponseItem,
} from '@apis/staffPermission'

export const initialState: StaffPermissionStore = {
    items: [],
    filters: {
        page: 1,
        limit: 50,
        search: undefined,
    },
    count: 0,
    loading: false,
    error: null,
}

export const getStaffPermissions = createAsyncThunk<
    {
        data: GetStaffPermissionsResponse
        filters: GetStaffPermissionsProps['params']
    },
    Partial<GetStaffPermissionsProps['params']>,
    { state: RootState }
>('GET_STAFF_PERMISSIONS', async (payload, thunkAPI) => {
    const { staffPermission } = thunkAPI.getState()
    const filters = { ...staffPermission.filters, ...payload }
    const { data } = await Apis.staffPermission.getStaffPermissions({
        params: filters,
    })
    return { data, filters }
})

const StaffPermissionSlice = createSlice({
    name: 'staffPermission',
    initialState,
    reducers: {
        ADD_STAFF_PERMISSION: (
            state,
            action: { payload: GetStaffPermissionsResponseItem }
        ) => {
            state.items.push(action.payload)
            state.count = state.items.length
        },
        UPDATE_STAFF_PERMISSION: (
            state,
            action: {
                payload: {
                    updatedStaffPermissionData: GetStaffPermissionsResponseItem
                    updatedStaffPermissionIndex: number
                }
            }
        ) => {
            state.items.splice(
                action.payload.updatedStaffPermissionIndex,
                1,
                action.payload.updatedStaffPermissionData
            )
        },
        REMOVE_STAFF_PERMISSION: (state, action: { payload: number }) => {
            state.items.splice(action.payload, 1)
            state.count = state.items.length
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getStaffPermissions.pending, (state, action) => {
            state.error = null
            state.loading = true
        })
        builder.addCase(getStaffPermissions.fulfilled, (state, action) => {
            const {
                filters,
                data: { items, count },
            } = action.payload
            state.filters = filters
            state.items = [...items]
            state.count = count
            state.loading = false
        })
        builder.addCase(getStaffPermissions.rejected, (state, action) => {
            state.loading = false
            state.error = action.error
        })
    },
})

export const {
    ADD_STAFF_PERMISSION,
    UPDATE_STAFF_PERMISSION,
    REMOVE_STAFF_PERMISSION,
} = StaffPermissionSlice.actions

export default StaffPermissionSlice.reducer
