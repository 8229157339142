import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Apis from '../../apis'
import { AdminStore } from './types'
import { RootState } from '../store'
import {
    GetAdminsProps,
    GetAdminsResponse,
    GetAdminsResponseItem,
} from '@apis/admin'

export const initialState: AdminStore = {
    items: [],
    filters: {
        page: 1,
        limit: 20,
        search: undefined,
    },
    count: 0,
    loading: false,
    error: null,
}

export const getAdmins = createAsyncThunk<
    { data: GetAdminsResponse; filters: GetAdminsProps['params'] },
    Partial<GetAdminsProps['params']>,
    { state: RootState }
>('GET_ADMINS', async (payload, thunkAPI) => {
    const { admins } = thunkAPI.getState()
    const filters = { ...admins.filters, ...payload }
    const { data } = await Apis.admin.getAdmins({ params: filters })
    return { data, filters }
})

const AdminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        ADD_ADMIN: (state, action: { payload: GetAdminsResponseItem }) => {
            state.items.push(action.payload)
            state.count = state.items.length
        },
        REMOVE_ADMIN: (state, action: { payload: number }) => {
            state.items.splice(action.payload, 1)
            state.count = state.items.length
        },
        UPDATE_ADMIN: (
            state,
            action: { payload: { data: GetAdminsResponseItem; index: number } }
        ) => {
            state.items.splice(action.payload.index, 1, action.payload.data)
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAdmins.pending, (state, action) => {
            state.error = null
            state.loading = true
        })
        builder.addCase(getAdmins.fulfilled, (state, action) => {
            const {
                filters,
                data: { items, count },
            } = action.payload
            state.filters = filters
            if (filters.page !== 1) {
                state.items = [...state.items, ...items]
            } else {
                state.items = [...items]
            }
            state.count = count
            state.loading = false
        })
        builder.addCase(getAdmins.rejected, (state, action) => {
            state.loading = false
            state.error = action.error
        })
    },
})

export const { ADD_ADMIN, UPDATE_ADMIN, REMOVE_ADMIN } = AdminSlice.actions
export default AdminSlice.reducer
