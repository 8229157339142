import { useAppDispatch, useAppSelector } from '@/redux/hook'
import { useRestriction } from '@hooks/useRestriction'
import {
    CreateStaffMenuProps,
    GetOneStaffDinnerResponse,
    GetStaffDinnerPerWeekResponse,
    GetStaffDinnersProps,
    UpdateStaffDinnerPerWeekProps,
    UpdateStaffDinnerProps,
} from '@apis/staffDinner'
import { getStaffDinners } from '@/redux/staffDinner'
import { SET_MESSAGE } from '@/redux/message'
import { useState } from 'react'
import Apis from '@apis'
import { useNavigate } from 'react-router-dom'

export const useStaffDinner = () => {
    const { loading } = useAppSelector((state) => state.staffDinner)
    const dispatch = useAppDispatch()
    const { isAuth } = useRestriction()
    const nav = useNavigate()

    const [oneStaffDinner, setOneStaffDinner] =
        useState<GetOneStaffDinnerResponse>()
    const [staffDinnerPerWeek, setStaffDinnerPerWeek] =
        useState<GetStaffDinnerPerWeekResponse>()

    const [payForLunchForStaffLoading, setPayForLunchForStaffLoading] =
        useState(false)
    const [getOneStaffDinnerLoading, setGetOneStaffDinnerLoading] =
        useState(false)
    const [getStaffDinnerPerWeekLoading, setGetStaffDinnerPerWeekLoading] =
        useState(false)
    const [createStaffMenuLoading, setCreateStaffMenuLoading] = useState(false)
    const [updateStaffDinnerLoading, setUpdateStaffDinnerLoading] =
        useState(false)
    const [
        updateStaffDinnerPerWeekLoading,
        setUpdateStaffDinnerPerWeekLoading,
    ] = useState(false)

    const fetchStaffDinners = async (
        params?: Partial<GetStaffDinnersProps['params']>
    ) => {
        if (!isAuth) return

        try {
            await dispatch(getStaffDinners(params ?? {}))
        } catch (e) {
            await dispatch(
                SET_MESSAGE({
                    text: 'Обеды сотрудников не загружены',
                    type: 'error',
                })
            )
        }
    }

    const payForLunchForStaff = async (id: number) => {
        if (!isAuth) return

        setPayForLunchForStaffLoading(true)
        try {
            await Apis.staffDinner.payForLunchForStaff(id)
            await dispatch(getStaffDinners({}))
            await dispatch(
                SET_MESSAGE({
                    text: 'Обед успешно оплачен',
                    type: 'success',
                })
            )
        } catch (e) {
            await dispatch(
                SET_MESSAGE({
                    text: 'Не удалось оплатить обед',
                    type: 'error',
                })
            )
        }
        setPayForLunchForStaffLoading(false)
    }

    const getOneStaffDinner = async (id: number) => {
        if (!isAuth) return

        setGetOneStaffDinnerLoading(true)
        try {
            const { data } = await Apis.staffDinner.getOneStaffDiner(id)
            setOneStaffDinner(data)
        } catch (e) {
            await dispatch(
                SET_MESSAGE({
                    text: 'Не удалось загрузить обед сотрудника',
                    type: 'error',
                })
            )
        }
        setGetOneStaffDinnerLoading(false)
    }

    const getStaffDinnerPerWeek = async (id: number) => {
        if (!isAuth) return

        setGetStaffDinnerPerWeekLoading(true)
        try {
            const { data } = await Apis.staffDinner.getStaffDinnerPerWeek(id)
            setStaffDinnerPerWeek(data)
        } catch (e) {
            await dispatch(
                SET_MESSAGE({
                    text: 'Не удалось загрузить обеды сотрудников за неделю',
                    type: 'error',
                })
            )
        }
        setGetStaffDinnerPerWeekLoading(false)
    }

    const createStaffMenu = async (newMenuData: CreateStaffMenuProps) => {
        if (!isAuth) return
        setCreateStaffMenuLoading(true)
        try {
            const { data } = await Apis.staffDinner.createStaffMenu(newMenuData)
            await dispatch(getStaffDinners({}))
            // dispatch(ADD_STAFF_DINNER(data))
            nav(`/dinners/${data.id}`)
        } catch (e: any) {
            await dispatch(
                SET_MESSAGE({
                    text: `Не удалось создать новое меню. Ошибка: ${
                        e?.response?.data?.message ?? e.message
                    }`,
                    type: 'error',
                })
            )
        }
        setCreateStaffMenuLoading(false)
    }

    const updateStaffDinner = async (values: UpdateStaffDinnerProps) => {
        if (!isAuth) return

        setUpdateStaffDinnerLoading(true)
        try {
            await Apis.staffDinner.updateStaffDinner(values)
            await fetchStaffDinners()
            await dispatch(
                SET_MESSAGE({
                    text: 'Обед был успешно изменен',
                    type: 'success',
                })
            )
            nav('/dinners', {
                replace: true,
            })
        } catch (e) {
            await dispatch(
                SET_MESSAGE({
                    text: 'Не удалось изменить',
                    type: 'error',
                })
            )
        }
        setUpdateStaffDinnerLoading(false)
    }

    const updateStaffDinnerPerWeek = async (
        values: UpdateStaffDinnerPerWeekProps
    ) => {
        if (!isAuth) return

        setUpdateStaffDinnerPerWeekLoading(true)
        try {
            await Apis.staffDinner.updateStaffDinnerPerWeek(values)
            await getOneStaffDinner(values.id)
            await getStaffDinnerPerWeek(values.id)
            await dispatch(getStaffDinners({}))
            await dispatch(
                SET_MESSAGE({
                    text: 'Обеды сторудников были успешно изменены',
                    type: 'success',
                })
            )
            nav(`/dinners/${values.id}`)
        } catch (e) {
            await dispatch(
                SET_MESSAGE({
                    text: 'Не удалось изменить',
                    type: 'error',
                })
            )
        }
        setUpdateStaffDinnerPerWeekLoading(false)
    }

    return {
        getStaffDinners: {
            getStaffDinners: fetchStaffDinners,
            getStaffDinnersLoading: loading,
        },
        payForLunchForStaff: {
            payForLunchForStaff,
            payForLunchForStaffLoading,
        },
        getOneStaffDinner: {
            getOneStaffDinner,
            getOneStaffDinnerLoading,
            oneStaffDinner,
        },
        getStaffDinnerPerWeek: {
            getStaffDinnerPerWeek,
            getStaffDinnerPerWeekLoading,
            staffDinnerPerWeek,
        },
        createStaffMenu: {
            createStaffMenu,
            createStaffMenuLoading,
        },
        updateStaffDinner: {
            updateStaffDinner,
            updateStaffDinnerLoading,
        },
        updateStaffDinnerPerWeek: {
            updateStaffDinnerPerWeek,
            updateStaffDinnerPerWeekLoading,
        },
    }
}
