import React, { useMemo } from 'react'
import { TopBarLayoutProps } from './types'
import styled from 'styled-components'
import { TopBar } from '@components'
import { BsChevronLeft } from 'react-icons/bs'
import { Input } from 'antd'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

const TopBarLayoutStyled = styled.div`
    height: inherit;
    display: flex;
    flex-direction: column;
    gap: 20px;
`
const TopBarLayoutContent = styled.div`
    height: inherit;
    overflow-y: auto;

    &.withBorder {
        border-bottom: 1px solid rgba(5, 5, 5, 0.06);
    }
`

const TopBarLayoutFooter = styled.footer``

export const TopBarLayout: React.FC<TopBarLayoutProps> = (props) => {
    const {
        title,
        prefix,
        postfix,
        withGoBack,
        withSearchBox,
        children,
        footer = false,
    } = props
    const nav = useNavigate()

    const onGoBack = () => {
        if (withGoBack) {
            props.onGoBack !== undefined ? props.onGoBack() : nav(-1)
        }
    }

    const topBarPrefix = useMemo(() => {
        if (prefix) return prefix
        else if (withGoBack)
            return (
                <BsChevronLeft
                    size="20px"
                    onClick={onGoBack}
                    cursor="pointer"
                />
            )
    }, [prefix, withGoBack])

    const onSearch = () => {
        if (props.withSearchBox) props.onSearch()
    }

    return (
        <TopBarLayoutStyled
            className={classNames('top-bar-layout', {
                'with-search': withSearchBox,
                'with-footer': footer,
                'with-title': title,
            })}
        >
            {(title || prefix || postfix) && (
                <TopBar title={title} prefix={topBarPrefix} postfix={postfix} />
            )}
            {withSearchBox && (
                <Input.Search
                    value={props.value}
                    onChange={props.onChange}
                    onSearch={onSearch}
                    className="top-bar-layout__searchbox"
                />
            )}
            <TopBarLayoutContent
                className={classNames('top-bar-layout__content', {
                    withBorder: footer,
                })}
            >
                {children}
            </TopBarLayoutContent>
            {footer && (
                <TopBarLayoutFooter className="top-bar-layout__footer">
                    {footer}
                </TopBarLayoutFooter>
            )}
        </TopBarLayoutStyled>
    )
}
