import React, { useEffect, useState } from 'react'
import { TopBarLayout } from '@layouts'
import { useAppSelector } from '@/redux/hook'
import { Button, Form, Input, Modal, Popconfirm, Table } from 'antd'
import type { TableProps } from 'antd'
import { useTeam } from '@hooks'
import { GetTeamsResponseItem } from '@apis/team'
import styled from 'styled-components'
import { getColumnData } from '@/utils'
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai'

const columns: TableProps<GetTeamsResponseItem>['columns'] = [
    {
        title: '№',
        dataIndex: 'index',
        render: (_: any, __: any, index: number) => index + 1,
        width: '5%',
        align: 'center',
        responsive: ['xs', 'sm'],
    },
    {
        title: 'Название',
        dataIndex: 'name',
        render: getColumnData,
    },
]

type FormType = {
    mode: 'create' | 'update'
    open: boolean
    initialValues: {
        id?: number
        name: string
    }
}

const Wrapper = styled.div`
    display: grid;
    row-gap: 20px;
`

const Teams: React.FC = () => {
    const {
        items,
        count,
        loading,
        filters: { search, limit, page },
    } = useAppSelector((state) => state.team)
    const {
        getTeams: { getTeams },
        deleteTeam: { deleteTeam },
        updateTeam: { updateTeam },
        createTeam: { createTeam },
    } = useTeam()

    const [value, setValue] = useState(search || '')
    const [form, setForm] = useState<FormType>({
        mode: 'create',
        open: false,
        initialValues: {
            name: '',
        },
    })
    const [teamIndex, setTeamIndex] = useState<number>()

    useEffect(() => {
        if (!count) {
            getTeams()
        }
    }, [])

    const onSearch = async () => {
        const search = value.trim()

        if (search) {
            await getTeams({ page: 1, search })
        } else {
            await getTeams({ page: 1, search: undefined })
        }
    }

    const onSubmit = async (values: { name: string }) => {
        if (form.mode === 'create') {
            await createTeam({ body: values })
        } else if (
            form.initialValues.id !== undefined &&
            teamIndex !== undefined
        ) {
            await updateTeam(
                { id: form.initialValues.id, body: values },
                teamIndex
            )
        }

        setForm((prevState) => ({ ...prevState, open: false }))
    }

    const onClick = (values: FormType['initialValues'], teamIndex: number) => {
        setForm({
            initialValues: values,
            open: true,
            mode: 'update',
        })
        setTeamIndex(teamIndex)
    }

    const getColumns = () => {
        const result = [...columns]
        result.push(
            {
                title: <AiOutlineEdit style={{ width: '100%' }} size={20} />,
                render: (_, values, teamIndex) => (
                    <Button onClick={(id) => onClick(values, teamIndex)}>
                        Изменть
                    </Button>
                ),
                align: 'center',
                width: '15%',
            },
            {
                title: <AiOutlineDelete style={{ width: '100%' }} size={20} />,
                render: (_, team, teamIndex) => (
                    <Popconfirm
                        title="Delete admin"
                        description="Are you sure to delete this item?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteTeam(team.id, teamIndex)}
                    >
                        <Button danger>Удалить</Button>
                    </Popconfirm>
                ),
                align: 'center',
                width: '15%',
            }
        )

        return result
    }

    return (
        <TopBarLayout
            withSearchBox
            onSearch={onSearch}
            onChange={(e) => setValue(e.target.value)}
            value={value}
            postfix={
                <Button
                    onClick={() =>
                        setForm((prevState) => ({
                            ...prevState,
                            open: true,
                            mode: 'create',
                        }))
                    }
                >
                    Новый проект
                </Button>
            }
        >
            <Table<GetTeamsResponseItem>
                dataSource={items}
                loading={loading}
                columns={getColumns()}
                rowKey="id"
                pagination={{
                    total: count,
                    current: page,
                    pageSize: limit,
                    onChange: (page) => getTeams({ page }),
                    hideOnSinglePage: true,
                }}
            />

            <Modal
                open={form.open}
                onCancel={() =>
                    setForm((prevState) => ({ ...prevState, open: false }))
                }
                footer={null}
                destroyOnClose
                width="350px"
                title={
                    form.mode === 'create'
                        ? 'Новый проект'
                        : 'Изменение проекта'
                }
            >
                <Wrapper>
                    <Form
                        initialValues={
                            form.mode === 'update'
                                ? form.initialValues
                                : undefined
                        }
                        onFinish={onSubmit}
                        layout="vertical"
                    >
                        <Form.Item
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Название команды обязательное поле!',
                                },
                            ]}
                            label="Название"
                        >
                            <Input />
                        </Form.Item>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                {form.mode === 'create'
                                    ? 'Создать'
                                    : 'Изменить'}
                            </Button>
                        </div>
                    </Form>
                </Wrapper>
            </Modal>
        </TopBarLayout>
    )
}

export default Teams
